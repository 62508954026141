import React, { Component } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Content from "../sections/Defaultregister/Content";

const Defaultregister = () => {
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <Sidenavigation />
      <main className="body-content">
        {/* <Topnavigation /> */}
        <Content />
      </main>
    </div>
  );
};

export default Defaultregister;
