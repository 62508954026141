import React from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../../Redux/userSlice";

export const ForgetMail = ({
  modalShow,
  setModalShow,
  modalOtp,
  setModalOtp,
}) => {
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const verifymail = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      const payload = {
        email: values.email,
      };
      const request = {
        payload,
        onSuccess: async (message) => {
          toast.success(message);
          setModalOtp(!modalOtp);
          setModalShow(!modalShow);
        },
        onFail: (message) => {
          toast.error(message);
        },
      };
      dispatch(verifyEmail(request));
    },
  });
  return (
    <>
      <Modal
        className="modal-min"
        show={modalShow}
        onHide={() => setModalShow(!modalShow)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div
            className="close"
            onClick={() => setModalShow(!modalShow)}
            style={{ cursor: "pointer" }}
          >
            <span aria-hidden="true">×</span>
          </div>
          <i
            className="flaticon-secure-shield d-block"
            style={{ marginBottom: "2rem" }}
          />
          <form onSubmit={verifymail.handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h1>Forgot Password?</h1>
              <p>Enter your email to recover your password</p>
            </div>
            <div className="ms-form-group has-icon">
              <input
                type="text"
                placeholder="Enter Your Email"
                className="form-control"
                name="email"
                onChange={verifymail.handleChange}
                value={verifymail.values.email}
              />{" "}
              {verifymail.touched.email && verifymail.errors.email ? (
                <>
                  <div className="input-error-message">
                    Please provide a valid email.
                  </div>
                </>
              ) : null}
            </div>
            <button
              type="submit"
              className="btn btn-primary shadow-none"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Send
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
