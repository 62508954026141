// import React, { Component, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import $ from "jquery";
// import Scrollbar from "react-perfect-scrollbar";
// import "react-perfect-scrollbar/dist/css/styles.css";

// import logo from "../../assets/img/foodtech/foodtech-logo-216x62.png";
// import { Accordion } from "react-bootstrap";

// const Sidenavigation = () => {
//   // const removeoverlay = () => {
//   //   $(".ms-body").toggleClass("ms-aside-left-open");
//   //   $("#ms-side-nav").toggleClass("ms-aside-open");
//   //   $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
//   // };

//   const [subNav, setSubnav] = useState(false);
//   const [selectedLink, setSelectedLink] = useState(false);
//   const [activeLink, setActiveLink] = useState(false);
//   const [icon, setIcon] = useState(false);

//   // function setActiveMenuItem() {
//   //   $(".ms-main-aside .menu-item>a").on("click", function () {
//   //     $(this).removeAttr("href");
//   //     // $(this).addClass("active");
//   //     var element = $(this).parent("li");
//   //     if (element.hasClass("active")) {
//   //       element.removeClass("active");
//   //       element.find("li").removeClass("active");
//   //       element.find(".collapse").slideUp();
//   //     } else {
//   //       element.addClass("active");
//   //       element.children(".collapse").slideDown();
//   //       element.siblings("li").children(".collapse").slideUp();
//   //       element.siblings("li").removeClass("active");
//   //       element.siblings("li").find("li").removeClass("active");
//   //       element.siblings("li").find(".collapse").slideUp();
//   //     }
//   //   });
//   // }

//   // useEffect(() => {
//   //   setActiveMenuItem();
//   // }, []);
//   const PageLink = [
//     {
//       name: "DashBoard",
//       // icon: <DashboardOutlined />,
//       path: "/dashboard",
//     },
//     {
//       name: "Menus",
//       // icon: <BarsOutlined />,
//       path: "#",
//       // iconClosed: <CaretDownOutlined />,
//       // iconOpened: <CaretUpOutlined />,

//       subnav: [
//         {
//           name: "Menu Catalogue",
//           path: "/menu-catalogue",
//         },
//         {
//           name: "Menu Category",
//           path: "/menu-category",
//         },
//         {
//           name: "Add Product",
//           path: "/add-product",
//         },
//       ],
//     },
//   ];
//   return (
//     <div>
//       <div
//         className="ms-aside-overlay ms-overlay-left ms-toggler"
//         // onClick={removeoverlay}
//       ></div>
//       <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
//       <Scrollbar
//         id="ms-side-nav"
//         className="side-nav fixed ms-aside-scrollable ms-aside-left"
//       >
//         {/* Logo */}
//         <div className="logo-sn ms-d-block-lg">
//           <Link className="pl-0 ml-0 text-center" to="/">
//             {/* <img src={logo} alt="logo" /> */}
//             <h3>Order 365</h3>
//           </Link>
//         </div>
//         <div className="">
//           {/* {PageLink.map(({ name, path }) => (
//             <Accordion name={name} path={path} />
//           ))} */}
//           {PageLink.map((route, path) => {
//             return (
//               <>
//                 {/* <Accordion name={name} path={path} /> */}
//                 <Link
//                   to={route.path}
//                   onClick={() => {
//                     route?.subnav ? setSubnav(!subNav) : setSubnav(false);
//                     setActiveLink(route.name);
//                     // setIcon(route.icon);
//                   }}
//                 >
//                   {/* /user-profile */}
//                   <div
//                     className={`flex justify-between text-white items-center py-2  hover:bg-[#e27281] hover:text-white hover:relative  ${
//                       activeLink === route.name
//                         ? "border-l-2 border-solid border-white bg-[#e27281] text-white"
//                         : "null"
//                     }`}
//                   >
//                     <div className="flex  items-center px-4  ">
//                       {/* {route.icon} */}

//                       <p className="text-w282pxhite text-base font-normal ml-2 ">
//                         {route.name}
//                       </p>
//                     </div>
//                     <div className="flex items-center pr-2">
//                       {route.subnav && subNav
//                         ? route.iconOpened
//                         : route.subnav
//                         ? route.iconClosed
//                         : null}
//                     </div>
//                   </div>
//                 </Link>
//                 {subNav && route?.subnav
//                   ? route?.subnav.map((item, index) => {
//                       console.log(item, "item");
//                       return (
//                         <Link
//                           to={item.path}
//                           key={index}
//                           onClick={() => {
//                             setSelectedLink(item.name);
//                             setIcon(item.icon);
//                           }}
//                           className="no-underline text-white text-base hover:cursor-pointer hover:text-white  "
//                         >
//                           <div
//                             className={`flex items-center pl-6 py-2 hover:bg-[#e27281] hover:text-white hover:relative ${
//                               selectedLink === item.name
//                                 ? "border-l-2 border-solid border-white bg-[#e27281] text-white"
//                                 : "null"
//                             }`}
//                           >
//                             {item.icon}
//                             <span className="ml-2">{item.name}</span>
//                           </div>
//                         </Link>
//                       );
//                     })
//                   : null}
//               </>
//             );
//           })}
//         </div>
//         {/* Navigation */}
//         {/* <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion"> */}
//         {/* Dashboard */}
//         {/* <li className="menu-item"> */}
//         {/* <Link to="/dashboard" id="dashoard" className="has-chevron1">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">dashboard</i>
//                 Dashboard{" "}
//               </span>
//             </Link> */}
//         {/* <ul
//               id="dashboard"
//               className="collapse"
//               aria-labelledby="dashboard"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/">Foodtech</Link>
//               </li>
//             </ul> */}
//         {/* </li> */}
//         {/* /Dashboard */}
//         {/* product */}
//         {/* <li className="menu-item"> */}
//         {/* <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="fa fa-archive fs-16" />
//                 Menus{" "}
//               </span>
//             </Link> */}
//         {/* <ul
//               id="product"
//               className="collapse"
//               aria-labelledby="product"
//               data-parent="#side-nav-accordion"
//             > */}
//         {/* <li>
//                 {" "}
//                 <Link id="menu_catalogue" to="/menu-catalogue">
//                   Menu Catalogue
//                 </Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link id="menu_category" to="/menu-category">
//                   {" "}
//                   Category
//                 </Link>
//               </li> */}
//         {/* <li>
//                 {" "}
//                 <Link to="/menu-list">Menu List</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/menu-grid">Menu Grid</Link>
//               </li> */}
//         {/* <li>
//                 {" "}
//                 <Link id="menu_product" to="/add-product">
//                   Add Product
//                 </Link>
//               </li> */}
//         {/* <li>
//                 {" "}
//                 <Link to="/product-detail">Product Detail</Link>
//               </li> */}
//         {/* </ul>
//           </li> */}
//         {/* product end */}
//         {/* orders */}
//         {/* <li className="menu-item">
//             <Link to="/orders">
//               {" "}
//               <span>
//                 <i className="fas fa-clipboard-list fs-16" />
//                 Orders
//               </span>
//             </Link>
//           </li> */}
//         {/* orders end */}
//         {/* restaurants */}
//         {/* <li className="menu-item">
//             <Link to="/restaurant-list">
//               {" "}
//               <span>
//                 <i className="fa fa-tasks fs-16" />
//                 Restaurants List
//               </span>
//             </Link>
//           </li> */}
//         {/* restaurants end */}
//         {/* Invoice */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="fas fa-file-invoice fs-16" />
//                 Invoice{" "}
//               </span>
//             </Link>
//             <ul
//               id="invoice"
//               className="collapse"
//               aria-labelledby="invoice"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/invoice-detail">Invoice Detail</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/invoice-list">Invoice List</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* Invoice end */}
//         {/* customers*/}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="fas fa-user-friends fs-16" />
//                 Customers{" "}
//               </span>
//             </Link>
//             <ul
//               id="customer"
//               className="collapse"
//               aria-labelledby="customer"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/customer-review">Customers Review</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/customer-list">Customers List</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/social-activity">Social Activity</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* Customers  end */}
//         {/* sales */}
//         {/* <li className="menu-item">
//             <Link to="/sales">
//               {" "}
//               <span>
//                 <i className="fa fa-briefcase fs-16" />
//                 Sales
//               </span>
//             </Link>
//           </li> */}
//         {/* sales end  */}
//         {/* <li className="menu-item">
//             <Link to="/widgets">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">widgets</i>Widgets
//               </span>
//             </Link>
//           </li> */}
//         {/* Basic UI Elements */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">filter_list</i>Basic UI
//                 Elements
//               </span>
//             </Link>
//             <ul
//               id="basic-elements"
//               className="collapse"
//               aria-labelledby="basic-elements"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/accordions">Accordions</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/alerts">Alerts</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/buttons">Buttons</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/breadcrumbs">Breadcrumbs</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/badges">Badges</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/cards">Cards</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/progress-bars">Progress Bars</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/preloaders">Pre-loaders</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/pagination">Pagination</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/tabs">Tabs</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/typography">Typography</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Basic UI Elements */}
//         {/* Advanced UI Elements */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">code</i>Advanced UI Elements
//               </span>
//             </Link>
//             <ul
//               id="advanced-elements"
//               className="collapse"
//               aria-labelledby="advanced-elements"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/draggables">Draggables</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/sliders">Sliders</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/modals">Modals</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/rating">Rating</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/tour">Tour</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/cropper">Cropper</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/range-slider">Range Slider</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Advanced UI Elements */}
//         {/* <li className="menu-item">
//             <Link to="/animations">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">format_paint</i>Animations
//               </span>
//             </Link>
//           </li> */}
//         {/* Form Elements */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">input</i>Form Elements
//               </span>
//             </Link>
//             <ul
//               id="form-elements"
//               className="collapse"
//               aria-labelledby="form-elements"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/form-elements">Form Elements</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/form-layouts">Form Layouts</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/form-validation">Form Validation</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/form-wizard">Form Wizard</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Form Elements */}
//         {/* Charts */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">equalizer</i>Charts
//               </span>
//             </Link>
//             <ul
//               id="charts"
//               className="collapse"
//               aria-labelledby="charts"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/chartjs">Chart JS</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/google-chart">Google Chart</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Charts */}
//         {/* Tables */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">tune</i>Tables
//               </span>
//             </Link>
//             <ul
//               id="tables"
//               className="collapse"
//               aria-labelledby="tables"
//               data-parent="#side-nav-accordion"
//             >
//               <li>

//                 {" "}
//                 <Link to="/basic-tables">Basic Tables</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/data-tables">Data tables</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Tables */}
//         {/* Popups */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">message</i>Popups
//               </span>
//             </Link>
//             <ul
//               id="popups"
//               className="collapse"
//               aria-labelledby="popups"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/sweet-alerts">Sweet Alerts</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/toast">Toast</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Popups */}
//         {/* Icons */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">border_color</i>Icons
//               </span>
//             </Link>
//             <ul
//               id="icons"
//               className="collapse"
//               aria-labelledby="icons"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/fontawesome">Fontawesome</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/flaticons">Flaticons</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/materialize">Materialize</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Icons */}
//         {/* Maps */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">map</i>Maps
//               </span>
//             </Link>
//             <ul
//               id="maps"
//               className="collapse"
//               aria-labelledby="maps"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/google-maps">Google Maps</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/vector-maps">Vector Maps</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Maps */}
//         {/* <li className="menu-item">
//             <Link
//               to="#"
//               className="has-chevron"
//               data-toggle="collapse"
//               data-target="#bonuspages"
//               aria-expanded="false"
//               aria-controls="bonuspages"
//             >
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">insert_drive_file</i> Bonus
//                 Pages
//               </span>
//             </Link>
//             <ul
//               id="bonuspages"
//               className="collapse"
//               aria-labelledby="bonuspages"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/web-analytics"> Web Analytics </Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/stock-management">Stock Management</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/client-management">Client Management</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* / bonus Pages */}
//         {/* Pages */}
//         {/* <li className="menu-item">
//             <Link
//               to="#"
//               className="has-chevron"
//               data-toggle="collapse"
//               data-target="#pages"
//               aria-expanded="false"
//               aria-controls="pages"
//             >
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">insert_drive_file</i>Pages
//               </span>
//             </Link>
//             <ul
//               id="pages"
//               className="collapse"
//               aria-labelledby="pages"
//               data-parent="#side-nav-accordion"
//             >
//               <li className="menu-item">
//                 {" "}
//                 <Link
//                   to="#"
//                   className="has-chevron"
//                   data-toggle="collapse"
//                   data-target="#authentication"
//                   aria-expanded="false"
//                   aria-controls="authentication"
//                 >
//                   Authentication
//                 </Link>
//                 <ul
//                   id="authentication"
//                   className="collapse"
//                   aria-labelledby="authentication"
//                   data-parent="#pages"
//                 >
//                   <li>
//                     {" "}
//                     <Link to="/default-login">Default Login</Link>
//                   </li>
//                   <li>
//                     {" "}
//                     <Link to="/modal-login">Modal Login</Link>
//                   </li>
//                   <li>
//                     {" "}
//                     <Link to="/default-register">Default Registration</Link>
//                   </li>
//                   <li>
//                     {" "}
//                     <Link to="/modal-register">Modal Registration</Link>
//                   </li>
//                   <li>
//                     {" "}
//                     <Link to="/lock-screen">Lock Screen</Link>
//                   </li>
//                 </ul>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/coming-soon">Coming Soon</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/error">Error Page</Link>
//               </li>
//               <li className="menu-item">
//                 {" "}
//                 <Link to="/faq"> FAQ </Link>
//               </li>
//               <li className="menu-item">
//                 {" "}
//                 <Link to="/portfolio"> Portfolio </Link>
//               </li>
//               <li className="menu-item">
//                 {" "}
//                 <Link to="/user-profile"> User Profile </Link>
//               </li>
//               <li className="menu-item">
//                 {" "}
//                 <Link to="/invoice"> Invoice </Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* Apps */}
//         {/* <li className="menu-item">
//             <Link to="#" className="has-chevron">
//               {" "}
//               <span>
//                 <i className="material-icons fs-16">phone_iphone</i>Apps
//               </span>
//             </Link>
//             <ul
//               id="apps"
//               className="collapse"
//               aria-labelledby="apps"
//               data-parent="#side-nav-accordion"
//             >
//               <li>
//                 {" "}
//                 <Link to="/chat">Chat</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/email">Email</Link>
//               </li>
//               <li>
//                 {" "}
//                 <Link to="/todo-list">To-do List</Link>
//               </li>
//             </ul>
//           </li> */}
//         {/* /Apps */}
//         {/* </ul> */}
//       </Scrollbar>
//     </div>
//   );
// };

// export default Sidenavigation;

import React, { Component, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import logo from "../../assets/img/foodtech/foodtech-logo-216x62.png";
import { useTranslation } from "react-i18next";

const Sidenavigation = () => {
  const removeoverlay = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  const { t, i18n } = useTranslation();
  const [checkMenu, setCheckMenu] = useState();
  const [orderMenu, setOrderMenu] = useState();
  const [attributeMenu, setAttributeMenu] = useState();

  const url = window.location.pathname;
  const location = useLocation();

  function setActiveMenuItem() {
    $(".ms-main-aside .menu-item>a").on("click", function () {
      $(this).removeAttr("href");
      // $(this).addClass("active");
      var element = $(this).parent("li");
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        // $(this).parent().addClass("active");
        // element.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        element.children(".collapse").slideDown();
        element.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        element.siblings("li").find(".collapse").slideUp();
      }
    });
  }
  const menuArray = [
    "/menu-catalogue",
    "/menu-category",
    "/menu-product",
    "/menu-addons",
    "/add-product",
    "/createProduct",
    "/viewProduct",
    "/menu-category",
    "/createCategory",
    "/editCategory",
    "/viewCategory",
    "/createAddons",
    "/editAddons",
    "/viewAddons",

    // "/editSchedule",
    // "/timeSchedule",
  ];

  const menuOrder = ["/orders", "/orders-history"];
  const attributeMenus = ["/attribute-list", "/attribute-set"];

  const checkUrlInclude =
    location.pathname.includes("/editCategory") ||
    location.pathname.includes("/editProductList") ||
    location.pathname.includes("/editAddons") ||
    location.pathname.includes("/viewAddons") ||
    location.pathname.includes("/viewProduct") ||
    location.pathname.includes("/viewCategory")
      ? // location.pathname.includes("/editSchedule")
        true
      : false;

  function checkUrl() {
    const a = menuArray?.map((item) => {
      if (item === url || checkUrlInclude) {
        setCheckMenu(true);
      }
    });
  }
  function order() {
    const a = menuOrder?.map((item) => {
      if (item === url) {
        setOrderMenu(true);
      }
    });
  }
  function attribute() {
    const a = attributeMenus?.map((item) => {
      if (item === url) {
        setAttributeMenu(true);
      }
    });
  }
  useEffect(() => {
    setActiveMenuItem();
    checkUrl();
    order();
    attribute();
  }, []);

  return (
    <div>
      <div
        className="ms-aside-overlay ms-overlay-left ms-toggler"
        onClick={removeoverlay}
      ></div>
      <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
      <Scrollbar
        id="ms-side-nav"
        className="side-nav fixed ms-aside-scrollable ms-aside-left"
      >
        {/* Logo */}
        <div className="logo-sn ms-d-block-lg">
          <Link className="pl-0 ml-0 text-center" to="/dashboard">
            {/* <img src={logo} alt="logo" /> */}
            <h3>{t("Order 365")}</h3>
          </Link>
        </div>
        {/* Navigation */}
        <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
          {/* Dashboard */}
          <li className="menu-item">
            <Link
              className={
                location.pathname === "/dashboard"
                  ? "nav-item-in-view"
                  : "nav-item"
              }
              to="/dashboard"
            >
              {" "}
              <span>
                <i className="material-icons fs-16" style={{ width: "15%" }}>
                  dashboard
                </i>
                {t("Dashboard")}{" "}
              </span>
            </Link>
          </li>

          <li
            className="menu-item"
            style={{ display: "block" }}
            // style={{ display: checkMenu ? "block" : "none" }}
          >
            <Link to="#" className="has-chevron">
              {" "}
              <span
                className={
                  location.pathname.includes("/createProduct") ||
                  location.pathname.includes("/createCategory") ||
                  location.pathname.includes("/createAddons") ||
                  location.pathname.includes("/editCategory") ||
                  location.pathname.includes("/editProductList") ||
                  location.pathname.includes("/editAddons") ||
                  location.pathname.includes("/viewAddons") ||
                  location.pathname.includes("/viewProduct") ||
                  location.pathname.includes("/viewCategory") ||
                  location.pathname.includes("/menu-category") ||
                  location.pathname.includes("/menu-product") ||
                  location.pathname.includes("/menu-addons") ||
                  location.pathname.includes("/menu-catalogue") ||
                  location.pathname.includes("/add-product")
                    ? "nav-item-in-view"
                    : "nav-item"
                }
              >
                <i className="fa fa-archive fs-16" style={{ width: "15%" }} />
                {t("Menus")}{" "}
              </span>
            </Link>
            <ul
              id="product"
              className="collapse"
              aria-labelledby="product"
              data-parent="#side-nav-accordion"
              // style={{ display: url == "/menu-catalogue" ? "block" : "none" }}
              style={{ display: checkMenu ? "block" : "none" }}
            >
              <li>
                <Link
                  className={
                    // className={
                    //   location.pathname === "/orders" ? "nav-item-in-view" : "nav-item"
                    // }
                    location.pathname.includes("/menu-category") ||
                    location.pathname.includes("/createCategory") ||
                    location.pathname.includes("/editCategory") ||
                    location.pathname.includes("/viewCategory")
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                  id="menu_category"
                  to="/menu-category"
                >
                  {" "}
                  {t("Category")}
                </Link>
              </li>
              <li>
                <Link
                  className={
                    location.pathname.includes("/menu-product") ||
                    location.pathname.includes("/createProduct") ||
                    location.pathname.includes("/editProductList") ||
                    location.pathname.includes("/viewProduct")
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                  to="/menu-product"
                >
                  {" "}
                  {t("Product")}
                </Link>
              </li>
              <li>
                <Link
                  className={
                    location.pathname.includes("/menu-addons") ||
                    location.pathname.includes("/createAddons") ||
                    location.pathname.includes("/editAddons") ||
                    location.pathname.includes("/viewAddons")
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                  to="/menu-addons"
                >
                  {" "}
                  {t("Addons")}
                </Link>
              </li>
              {/* <li>
                {" "}
                <Link to="/menu-list">Menu List</Link>
              </li>
              <li>
                {" "}
                <Link to="/menu-grid">Menu Grid</Link>
              </li> */}
              <li>
                {" "}
                {/* <Link
                  className={
                    location.pathname === "/add-product"
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                  to="/add-product"
                >
                  {t("Add Product")}
                </Link> */}
              </li>
              {/* <li>
                {" "}
                <Link to="/product-detail">Product Detail</Link>
              </li> */}
            </ul>
          </li>
          <li className="menu-item">
            <Link
              className={
                location?.pathname === "/timeSchedule" ||
                location.pathname.includes("/editSchedule")
                  ? "nav-item-in-view"
                  : "nav-item"
              }
              to="/timeSchedule"
            >
              {" "}
              <span>
                <i className="material-icons fs-16" style={{ width: "15%" }}>
                  access_time
                </i>
                {t("Time Schedule")}{" "}
              </span>
            </Link>
          </li>
          {/* product end */}
          {/* orders */}

          <li
            className="menu-item"
            style={{ display: "block" }}
            // style={{ display: checkMenu ? "block" : "none" }}
          >
            <Link to="#" className="has-chevron">
              {" "}
              <span
                className={
                  location.pathname.includes("/orders") ||
                  location.pathname.includes("/orders-history")
                    ? // location.pathname.includes("/menu-catalogue") ||
                      // location.pathname.includes("/add-product")
                      "nav-item-in-view"
                    : "nav-item"
                }
              >
                <i className="fa fa-briefcase fs-16" style={{ width: "15%" }} />
                Orders
              </span>
            </Link>
            <ul
              id="product"
              className="collapse"
              aria-labelledby="product"
              data-parent="#side-nav-accordion"
              // style={{ display: url == "/menu-catalogue" ? "block" : "none" }}
              style={{ display: orderMenu ? "block" : "none" }}
            >
              <li className="menu-item">
                <Link
                  to="/orders"
                  className={
                    location.pathname === "/orders"
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                >
                  {" "}
                  <span>
                    {/* <i className="fas fa-clipboard-list fs-16" /> */}
                    Orders List
                  </span>
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  to="/orders-history"
                  className={
                    location.pathname === "/orders-history"
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                >
                  {" "}
                  <span>
                    {/* <i className="fas fa-clipboard-list fs-16" /> */}
                    Orders history
                  </span>
                </Link>
              </li>

              {/* <li>
                {" "}
                <Link to="/menu-list">Menu List</Link>
              </li>
              <li>
                {" "}
                <Link to="/menu-grid">Menu Grid</Link>
              </li> */}
              <li>
                {" "}
                {/* <Link
                  className={
                    location.pathname === "/add-product"
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                  to="/add-product"
                >
                  {t("Add Product")}
                </Link> */}
              </li>
              {/* <li>
                {" "}
                <Link to="/product-detail">Product Detail</Link>
              </li> */}
            </ul>
          </li>
          {/* <li className="menu-item">
            <Link
              to="/orders"
              className={
                location.pathname === "/orders"
                  ? "nav-item-in-view"
                  : "nav-item"
              }
            >
              {" "}
              <span>
                <i className="fas fa-clipboard-list fs-16" />
                Orders
              </span>
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/orders-history"
              className={
                location.pathname === "/orders-history"
                  ? "nav-item-in-view"
                  : "nav-item"
              }
            >
              {" "}
              <span>
                <i className="fas fa-clipboard-list fs-16" />
                Orders history
              </span>
            </Link>
          </li> */}
          <li className="menu-item">
            <Link
              to="/customer-list"
              className={
                location.pathname === "/customer-list"
                  ? "nav-item-in-view"
                  : "nav-item"
              }
            >
              {" "}
              <span>
                <i
                  className="fas fa-user-friends fs-16"
                  style={{ width: "15%" }}
                />
                Customers List{" "}
              </span>
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/coupon-list"
              className={
                location.pathname === "/coupon-list"
                  ? "nav-item-in-view"
                  : "nav-item"
              }
            >
              {" "}
              <span>
                <i className="fa fa-tasks fs-16" style={{ width: "15%" }} />
                Coupons{" "}
              </span>
            </Link>
          </li>

          <li
            className="menu-item"
            style={{ display: "block" }}
            // style={{ display: checkMenu ? "block" : "none" }}
          >
            <Link to="#" className="has-chevron">
              {" "}
              <span
                className={
                  location.pathname.includes("/attribute-list") ||
                  location.pathname.includes("/attribute-set")
                    ? // location.pathname.includes("/menu-catalogue") ||
                      // location.pathname.includes("/add-product")
                      "nav-item-in-view"
                    : "nav-item"
                }
              >
                <i className="fa fa-briefcase fs-16" style={{ width: "15%" }} />
                Configuration
              </span>
            </Link>
            <ul
              id="product"
              className="collapse"
              aria-labelledby="product"
              data-parent="#side-nav-accordion"
              // style={{ display: url == "/menu-catalogue" ? "block" : "none" }}
              style={{ display: attributeMenu ? "block" : "none" }}
            >
              <li className="menu-item">
                <Link
                  to="/attribute-list"
                  className={
                    location.pathname === "/attribute-list"
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                >
                  {" "}
                  <span>
                    {/* <i className="fas fa-clipboard-list fs-16" /> */}
                    Attribute
                  </span>
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  to="/attribute-set"
                  className={
                    location.pathname === "/attribute-set"
                      ? "nav-item-in-view"
                      : "nav-item"
                  }
                >
                  {" "}
                  <span>
                    {/* <i className="fas fa-clipboard-list fs-16" /> */}
                    Attribute Set
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          {/* orders end */}
          {/* restaurants */}
          {/* <li className="menu-item">
            <Link to="/restaurant-list">
              {" "}
              <span>
                <i className="fa fa-tasks fs-16" />
                Restaurants List
              </span>
            </Link>
          </li> */}
          {/* restaurants end */}
          {/* Invoice */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="fas fa-file-invoice fs-16" />
                Invoice{" "}
              </span>
            </Link>
            <ul
              id="invoice"
              className="collapse"
              aria-labelledby="invoice"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/invoice-detail">Invoice Detail</Link>
              </li>
              <li>
                {" "}
                <Link to="/invoice-list">Invoice List</Link>
              </li>
            </ul>
          </li> */}
          {/* Invoice end */}
          {/* customers*/}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="fas fa-user-friends fs-16" />
                Customers{" "}
              </span>
            </Link>
            <ul
              id="customer"
              className="collapse"
              aria-labelledby="customer"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/customer-review">Customers Review</Link>
              </li>
              <li>
                {" "}
                <Link to="/customer-list">Customers List</Link>
              </li>
              <li>
                {" "}
                <Link to="/social-activity">Social Activity</Link>
              </li>
            </ul>
          </li> */}
          {/* Customers  end */}
          {/* sales */}
          {/* <li className="menu-item">
            <Link to="/sales">
              {" "}
              <span>
                <i className="fa fa-briefcase fs-16" />
                Sales
              </span>
            </Link>
          </li> */}
          {/* sales end  */}
          {/* <li className="menu-item">
            <Link to="/widgets">
              {" "}
              <span>
                <i className="material-icons fs-16">widgets</i>Widgets
              </span>
            </Link>
          </li> */}
          {/* Basic UI Elements */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">filter_list</i>Basic UI
                Elements
              </span>
            </Link>
            <ul
              id="basic-elements"
              className="collapse"
              aria-labelledby="basic-elements"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/accordions">Accordions</Link>
              </li>
              <li>
                {" "}
                <Link to="/alerts">Alerts</Link>
              </li>
              <li>
                {" "}
                <Link to="/buttons">Buttons</Link>
              </li>
              <li>
                {" "}
                <Link to="/breadcrumbs">Breadcrumbs</Link>
              </li>
              <li>
                {" "}
                <Link to="/badges">Badges</Link>
              </li>
              <li>
                {" "}
                <Link to="/cards">Cards</Link>
              </li>
              <li>
                {" "}
                <Link to="/progress-bars">Progress Bars</Link>
              </li>
              <li>
                {" "}
                <Link to="/preloaders">Pre-loaders</Link>
              </li>
              <li>
                {" "}
                <Link to="/pagination">Pagination</Link>
              </li>
              <li>
                {" "}
                <Link to="/tabs">Tabs</Link>
              </li>
              <li>
                {" "}
                <Link to="/typography">Typography</Link>
              </li>
            </ul>
          </li> */}
          {/* /Basic UI Elements */}
          {/* Advanced UI Elements */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">code</i>Advanced UI Elements
              </span>
            </Link>
            <ul
              id="advanced-elements"
              className="collapse"
              aria-labelledby="advanced-elements"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/draggables">Draggables</Link>
              </li>
              <li>
                {" "}
                <Link to="/sliders">Sliders</Link>
              </li>
              <li>
                {" "}
                <Link to="/modals">Modals</Link>
              </li>
              <li>
                {" "}
                <Link to="/rating">Rating</Link>
              </li>
              <li>
                {" "}
                <Link to="/tour">Tour</Link>
              </li>
              <li>
                {" "}
                <Link to="/cropper">Cropper</Link>
              </li>
              <li>
                {" "}
                <Link to="/range-slider">Range Slider</Link>
              </li>
            </ul>
          </li> */}
          {/* /Advanced UI Elements */}
          {/* <li className="menu-item">
            <Link to="/animations">
              {" "}
              <span>
                <i className="material-icons fs-16">format_paint</i>Animations
              </span>
            </Link>
          </li> */}
          {/* Form Elements */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">input</i>Form Elements
              </span>
            </Link>
            <ul
              id="form-elements"
              className="collapse"
              aria-labelledby="form-elements"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/form-elements">Form Elements</Link>
              </li>
              <li>
                {" "}
                <Link to="/form-layouts">Form Layouts</Link>
              </li>
              <li>
                {" "}
                <Link to="/form-validation">Form Validation</Link>
              </li>
              <li>
                {" "}
                <Link to="/form-wizard">Form Wizard</Link>
              </li>
            </ul>
          </li> */}
          {/* /Form Elements */}
          {/* Charts */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">equalizer</i>Charts
              </span>
            </Link>
            <ul
              id="charts"
              className="collapse"
              aria-labelledby="charts"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/chartjs">Chart JS</Link>
              </li>
              <li>
                {" "}
                <Link to="/google-chart">Google Chart</Link>
              </li>
            </ul>
          </li> */}
          {/* /Charts */}
          {/* Tables */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">tune</i>Tables
              </span>
            </Link>
            <ul
              id="tables"
              className="collapse"
              aria-labelledby="tables"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/basic-tables">Basic Tables</Link>
              </li>
              <li>
                {" "}
                <Link to="/data-tables">Data tables</Link>
              </li>
            </ul>
          </li> */}
          {/* /Tables */}
          {/* Popups */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">message</i>Popups
              </span>
            </Link>
            <ul
              id="popups"
              className="collapse"
              aria-labelledby="popups"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/sweet-alerts">Sweet Alerts</Link>
              </li>
              <li>
                {" "}
                <Link to="/toast">Toast</Link>
              </li>
            </ul>
          </li> */}
          {/* /Popups */}
          {/* Icons */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">border_color</i>Icons
              </span>
            </Link>
            <ul
              id="icons"
              className="collapse"
              aria-labelledby="icons"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/fontawesome">Fontawesome</Link>
              </li>
              <li>
                {" "}
                <Link to="/flaticons">Flaticons</Link>
              </li>
              <li>
                {" "}
                <Link to="/materialize">Materialize</Link>
              </li>
            </ul>
          </li> */}
          {/* /Icons */}
          {/* Maps */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">map</i>Maps
              </span>
            </Link>
            <ul
              id="maps"
              className="collapse"
              aria-labelledby="maps"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/google-maps">Google Maps</Link>
              </li>
              <li>
                {" "}
                <Link to="/vector-maps">Vector Maps</Link>
              </li>
            </ul>
          </li> */}
          {/* /Maps */}
          {/* <li className="menu-item">
            <Link
              to="#"
              className="has-chevron"
              data-toggle="collapse"
              data-target="#bonuspages"
              aria-expanded="false"
              aria-controls="bonuspages"
            >
              {" "}
              <span>
                <i className="material-icons fs-16">insert_drive_file</i> Bonus
                Pages
              </span>
            </Link>
            <ul
              id="bonuspages"
              className="collapse"
              aria-labelledby="bonuspages"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/web-analytics"> Web Analytics </Link>
              </li>
              <li>
                {" "}
                <Link to="/stock-management">Stock Management</Link>
              </li>
              <li>
                {" "}
                <Link to="/client-management">Client Management</Link>
              </li>
            </ul>
          </li> */}
          {/* / bonus Pages */}
          {/* Pages */}
          {/* <li className="menu-item">
            <Link
              to="#"
              className="has-chevron"
              data-toggle="collapse"
              data-target="#pages"
              aria-expanded="false"
              aria-controls="pages"
            >
              {" "}
              <span>
                <i className="material-icons fs-16">insert_drive_file</i>Pages
              </span>
            </Link>
            <ul
              id="pages"
              className="collapse"
              aria-labelledby="pages"
              data-parent="#side-nav-accordion"
            >
              <li className="menu-item">
                {" "}
                <Link
                  to="#"
                  className="has-chevron"
                  data-toggle="collapse"
                  data-target="#authentication"
                  aria-expanded="false"
                  aria-controls="authentication"
                >
                  Authentication
                </Link>
                <ul
                  id="authentication"
                  className="collapse"
                  aria-labelledby="authentication"
                  data-parent="#pages"
                >
                  <li>
                    {" "}
                    <Link to="/default-login">Default Login</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/modal-login">Modal Login</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/default-register">Default Registration</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/modal-register">Modal Registration</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/lock-screen">Lock Screen</Link>
                  </li>
                </ul>
              </li>
              <li>
                {" "}
                <Link to="/coming-soon">Coming Soon</Link>
              </li>
              <li>
                {" "}
                <Link to="/error">Error Page</Link>
              </li>
              <li className="menu-item">
                {" "}
                <Link to="/faq"> FAQ </Link>
              </li>
              <li className="menu-item">
                {" "}
                <Link to="/portfolio"> Portfolio </Link>
              </li>
              <li className="menu-item">
                {" "}
                <Link to="/user-profile"> User Profile </Link>
              </li>
              <li className="menu-item">
                {" "}
                <Link to="/invoice"> Invoice </Link>
              </li>
            </ul>
          </li> */}
          {/* Apps */}
          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="material-icons fs-16">phone_iphone</i>Apps
              </span>
            </Link>
            <ul
              id="apps"
              className="collapse"
              aria-labelledby="apps"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/chat">Chat</Link>
              </li>
              <li>
                {" "}
                <Link to="/email">Email</Link>
              </li>
              <li>
                {" "}
                <Link to="/todo-list">To-do List</Link>
              </li>
            </ul>
          </li> */}
          {/* /Apps */}
        </ul>
      </Scrollbar>
    </div>
  );
};

export default Sidenavigation;
