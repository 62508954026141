import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";
import customAxios from "../../../customAxios";
import authHeader from "../../../authHeader";
import { updateAttributeSet } from "../../../Redux/attributeSetSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Content = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  const [value, setValue] = React.useState("en");
  const [isLoadingData, setLoadingData] = useState(false);
  // const [imageUpload, setImageUpload] = useState([]);
  // const [selectTypes, setSelectTypes] = useState();
  // const [payloadType, setPayloadType] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { id } = useParams();
  const { singleAttributeSetData, attributeSetData } = useSelector(
    (state) => state.attributeSetSlice
  );

  const [saveData, setSaveData] = useState([]);

  useEffect(() => {
    if (id) {
      let singleData = "/vendor/attribute-set/show/" + id;
      customAxios
        .get(singleData, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setSaveData(data?.data);
          }
        })
        .catch(function (error) {});
    }
  }, []);

  // const addConvert = [singleAttributeData];
  // const addName = [singleAttributeData];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameen: saveData?.value?.en,
      namear: "",
      namede: "",
      is_active: "",
    },
    // validate,
    onSubmit: (values) => {
      let namevalue = {
        en: values.nameen,
        ar: values.namear,
        de: values.namede,
      };
      //   dispatch(updateCategory(request));

      const payload = {
        value: namevalue,
        is_active: isChecked,
      };

      const request = {
        payload,
        id,
        onSuccess: async (message) => {
          setLoadingData(false);
          toast.success(message);
          history.push("/attribute-set");
        },
        onFail: (message) => {
          toast.error(message);
        },
      };
      setLoadingData(true);
      dispatch(updateAttributeSet(request));
    },
  });
  const checkhandleChange = (event) => {
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    setIsCheck((prev) => prev?.saveData);
    let status = saveData?.is_active === 1 ? true : false;
    setIsChecked(status);
  }, [saveData]);

  // const selectedOptions = () => {
  //   if (attributeSetData?.data) {
  //     const newdatavalue = attributeSetData?.data?.filter((parent) => {
  //       if (parent.uuid == id) {
  //         setSelectTypes(parent?.type);
  //       }
  //     });
  //   }
  // };

  // useEffect(() => {
  //   selectedOptions();
  // }, [attributeSetData?.data]);

  return (
    <div className="">
      <div className="col-xl-12 col-md-12">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-header">
            <h6>{t("Edit Attribute Set")}</h6>
          </div>
          <div className="ms-panel-body">
            <form
              className="needs-validation clearfix"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              {isLoadingData ? (
                <>
                  {" "}
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "30%",
                      zIndex: "1",
                      color: "orange",
                      animation: "none",
                      // animationDuration: "1s",
                    }}
                  />
                </>
              ) : null}
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="validationCustom18"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Value")}&nbsp;[
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="secondary tabs example"
                      // textColor="secondary"
                    >
                      <Tab
                        value="en"
                        label="English"
                        {...a11yProps("en")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="ar"
                        label="Arabic"
                        {...a11yProps("ar")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="de"
                        label="Germen"
                        {...a11yProps("de")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                    </Tabs>
                    ]
                  </label>
                  <TabPanel value={value} index={"en"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Value"
                        name="nameen"
                        onChange={formik.handleChange}
                        value={formik.values.nameen}
                      />
                    </div>
                    {/* Item One */}
                  </TabPanel>
                  <TabPanel value={value} index={"ar"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Value"
                        name="namear"
                        onChange={formik.handleChange}
                        value={formik.values.namear}
                      />
                    </div>
                    {/* Item Two */}
                  </TabPanel>
                  <TabPanel value={value} index={"de"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Value"
                        name="namede"
                        onChange={formik.handleChange}
                        value={formik.values.namede}
                      />
                    </div>
                  </TabPanel>
                </div>
                {/* <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Type")}</label>
                  <div className="input-group">
                    <Select
                      name="type"
                      onChange={handleTypeChange}
                      // value={parentOptions}
                      options={selectType}
                      value={selectType?.find(
                        (check) => check?.value == selectTypes
                      )}
                      placeholder="Type"
                      className="form-gender"
                    />
                  </div>
                </div> */}
                <div className="col-md-12 mb-3">
                  <label className="ms-checkbox-wrap ms-checkbox-primary">
                    <input
                      type="checkbox"
                      defaultValue
                      id="validationCustom18"
                      name="is_active"
                      checked={isChecked}
                      onChange={(event) => {
                        checkhandleChange(event);
                      }}
                    />{" "}
                    <i className="ms-checkbox-check" />
                  </label>{" "}
                  <span> {t("Is Active")} </span>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="ms-panel-header new buttonConatiner">
                    <button
                      className="btn btn-secondary d-block cancelButton"
                      type="submit"
                      onClick={() => history.push("/attribute-set")}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary d-block saveButton"
                      type="submit"
                    >
                      {t("Update")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
