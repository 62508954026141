import React, { Component, useEffect } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Homecontent from "../sections/Dashboard/Homecontent";
import Quickbar from "../layouts/Quickbar";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");

  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);
  // render() {
  return (
    <div
      className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar"
      style={{ flexDirection: "row-reverse" }}
    >
      <Sidenavigation t={t} />
      <main className="body-content">
        <Topnavigation />
        <Homecontent t={t} />
      </main>
      {/* <Quickbar /> */}
    </div>
  );
  // }
};

export default Dashboard;
