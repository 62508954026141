import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  objectFit: "contain",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 16,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

export const Singledropzone = ({ setImageUpload }) => {
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // const [preview, setPreview] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((imageFile) => {
      convertBase64(imageFile)
        .then((result) => {
          imageFile["base64"] = result;
          setImageUpload(imageFile["base64"]);
        })
        .catch((err) => {});
    });
    const fileobject = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    // setPreview((prevState) => {
    //   console.log(prevState, "prevState");
    //   return [...prevState, ...fileobject];
    // });
  }, []);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
      onDrop,
    });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <div style={thumb}>
      <div key={file.path} style={thumbInner}>
        <img
          src={file?.preview}
          style={img}
          alt={file.name}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        {/* {file.path} - {file.size} bytes */}
      </div>
    </div>
  ));

  useEffect(() => {
    return () =>
      acceptedFiles?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    // <section className="container">
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      <div
        style={{
          border: "1px dashed black",
          width: "100px",
          height: "100px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        {/* <p>Drag 'n' drop </p> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <i class="Medium material-icons">add_a_photo</i>
          <p style={{ margin: "0" }}>Change</p>
          <p style={{ margin: "0" }}>Image</p>
        </div>
      </div>
      {acceptedFileItems}
    </div>
    // </section>
  );
};
