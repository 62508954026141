import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./userSlice";
import categorySlice from "./categorySlice";
import productSlice from "./productSlice";
import addonSlice from "./addonSlice";
import timeScheduleSlice from "./timeScheduleSlice";
import attributeSlice from "./attributeSlice";
import attributeSetSlice from "./attributeSetSlice";

export const store = configureStore({
  reducer: {
    loginSlice,
    categorySlice,
    productSlice,
    addonSlice,
    timeScheduleSlice,
    attributeSlice,
    attributeSetSlice,
  },
});
