import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { singleAddon } from "../../../Redux/addonSlice";
import { useState } from "react";
import customAxios from "../../../customAxios";
import authHeader from "../../../authHeader";
import { CircularProgress } from "@mui/material";

const Content = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  // useEffect(() => {
  //   dispatch(singleAddon(id));
  // }, []);

  const [saveData, setSaveData] = useState([]);
  const [active, setActive] = useState();
  const [refresh, setRefresh] = useState(false);

  const { singleAddonData } = useSelector((state) => state.addonSlice);

  const viewData = singleAddonData;
  const { t, i18n } = useTranslation();

  const currentLang = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();
  const [data, setData] = useState([]);
  let newData;
  useEffect(() => {
    setCurrLang(currentLang);
  }, [currentLang]);

  useEffect(() => {
    if (id) {
      let singleData = "/vendor/addons/show/" + id;
      setRefresh(true);

      customAxios
        .get(singleData, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setSaveData(data?.data);
          }
        })
        .catch(function (error) {})
        .finally(() => {
          setRefresh(false);
        });
    }
  }, []);

  // useEffect(() => {});

  const handleCancel = () => {
    history.push("/menu-addons");
    setSaveData([]);
  };

  return (
    <div className="">
      <div className="col-xl-9 col-md-9">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-body">
            <div className="ms-profile-user-buttons viewbuttons">
              <h2 className="section-title new-heading">{t("View Addons")}</h2>
              <button
                className="btn-primary d-block mt-0"
                type="submit"
                onClick={handleCancel}
              >
                {t("Back")}
              </button>
            </div>
            <table className="table ms-profile-information">
              <tbody>
                {refresh ? (
                  <>
                    <CircularProgress
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                        color: "orange",
                        animation: "none",
                        // animationDuration: "1s",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <tr>
                      <th scope="row">{t("Name")}</th>
                      <td style={{ textAlign: "justify" }}>
                        {saveData?.name && saveData?.name[currentLang]}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{t("Amount")}</th>
                      <td style={{ textAlign: "justify" }}>
                        {saveData?.amount}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "40%" }}>
                        {t("Description")}
                      </th>
                      <td
                        style={{ whiteSpace: "inherit", textAlign: "justify" }}
                      >
                        {saveData?.description?.en}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{t("Is Active")}</th>
                      <td style={{ textAlign: "justify" }}>
                        {saveData?.is_active === 1 ? (
                          <span style={{ color: "green" }}>Active</span>
                        ) : saveData?.is_active === 0 ? (
                          <span style={{ color: "red" }}>Inactive</span>
                        ) : null}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
