import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTime } from "../../../Redux/timeScheduleSlice";

const TimeSchedule = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getTimeData, isLoading } = useSelector(
    (state) => state.timeScheduleSlice
  );

  useEffect(() => {
    dispatch(getTime());
  }, []);

  const getEditCategory = (id) => {
    history.push(`/editSchedule/${id}`);
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <div
          style={{
            padding: "1rem 1rem 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4>{t("Time Schedule")}</h4>
        </div>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Days")}</TableCell>
              <TableCell align="right">{t("Is Working")}</TableCell>
              <TableCell align="right">{t("Opening Time")}</TableCell>
              <TableCell align="right">{t("Closing Time")}</TableCell>
              <TableCell align="right">{t("Action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              <>
                {!getTimeData?.length ? (
                  <>
                    <b
                      style={{
                        color: "red",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No Data Available
                    </b>
                  </>
                ) : (
                  <>
                    {getTimeData.map((row) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: row?.is_working === 0 ? "red" : "#4caf50fa",
                            fontWeight: "600",
                          }}
                        >
                          <span
                            className={
                              row?.is_working === 0
                                ? "badge badge-danger"
                                : "badge badge-success"
                            }
                          >
                            {row?.day_type?.name?.en}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          {row?.is_working === 1 ? "Open" : "Close"}
                        </TableCell>
                        <TableCell align="right">
                          {row?.opening_time === null
                            ? "00:00:00"
                            : row?.opening_time}
                        </TableCell>
                        <TableCell align="right">
                          {row?.closing_time === null
                            ? "00:00:00"
                            : row?.closing_time}
                        </TableCell>
                        <TableCell align="right">
                          <li
                            className="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Edit"
                          >
                            <div
                              onClick={() => getEditCategory(row?.id)}
                              // href="/editCategory"
                              className="text-primary d-inline-block edit-item-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fas fa-edit"></i>
                            </div>
                          </li>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            ) : (
              <CircularProgress
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  color: "orange",
                  animation: "none",
                  // animationDuration: "1s",
                }}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TimeSchedule;
