import React, { Component, useEffect } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Catelougebox from "../sections/Menucatelogue/Catelougebox";
import Quickbar from "../layouts/Quickbar";
import Content from "../sections/Product/Content";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../sections/Product/Breadcrumb";
import { useHistory } from "react-router-dom";

const Product = (props) => {
  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");
  const history = useHistory();
  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="col-md-12">
          <Breadcrumb />
          <Content t={t} history={history} props={props} />
        </div>
      </main>
      {/* <Quickbar /> */}
    </div>
  );
};

export default Product;
