import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import Routes from "./Routes/Routes";

const App = () => {
  return (
    <Switch>
      <Routes />
    </Switch>
  );
};

export default App;
