import React from "react";

import { createSlice } from "@reduxjs/toolkit";
import { ApiService } from "../Api/ApiService";
const initialState = {
  isLoading: false,
  token: "",
  errorMessage: "",
  successMessage: "",
  singleAddonData: {},
  addonData: [],
  //   parent_category_id: "",
  // userData: "",
};
export const addonSlice = createSlice({
  name: "addon",
  initialState,
  reducers: {
    createAddonRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    createAddonSuccessful(state, action) {
      state.isLoading = false;
      state.addonData = action.payload;
    },
    createAddonFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    addonListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
      state.addonData = [];
    },
    addonListSuccessful(state, action) {
      state.isLoading = false;
      state.addonData = action.payload;
    },
    addonListFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    updateAddonRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    updateAddonSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    updateAddonFailed(state, action) {
      state.errorMessage = action.payload;
    },
    singleAddonRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    singleAddonSuccessful(state, action) {
      state.isLoading = false;
      state.singleAddonData = action.payload;
    },
    singleAddonFailed(state, action) {
      state.errorMessage = action.payload;
    },
    deleteAddonRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    deleteAddonSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    deleteAddonFailed(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  createAddonRequested,
  createAddonSuccessful,
  createAddonFailed,
  addonListRequested,
  addonListSuccessful,
  addonListFailed,
  updateAddonRequested,
  updateAddonSuccessful,
  updateAddonFailed,
  singleAddonRequested,
  singleAddonSuccessful,
  singleAddonFailed,
  deleteAddonRequested,
  deleteAddonSuccessful,
  deleteAddonFailed,
} = addonSlice.actions;

export default addonSlice.reducer;

export const createAddon = (request) => async (dispatch, getState) => {
  //   const { token } = getState().createCategory;
  const categoryToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = categoryToken?.token;

  const { payload, onSuccess, onFail } = request;
  //   const {businessId} = getState().loginSlice;
  dispatch(createAddonRequested());
  try {
    const res = await ApiService.createAddon(payload, authtoken);

    if (res?.status === 201) {
      dispatch(createAddonSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        createAddonFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        createAddonFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        createAddonFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const addonList = (currPage) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  const { token } = getState().loginSlice;
  dispatch(addonListRequested());
  try {
    const res = await ApiService.addonList(authtoken, currPage);

    if (res?.status === 200) {
      dispatch(addonListSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        addonListFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        addonListFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        addonListFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const updateAddon = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail, id } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(updateAddonRequested());
  try {
    const res = await ApiService.updateAddon(payload, authtoken, id);
    if (res?.status === 202) {
      dispatch(updateAddonSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        updateAddonFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        updateAddonFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        updateAddonFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const singleAddon = (uuid) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  const { token } = getState().loginSlice;
  dispatch(singleAddonRequested());
  try {
    const res = await ApiService.singleAddon(authtoken, uuid);

    if (res?.status === 200) {
      dispatch(singleAddonSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        singleAddonFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        singleAddonFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        singleAddonFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};
export const deleteAddon = (uuid) => async (dispatch, getState) => {
  // const { payload, onSuccess, onFail, uuid } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(deleteAddonRequested());
  try {
    const res = await ApiService.deleteAddon(authtoken, uuid);
    if (res?.status === 200) {
      dispatch(deleteAddonSuccessful());
      // onSuccess(res?.data?.message || "Something went wrong");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        deleteAddonFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        deleteAddonFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        deleteAddonFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
