import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { singleAddon } from "../../../Redux/addonSlice";
import { useState } from "react";
import customAxios from "../../../customAxios";
import authHeader from "../../../authHeader";

const Content = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  // useEffect(() => {
  //   dispatch(singleAddon(id));
  // }, []);
  // const languageArabic = localStorage.getItem("lang");

  const [saveData, setSaveData] = useState([]);
  const [active, setActive] = useState();

  // const languageArabic = localStorage.getItem("lang");

  // const [currLang, setCurrLang] = useState();

  // useEffect(() => {
  //   setCurrLang(languageArabic);
  // }, [languageArabic]);
  // const { singleAddonData } = useSelector((state) => state.addonSlice);

  // const viewData = singleAddonData;
  const { t, i18n } = useTranslation();

  // let userName = singleAddonData?.name?.map((name) => name?.en);
  useEffect(() => {
    if (id) {
      let singleData = "/vendor/attribute-set/show/" + id;
      customAxios
        .get(singleData, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setSaveData(data?.data);
          }
        })
        .catch(function (error) {});
    }
  }, []);

  // useEffect(() => {});

  const handleCancel = () => {
    history.push("/attribute-set");
    setSaveData([]);
  };

  return (
    <div className="">
      <div className="col-xl-9 col-md-9">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-body">
            <div className="ms-profile-user-buttons viewbuttons">
              <h2 className="section-title new-heading">
                {t("View Attribute Set")}
              </h2>
              <button
                className="btn-primary d-block mt-0"
                type="submit"
                onClick={handleCancel}
              >
                {t("Back")}
              </button>
            </div>
            <table className="table ms-profile-information">
              <tbody>
                {/* <tr>
                  <th scope="row">{t("Name")}</th>
                  <td style={{ textAlign: "justify" }}>{saveData?.key}</td>
                </tr> */}
                <tr>
                  <th scope="row">{t("Value")}</th>
                  <td style={{ textAlign: "justify" }}>
                    {saveData?.value?.en}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t("Is Active")}</th>
                  <td style={{ textAlign: "justify" }}>
                    {saveData?.is_active === 1 ? (
                      <span style={{ color: "green" }}>Active</span>
                    ) : saveData?.is_active === 0 ? (
                      <span style={{ color: "red" }}>Inactive</span>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
