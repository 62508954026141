import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { getTime, updateTime } from "../../../Redux/timeScheduleSlice";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import moment from "moment/moment";

const Content = ({ t }) => {
  const [workingOptions, setWorkingOptions] = useState("");
  const [singleDay, setSingleDay] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const [opening, setOpening] = React.useState("");
  const [closing, setClosing] = React.useState("");
  const [payloadcat, setPayloadcat] = useState(null);
  const [isLoadingData, setLoadingData] = useState(false);

  const { id } = useParams();

  const handleOpenChange = (openValue) => {
    setOpening(dayjs(openValue));
  };

  const handleCloseChange = (closeValue) => {
    setClosing(dayjs(closeValue));
  };
  const { getTimeData, isLoading } = useSelector(
    (state) => state.timeScheduleSlice
  );

  // const stringToDate = (input) => {
  //   const [hours, minutes, seconds] = input?.split(":");
  //   const date = new Date();
  //   date.setHours(hours);
  //   date.setMinutes(minutes);
  //   date.setSeconds(seconds);
  //   return date.toString();
  // };
  const stringToDate = (input) => {
    if (!input) return ""; // Return an empty string if the input is undefined or empty

    const [hours = 0, minutes = 0, seconds = 0] = input.split(":").map(Number);
    const date = new Date();

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    return date.toString();
  };

  const selectedParent = () => {
    if (getTimeData?.length) {
      const newdatavalue = getTimeData?.map((parent) => {
        if (parent.id == id) {
          setSingleDay(parent);
          setOpening(dayjs(stringToDate(parent?.opening_time)));
          setClosing(dayjs(stringToDate(parent?.closing_time)));
          setWorkingOptions(parent?.is_working);
        }
      });
    }
  };

  const workingOption = [
    {
      value: "1",
      label: "Open",
    },
    {
      value: "0",
      label: "Close",
    },
  ];

  const handleWorkingChange = (args) => {
    setWorkingOptions(args?.value);
    // setPayloadcat(payloadCategory);
  };

  const checkSchedulehandleChange = () => {
    const payload = JSON.stringify({
      schedule: [
        {
          id: id,
          opening_time: dayjs(opening).format("HH:mm"),
          closing_time: dayjs(closing).format("HH:mm"),
          is_working: workingOptions,
        },
      ],
    });
    setLoadingData(true);
    const request = {
      payload,
      onSuccess: async (message) => {
        toast.success(message);
        history.push("/timeSchedule");
        setLoadingData(false);
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(updateTime(request));
  };
  useEffect(() => {
    dispatch(getTime());
  }, []);

  useEffect(() => {
    selectedParent();
  }, [getTimeData]);

  return (
    <div>
      <TableContainer component={Paper}>
        <div
          style={{
            padding: "1rem 1rem 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4>{t("Edit Schedule")}</h4>
        </div>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Days")}</TableCell>
              <TableCell align="left">{t("Is Working")}</TableCell>
              <TableCell align="left">{t("Opening Time")}</TableCell>
              <TableCell align="left">{t("Closing Time")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingData ? (
              <>
                {" "}
                <CircularProgress
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    color: "orange",
                    animation: "none",
                    // animationDuration: "1s",
                  }}
                />
              </>
            ) : null}{" "}
            <TableRow>
              <TableCell component="th" scope="row">
                {singleDay?.day_type?.name?.en}
              </TableCell>
              <TableCell align="right">
                <div className="">
                  <div
                    className="input-group work-opt"
                    style={{ marginBottom: "0" }}
                  >
                    <Select
                      placeholder="Is Working"
                      className="form-gender"
                      name="lang"
                      onChange={handleWorkingChange}
                      value={workingOption.find(
                        (check) => check.value == workingOptions
                      )}
                      options={workingOption}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="right">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    // label="Time"
                    value={opening}
                    ampm={false}
                    onChange={handleOpenChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell align="right">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    //   label="Time"
                    value={closing}
                    ampm={false}
                    onChange={handleCloseChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="col-md-12 mb-3">
          <div className="ms-panel-header new buttonConatiner">
            <button
              className="btn btn-secondary d-block cancelButton"
              type="submit"
              onClick={() => history.push("/timeSchedule")}
              style={{ minWidth: "100px", padding: "0.4rem" }}
            >
              {t("Cancel")}
            </button>
            <IconButton>
              <div
                onClick={checkSchedulehandleChange}
                className="btn btn-primary new-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: "0.4rem",
                }}
              >
                <i className="material-icons">access_time</i>{" "}
                {t("Update Schedule")}{" "}
              </div>{" "}
            </IconButton>
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default Content;
