import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { ApiService } from "../Api/ApiService";

const initialState = {
  isLoading: false,
  token: "",
  errorMessage: "",
  successMessage: "",
  getTimeData: [],
  singleTimeData: {},
};

export const timeScheduleSlice = createSlice({
  name: "timeSchedule",
  initialState,
  reducers: {
    getTimeRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
      state.getTimeData = [];
    },
    getTimeSuccessful(state, action) {
      state.isLoading = false;
      state.getTimeData = action.payload;
    },
    getTimeFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    updateTimeRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    updateTimeSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    updateTimeFailed(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  getTimeRequested,
  getTimeSuccessful,
  getTimeFailed,
  updateTimeRequested,
  updateTimeSuccessful,
  updateTimeFailed,
} = timeScheduleSlice.actions;

export default timeScheduleSlice.reducer;

export const getTime = () => async (dispatch, getState) => {
  // const { currPage } = request;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  const { token } = getState().loginSlice;
  dispatch(getTimeRequested());
  try {
    const res = await ApiService.getTime(authtoken);

    if (res?.status === 200) {
      dispatch(getTimeSuccessful(res?.data?.data));
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        getTimeFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        getTimeFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        getTimeFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const updateTime = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(updateTimeRequested());
  try {
    const res = await ApiService.updateTime(payload, authtoken);
    if (res?.status === 201) {
      dispatch(updateTimeSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        updateTimeFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        updateTimeFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        updateTimeFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
