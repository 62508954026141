import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  deleteProductImage,
  productList,
  singleProduct,
  updateProduct,
} from "../../../Redux/productSlice";
import { categoryList } from "../../../Redux/categorySlice";
import { addonList } from "../../../Redux/addonSlice";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  CircularProgress,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { MyDropzone } from "../Dropzone/Dropzone";
import customAxios from "../../../customAxios";
import authHeader from "../../../authHeader";
import { attributeList } from "../../../Redux/attributeSlice";
import { attributeSetList } from "../../../Redux/attributeSetSlice";
import { Button } from "react-bootstrap";
import Collapsed from "../../pages/Collapsed";
import EditCollapsed from "../../pages/EditCollapsed";
import ProductAttribute from "../../pages/ProductAttribute";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Content = (props) => {
  const { state } = props.props.props.location;
  const langs = localStorage.getItem("lang");

  const [checking, setChecking] = useState([]);
  const { singleProductData } = useSelector((state) => state.productSlice);

  const { categoryData } = useSelector((state) => state.categorySlice);
  const { addonData } = useSelector((state) => state.addonSlice);
  const pageone = 1;
  const [imageUpload, setImageUpload] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [categoriesOption, setCategoriesOption] = useState("");
  const [payloadcat, setPayloadcat] = useState("");
  const [addonsOption, setAddonsOption] = useState("");
  const [isLoadingData, setLoadingData] = useState(false);
  const [payloadaddon, setPayloadaddon] = useState("");
  const [ischeck, setIsCheck] = useState(false);
  const [productMultiData, setProductMultiData] = useState([]);

  const [value, setValue] = React.useState("en");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [shortDesc, setShortDesc] = React.useState("en");
  const handleShortDesc = (event, newShortDesc) => {
    setShortDesc(newShortDesc);
  };
  const [desc, setDesc] = React.useState("en");
  const handleDesc = (event, newDesc) => {
    setDesc(newDesc);
  };

  const dispatch = useDispatch();
  const { id } = useParams();

  const [saveData, setSaveData] = useState([]);
  const [categoryyyy, setCategoryyyy] = useState([]);
  const [addonsss, setAddonsss] = useState([]);

  useEffect(() => {
    if (saveData) {
      setChecking(saveData);
    }
  }, [saveData]);

  useEffect(() => {
    if (id) {
      let singleData = "/vendor/product/show/" + id;
      customAxios
        .get(singleData, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setSaveData(data?.data);
            const defaultCategories = data?.data?.categories?.map((categ) => ({
              value: categ?.id,
              label: categ?.name?.en,
            }));
            setCategoriesOption(defaultCategories);
            setCategoryyyy(
              defaultCategories?.map((category) => category?.value)
            );

            const defaultAddons = data?.data?.addons?.map((adon) => ({
              value: adon?.id,
              label: adon?.name?.en,
            }));
            setAddonsOption(defaultAddons);
            setAddonsss(defaultAddons?.map((addon) => addon?.value));
          }
        })
        .catch(function (error) {});
    }
  }, [id]);

  const newdatacat = categoryData?.data?.map((category) => ({
    value: category?.id,
    label: category?.name?.en,
  }));

  const newaddondata = addonData?.data?.map((addon) => ({
    value: addon?.id,
    label: addon?.name?.en,
  }));

  const history = useHistory();
  const dataFromProp = [singleProductData];

  const payloadToFormData = async (payloadData) => {
    let formData = new FormData();
    try {
      for (let key in payloadData) {
        if (Array.isArray(payloadData[key])) {
          // console.log(key, ":", payloadData[key], "keyyyssssss");
          payloadData[key].forEach((item, index) => {
            // console.log(key, ":", item, "forEach item");
            if (typeof item === "object") {
              // console.log(item, "item inside object");
              for (let keyItem in item) {
                // console.log(keyItem, ":", item[keyItem], "object for");
                if (Array.isArray(item[keyItem])) {
                  // console.log(item[keyItem], "keyItem checkkk");
                  item[keyItem] &&
                    item[keyItem].forEach((itemItem, indexItem) => {
                      if (Object.keys(itemItem).length) {
                        for (let keys in itemItem) {
                          // console.log(keys, "keys");
                          formData.append(
                            `${key}[${index}][${keyItem}][${indexItem}][${keys}]`,
                            itemItem[keys]
                          );
                        }
                      }
                    });
                } else {
                  // console.log(
                  //   keyItem,
                  //   ":",
                  //   item[keyItem],
                  //   `${key}[${index}][${keyItem}]`,
                  //   item[keyItem],
                  //   "keyItem-else"
                  // );
                  formData.append(
                    `${key}[${index}][${keyItem}]`,
                    item[keyItem]
                  );
                }
              }
            } else {
              // console.log(
              //   `${key}[${index}]`,
              //   item[`${index}`],
              //   "forEach item ELSEE"
              // );
              formData.append(`${key}[${index}]`, item);
            }
            // for (let itemData in item) {

            // }
          });
        } else if (typeof payloadData[key] === "object") {
          formData.append(`${key}[${langs}]`, payloadData[key][langs]);
        } else {
          formData.append(key, payloadData[key]);
        }
      }

      return formData;
    } catch (err) {
      // console.log(err, "payload");
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sku_code: saveData?.sku_code,
      nameen: saveData?.name?.en,
      namear: "",
      namede: "",
      short_descriptionen: saveData?.short_description?.en,
      short_descriptionar: "",
      short_descriptionde: "",
      descriptionen: saveData?.description?.en,
      descriptionar: "",
      descriptionde: "",
      amount: saveData?.amount,
      quality: saveData?.quality,
      categories: categoryyyy,
      addons: addonsss,
      product_attributes: productMultiData,
      product_images: saveData?.product_images,
      is_active: "",
    },
    // validate,
    onSubmit: async (values) => {
      // const formData = new FormData();
      // Object.keys(values).forEach((key) => {
      //   formData.append(key, values[key]);
      // });
      let namevalue = {
        en: values.nameen,
        ar: values.namear,
        de: values.namede,
      };
      let shortdescvalue = {
        en: values.short_descriptionen,
        ar: values.short_descriptionar,
        de: values.short_descriptionde,
      };
      let descvalue = {
        en: values.descriptionen,
        ar: values.descriptionar,
        de: values.descriptionde,
      };

      const payload = {
        sku_code: values.sku_code,
        name: namevalue,
        short_description: shortdescvalue,
        description: descvalue,
        amount: values.amount,
        quality: values.quality,
        categories: categoryyyy,
        addons: addonsss,
        product_attributes: productMultiData,
        product_images: imageUpload,
        is_active: isChecked,
      };
      // formik.handleReset();
      const formDataPayload = await payloadToFormData(payload);

      const request = {
        id,
        payload: formDataPayload,
        onSuccess: async (message) => {
          setLoadingData(false);
          toast.success(message);
          history.push("/menu-product");
        },
        onFail: (message) => {
          toast.error(message);
        },
      };
      setLoadingData(true);
      dispatch(updateProduct(request));
    },
  });

  // console.log(productMultiData, "productMultiData");
  const { t, i18n } = useTranslation();

  const checkhandleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCategoryChange = (args) => {
    const payloadCategoryValue = args?.map((selectedCat) => {
      return selectedCat.value;
    });
    setCategoriesOption(args?.value);
    setPayloadcat(payloadCategoryValue);
  };

  const handleAddonChange = (args) => {
    const payloadAddonValue = args?.map((selectedAddon) => {
      return selectedAddon.value;
    });
    setAddonsOption(args?.value);
    setPayloadaddon(payloadAddonValue);
  };

  const getDeleteProductImage = (ids, message) => {
    const request = {
      ids,
      onSuccess: async (message) => {
        toast.success(message);
        dispatch(singleProduct(id));
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(deleteProductImage(request));
  };

  useEffect(() => {
    setIsCheck((prev) => prev?.saveData);
    let status = saveData?.is_active === 1 ? true : false;
    setIsChecked(status);
  }, [saveData]);

  useEffect(() => {
    dispatch(categoryList(pageone));
    dispatch(addonList(pageone));
  }, []);
  return (
    <div className="">
      {Object.keys(dataFromProp).length ? (
        <div className="col-xl-12 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>{t("Edit Product")}</h6>
            </div>
            <div className="ms-panel-body">
              {dataFromProp.length ? (
                <form
                  className="needs-validation clearfix"
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  {isLoadingData ? (
                    <>
                      {" "}
                      <CircularProgress
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "30%",
                          zIndex: "1",
                          color: "orange",
                          animation: "none",
                        }}
                      />
                    </>
                  ) : null}
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="validationCustom18"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {t("Name")}&nbsp;[
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="secondary tabs example"
                          // textColor="secondary"
                        >
                          <Tab
                            value="en"
                            label="English"
                            {...a11yProps("en")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                          <Tab
                            value="ar"
                            label="Arabic"
                            {...a11yProps("ar")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                          <Tab
                            value="de"
                            label="Germen"
                            {...a11yProps("de")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                        </Tabs>
                        ]
                      </label>
                      <TabPanel value={value} index={"en"}>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Name"
                            name="nameen"
                            onChange={formik.handleChange}
                            value={formik.values.nameen}
                          />
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={"ar"}>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Name"
                            name="namear"
                            onChange={formik.handleChange}
                            value={formik.values.namear}
                          />
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={"de"}>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Name"
                            name="namede"
                            onChange={formik.handleChange}
                            value={formik.values.namede}
                          />
                        </div>
                      </TabPanel>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom18">
                        {t("Sku code")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          placeholder="sku_code"
                          name="sku_code"
                          onChange={formik.handleChange}
                          value={formik.values.sku_code}
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="validationCustom18"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {t("Short Description")}
                        &nbsp;[
                        <Tabs
                          value={shortDesc}
                          onChange={handleShortDesc}
                          aria-label="secondary tabs example"
                        >
                          <Tab
                            value="en"
                            label="English"
                            {...a11yProps("en")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                          <Tab
                            value="ar"
                            label="Arabic"
                            {...a11yProps("ar")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                          <Tab
                            value="de"
                            label="Germen"
                            {...a11yProps("de")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                        </Tabs>
                        ]
                      </label>
                      <TabPanel value={shortDesc} index={"en"}>
                        <div className="input-group">
                          <TextareaAutosize
                            name="short_descriptionen"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Short Description"
                            onChange={formik.handleChange}
                            value={formik.values.short_descriptionen}
                          />
                        </div>
                        {/* Item One */}
                      </TabPanel>
                      <TabPanel value={shortDesc} index={"ar"}>
                        <div className="input-group">
                          <TextareaAutosize
                            name="short_descriptionar"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Short Description"
                            onChange={formik.handleChange}
                            value={formik.values.short_descriptionar}
                          />
                        </div>
                        {/* Item Two */}
                      </TabPanel>
                      <TabPanel value={shortDesc} index={"de"}>
                        <div className="input-group">
                          <TextareaAutosize
                            name="short_descriptionde"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Short Description"
                            onChange={formik.handleChange}
                            value={formik.values.short_descriptionde}
                          />
                        </div>
                        {/* Item Three */}
                      </TabPanel>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="validationCustom18"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {t("Description")}
                        &nbsp;[
                        <Tabs
                          value={desc}
                          onChange={handleDesc}
                          aria-label="secondary tabs example"
                          // textColor="secondary"
                        >
                          <Tab
                            value="en"
                            label="English"
                            {...a11yProps("en")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                          <Tab
                            value="ar"
                            label="Arabic"
                            {...a11yProps("ar")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                          <Tab
                            value="de"
                            label="Germen"
                            {...a11yProps("de")}
                            style={{
                              textTransform: "unset",
                              fontSize: "12px",
                              minWidth: "50px",
                              padding: "0",
                              minHeight: "0",
                            }}
                          />
                        </Tabs>
                        ]
                      </label>
                      <TabPanel value={desc} index={"en"}>
                        <div className="input-group">
                          <TextareaAutosize
                            name="descriptionen"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Description"
                            onChange={formik.handleChange}
                            value={formik.values.descriptionen}
                          />
                        </div>
                        {/* Item One */}
                      </TabPanel>
                      <TabPanel value={desc} index={"ar"}>
                        <div className="input-group">
                          <TextareaAutosize
                            name="descriptionar"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Description"
                            onChange={formik.handleChange}
                            value={formik.values.descriptionar}
                          />
                        </div>
                        {/* Item Two */}
                      </TabPanel>
                      <TabPanel value={desc} index={"de"}>
                        <div className="input-group">
                          <TextareaAutosize
                            name="descriptionde"
                            className="form-control"
                            id="validationCustom18"
                            placeholder="Description"
                            onChange={formik.handleChange}
                            value={formik.values.descriptionde}
                          />
                        </div>
                        {/* Item Three */}
                      </TabPanel>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom18">{t("Amount")}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          placeholder="Amount "
                          name="amount"
                          onChange={formik.handleChange}
                          value={formik.values.amount}
                          // defaultValue="phone"
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom18">
                        {t("Quantity")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          placeholder="Quantity "
                          name="quality"
                          onChange={formik.handleChange}
                          value={formik.values.quality}
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom18">
                        {t("Categories")}
                      </label>
                      <div className="input-group">
                        <Select
                          name="categories"
                          onChange={handleCategoryChange}
                          placeholder="categories"
                          className="form-gender"
                          isMulti={true}
                          options={newdatacat}
                          value={categoriesOption}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom18">{t("Addons")}</label>
                      <div className="input-group">
                        <Select
                          name="addons"
                          onChange={handleAddonChange}
                          value={addonsOption}
                          placeholder="addons"
                          className="form-gender"
                          isMulti={true}
                          options={newaddondata}
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom18">
                        {t("Product Attributes")}
                      </label>
                      <EditCollapsed
                        setProductMultiData={setProductMultiData}
                        saveData={saveData}
                        attributeSetDataOptions={state}
                      />
                      {/* <ProductAttribute /> */}
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom23">
                        {t("Product Image")}
                      </label>
                      <div
                        style={{ width: "100%", display: "flex", gap: "2rem" }}
                      >
                        {checking?.product_images?.map((productImage) => {
                          return (
                            <div
                              style={{
                                position: "relative",
                                width: "max-content",
                              }}
                            >
                              <img
                                src={productImage?.url}
                                alt=""
                                key={productImage?.id}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "contain",
                                }}
                              />
                              <div
                                onClick={() =>
                                  getDeleteProductImage(productImage?.id)
                                }
                                style={{
                                  position: "absolute",
                                  right: "-8px",
                                  top: "-11px",
                                  cursor: "pointer",
                                  border: "1px solid black",
                                  width: "10px",
                                  height: "10px",
                                  padding: "10px",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: "white",
                                }}
                              >
                                <i class="Medium material-icons">close</i>
                              </div>
                            </div>
                          );
                        })}
                        <MyDropzone
                          setImageUpload={setImageUpload}
                          showPreview="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="ms-checkbox-wrap ms-checkbox-primary">
                        <input
                          type="checkbox"
                          defaultValue
                          // defaultChecked
                          id="validationCustom18"
                          name="is_active"
                          checked={isChecked}
                          onChange={(event) => {
                            checkhandleChange(event);
                          }}
                        />{" "}
                        <i className="ms-checkbox-check" />
                      </label>{" "}
                      <span> {t("Is Active")} </span>
                    </div>
                    <div className="col-md-6 mb-3"></div>
                    <div className="col-md-6 mb-3">
                      <div className="ms-panel-header new buttonConatiner">
                        <button
                          className="btn btn-secondary d-block cancelButton"
                          type="submit"
                          onClick={() => history.push("/menu-product")}
                          style={{ minWidth: "100px", padding: "0.4rem" }}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          className="btn btn-primary d-block saveButton"
                          type="submit"
                          style={{ minWidth: "100px", padding: "0.4rem" }}
                        >
                          {t("Update")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            top: "30%",
            zIndex: "1",
            color: "orange",
            animation: "none",
          }}
        />
      )}
    </div>
  );
};

export default Content;
