import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import {
  changeProductStatus,
  deleteProduct,
  productList,
} from "../../../Redux/productSlice";
import { addonList } from "../../../Redux/addonSlice";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { GridToolbarContainer } from "@mui/x-data-grid";
import {
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { reload } from "../../../Redux/userSlice";
import { Replay } from "@mui/icons-material";
import { categoryList } from "../../../Redux/categorySlice";
import { Nav, Tab } from "react-bootstrap";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const VISIBLE_FIELDS = ["name", "amount", "quality", "categories", "is_active"];
// console.log(VISIBLE_FIELDS, "VISIBLE_FIELDS");

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  // eslint-disable-next-line no-unused-expressions
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    t,
  } = props;

  const headCells = [
    {
      id: "Order ID",
      numeric: false,
      disablePadding: true,
      label: `${t("Order ID")}`,
    },
    {
      id: "Customer",
      numeric: true,
      disablePadding: false,
      label: `${t("Customer")}`,
    },
    {
      id: "Product",
      numeric: true,
      disablePadding: false,
      label: `${t("Product")}`,
    },
    {
      id: "Order Date",
      numeric: true,
      disablePadding: false,
      label: `${t("Order Date")}`,
    },
    {
      id: "Amount",
      numeric: true,
      disablePadding: false,
      label: `${t("Amount($)")}`,
    },
    {
      id: "Payment Method",
      numeric: true,
      disablePadding: false,
      label: `${t("Payment Method")}`,
    },
    {
      id: "Delivery Status",
      numeric: true,
      disablePadding: false,
      label: `${t("Delivery Status")}`,
    },
    {
      id: "Action",
      numeric: true,
      disablePadding: false,
      label: `${t("Action")}`,
    },
  ];
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  style={{
                    display: "none",
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const languageArabic = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();

  useEffect(() => {
    setCurrLang(languageArabic);
  }, [languageArabic]);

  const { numSelected, t } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        textAlign: currLang === "ar" ? "end" : "",
        flexDirection: currLang === "ar" ? "row-reverse" : "",
      }}
      // style={{
      // }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
  </GridToolbarContainer>
);

CustomToolbar.propTypes = {
  setFilterButtonEl: PropTypes.func.isRequired,
};
export default function OrderHistory({ t }) {
  const { data } = useDemoData({
    dataSet: "Employee",
    visibleFields: VISIBLE_FIELDS,
    rowLength: 100,
  });
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { productData, isLoading } = useSelector((state) => state.productSlice);
  const { addonData } = useSelector((state) => state.addonSlice);
  const { categoryData } = useSelector((state) => state.categorySlice);
  const { singleProductData } = useSelector((state) => state.productSlice);
  const [productListData, setProductListData] = useState([]);
  const [allProductData, setAllProductData] = useState([]);

  // ******* filter states start ******
  const [parentOptions, setParentOptions] = useState();
  const [payloadParent, setPayloadparent] = useState();
  const [skuParent, setSkuparent] = useState();
  const [addonParent, setaddonparent] = useState();

  const [filterQuery, setIsFilterQuery] = useState({});

  console.log(filterQuery, "filterQuery");
  // ******* filter states end ******

  console.log(productData, "productData");

  const allProduct = productData;
  const languageArabic = localStorage.getItem("lang");

  const pageone = 1;
  const skuRef = useRef(null);
  const amountRef = useRef(null);
  const qualityRef = useRef(null);
  const categoryRef = useRef(null);
  const addonRef = useRef(null);
  const activeRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const checkStatushandleChange = (event, row) => {
    const id = row?.uuid;
    const activeStatusValue = event.target.checked;
    const payload = {
      is_active: activeStatusValue,
    };
    const request = {
      payload,
      id,
      onSuccess: async (message) => {
        toast.success(message);
        dispatch(productList(pageone));
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(changeProductStatus(request));
  };

  useEffect(() => {
    dispatch(productList(pageone));
    dispatch(addonList(pageone));
    dispatch(categoryList(pageone));
  }, []);

  useEffect(() => {
    setProductListData(allProduct?.data);
    setAllProductData(allProduct?.data);
  }, [allProduct]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allProductData?.map((n) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - allProductData?.length)
      : 0;

  const history = useHistory();
  const getViewProduct = (id) => {
    history.push(`/invoice-detail/${id}`);
  };
  const getEditProduct = (id) => {
    history.push(`/editProductList/${id}`);
  };
  const getDeleteProduct = (id) => {
    dispatch(deleteProduct(id));
    dispatch(productList(pageone));
  };
  const handleSearchData = (e, title) => {
    console.log(title, e, "titletitletitle");
    if (e?.target?.name === "name_lang") {
      const { name, value } = e?.target;
      setIsFilterQuery((prev) => ({ ...prev, [name]: value }));
      console.log(filterQuery, "nameee");
    } else {
      setIsFilterQuery((prev) => ({ ...prev, [title]: e?.value }));
      console.log(filterQuery, "titleeee");
    }
    // console.log(JSON.stringify(e), "eeeeeeeeeeeee");
  };
  const clearSearchData = () => {
    // skuRef.current.clearValue();
    amountRef.current.clearValue();
    // qualityRef.current.clearValue();
    // categoryRef.current.clearValue();
    // addonRef.current.clearValue();
    activeRef.current.clearValue();
    setIsFilterQuery({});
    setAllProductData(productListData);
  };

  /* END: Search bar */
  const searchProductData = () => {
    let filteredData = productData?.data?.filter((item) => {
      for (const key in filterQuery) {
        // console.log(key, "check filter 3", key in item);
        if (key in item) {
          // if (key === "categories" || "addons") {
          //   const categoryIdList = item[key].map(
          //     (categoryInstance) => categoryInstance.id
          //   );
          //   // console.log(categoryIdList, item[key], "check filter");

          //   return categoryIdList.includes(filterQuery[key]);
          // } else
          if (item[key] !== filterQuery[key]) {
            return false;
          }
        }
      }

      return true;
    });
    // console.log(filteredData, "filteredData==");
    setAllProductData(filteredData);
    setPage(0);
  };

  const statusOption = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 0,
      label: "Inactive",
    },
  ];

  // const skuOption = productData?.data?.map((product) => ({
  //   value: product?.id,
  //   label: product?.sku_code,
  // }));

  console.log(productData, addonData, ">>>>><<<<<<<<");

  const categoryOption = categoryData?.data?.map((product) => ({
    value: product?.id,
    label: product?.name?.en,
  }));

  console.log(categoryOption, "categoryOption");

  const addonOption = addonData?.data?.map((product) => ({
    value: product?.id,
    label: product?.name?.en,
  }));

  console.log(addonOption, "addonOption");

  let newArray;

  useEffect(() => {
    // to convert many same values into a single
    let parentId = productData?.data?.map((product) => ({
      label: product?.amount,
      value: product?.amount,
    }));
    // console.log(parentId, "parentId");
    newArray = [...new Set(parentId?.map((obj) => JSON.stringify(obj)))].map(
      (str) => JSON.parse(str)
    );
    setPayloadparent(newArray);

    let quality = productData?.data?.map((product) => ({
      label: product?.quality,
      value: product?.quality,
    }));
    // console.log(quality, "quality");
    newArray = [...new Set(quality?.map((obj) => JSON.stringify(obj)))].map(
      (str) => JSON.parse(str)
    );
    setParentOptions(newArray);

    let skuOption = productData?.data?.map((product) => ({
      value: product?.sku_code,
      label: product?.sku_code,
    }));
    newArray = [...new Set(skuOption?.map((obj) => JSON.stringify(obj)))].map(
      (str) => JSON.parse(str)
    );
    setSkuparent(newArray);

    // let addonOption = addonData?.data?.map((product) => ({
    //   value: product?.name?.en,
    //   label: product?.name?.en,
    // }));
    // console.log(addonOption, "addonOption>>>>");
    // newArray = [...new Set(addonOption?.map((obj) => JSON.stringify(obj)))].map(
    //   (str) => JSON.parse(str)
    // );
    // setaddonparent(newArray);
    // console.log(newArray, "newArray");
  }, [productData?.data, addonData?.data]);

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {selected.length > 0 ? (
          <EnhancedTableToolbar numSelected={selected.length} t={t} />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0rem 1rem",
                paddingTop: "1rem",
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {t("Orders History")}{" "}
              </Typography>
              {/* <Tooltip title="Filter list">
                <IconButton>
                  <Link
                    to="/createProduct"
                    className="btn btn-primary new-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <i className="material-icons">person_add</i>{" "}
                    {t("Create Order")}
                  </Link>
                </IconButton>
              </Tooltip> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0rem 1rem",
                paddingTop: "1rem",
              }}
              className="media-query"
            >
              <div className="w-100 mr-2 wrapper">
                <TextField
                  name="name_lang"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Search Name"
                  className="text"
                  value={filterQuery?.name_lang ?? ""}
                  onChange={(e) => handleSearchData(e)}
                  borderColor="blue"
                />
              </div>
              {/* <div className="w-100"> */}
              {/* <Select
                  ref={skuRef}
                  name="sku_code"
                  onChange={(e) => handleSearchData(e, "sku_code")}
                  // value={skuOptions}
                  options={skuParent}
                  placeholder="Select Date"
                  className="form-gender sku-filter"
                /> */}
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    // defaultValue={dayjs("2022-04-17")}
                    // placeholder="Select Date"
                    label={"Select Date"}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}
              {/* </div> */}
              <div
                className="w-100 ml-2 wrapper-left"
                // style={{ width: "50%" }}
              >
                <Select
                  ref={amountRef}
                  name="amount"
                  onChange={(e) => handleSearchData(e, "amount")}
                  // value={amountOptions}
                  options={payloadParent}
                  placeholder="Select Amount"
                  className="form-gender sku-filter"
                />
              </div>
              {/* <div
                className="w-100 ml-2 wrapper-left"
                // style={{ width: "50%" }}
              >
                <Select
                  ref={qualityRef}
                  name="quality"
                  onChange={(e) => handleSearchData(e, "quality")}
                  // value={qualityOptions}
                  options={parentOptions}
                  placeholder="Select Quality"
                  className="form-gender sku-filter"
                />
              </div>
              <div
                className="w-100 ml-2 wrapper-left"
                // style={{ width: "50%" }}
              >
                <Select
                  ref={categoryRef}
                  name="categories"
                  onChange={(e) => handleSearchData(e, "categories")}
                  // value={categoryOptions}
                  options={categoryOption}
                  placeholder="Select Category"
                  className="form-gender sku-filter"
                />
              </div>
              <div
                className="w-100 ml-2 wrapper-left"
                // style={{ width: "50%" }}
              >
                <Select
                  ref={addonRef}
                  name="addons"
                  onChange={(e) => handleSearchData(e, "addons")}
                  // value={addonOptions}
                  options={addonOption}
                  placeholder="Select Addon"
                  className="form-gender sku-filter"
                />
              </div> */}
              <div
                className="w-100 ml-2 wrapper-left"
                // style={{ width: "50%" }}
              >
                <Select
                  ref={activeRef}
                  name="is_active"
                  onChange={(e) => handleSearchData(e, "is_active")}
                  // value={statusOptions}
                  options={statusOption}
                  placeholder="Select Status"
                  className="form-gender sku-filter"
                />
              </div>
              <div className="w-100 reset-container">
                <div
                  style={{
                    border: "1px solid lightgrey",
                    fontSize: "23px",
                    width: "23%",
                    textAlign: "center",
                    borderRadius: "4px",
                    // color: "#808080",
                    backgroundColor: "black",
                    color: "white",
                    marginLeft: "7px",
                    cursor: "pointer",
                  }}
                  onClick={searchProductData}
                  className="searchbar"
                >
                  <SearchIcon />
                </div>
                <div
                  style={{
                    border: "1px solid lightgrey",
                    fontSize: "23px",
                    width: "23%",
                    textAlign: "center",
                    borderRadius: "4px",
                    // color: "#808080",
                    backgroundColor: "black",
                    color: "white",
                    marginLeft: "7px",
                    cursor: "pointer",
                  }}
                  onClick={clearSearchData}
                  className="searchbar"
                >
                  {/* <CachedIcon />{" "} */}
                  <Replay />{" "}
                </div>
              </div>
            </div>
          </>
        )}

        <div
          //   className="col-md-12"
          style={{ marginTop: "1rem" }}
        >
          <div
          //   className="ms-panel ms-panel-fh"
          >
            {/* <div className="ms-panel-header">
              <h6>Tabs Bordered</h6>
            </div> */}
            <div
            //   className="ms-panel-body"
            >
              {/* <p className="ms-directions">
                Use <code>.tabs-bordered</code> in <code>.nav-tabs</code>
              </p> */}
              {/* <Tab.Container defaultActiveKey="tab1"> */}
              {/* <div>
                <Nav
                  variant="tabs"
                  className="nav nav-tabs tabs-bordered d-flex nav-justified"
                  style={{ width: "40%" }}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="tab1">All Orders</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="tab3">Pickups</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="tab5">Cancelled</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div> */}
              {/* <Tab.Content> */}
              {/* <Tab.Pane eventKey="tab1"> */}
              <TableContainer
                style={{
                  direction: languageArabic === "ar" ? "rtl" : "unset",
                  width: "97%",
                  margin: "auto",
                }}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  style={{ position: "relative" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={allProductData?.length}
                    t={t}
                  />
                  <TableBody>
                    {!isLoading ? (
                      <>
                        {!allProductData?.length ? (
                          <>
                            <b
                              style={{
                                // display: "flex",
                                // justifyContent: "center",
                                color: "red",
                                whiteSpace: "nowrap",
                              }}
                            >
                              No Data Available
                            </b>
                          </>
                        ) : (
                          <>
                            {stableSort(
                              allProductData,
                              getComparator(order, orderBy)
                            )
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              ?.map((row, index) => {
                                const isItemSelected = isSelected(row?.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                // console.log(row, "====");

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row?.id}
                                    selected={isItemSelected}
                                  >
                                    {/* <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        onClick={(event) =>
                                          handleClick(event, row?.id)
                                        }
                                        checked={isItemSelected}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell> */}
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                    >
                                      {/* {row?.name[languageArabic]} */}
                                      {233499}
                                    </TableCell>
                                    <TableCell align="right">
                                      {/* {row?.sku_code} */}
                                      {"John coe"}
                                    </TableCell>
                                    <TableCell align="right">
                                      {/* {row?.amount} */}
                                      {"Pizza"}
                                    </TableCell>
                                    <TableCell align="right">
                                      {/* {row?.quality} */}
                                      {"17-04-2023"}
                                    </TableCell>
                                    <TableCell align="right">
                                      {/* {row?.categories?.map(
                                              (category, index) => {
                                                return (
                                                  <span>
                                                    {" "}
                                                    {(index ? "," : " ") +
                                                      category?.name[
                                                        languageArabic
                                                      ]}
                                                  </span>
                                                );
                                              }
                                            )} */}
                                      {"300"}
                                    </TableCell>
                                    <TableCell align="right">
                                      {/* {row?.addons?.map(
                                              (addon, index) => {
                                                return (
                                                  <span>
                                                    {(index ? "," : " ") +
                                                      addon?.name[
                                                        languageArabic
                                                      ]}{" "}
                                                  </span>
                                                );
                                              }
                                            )} */}
                                      {"Phone pay"}
                                    </TableCell>
                                    <TableCell align="right">
                                      {"Delivered"}
                                      {/* <label className="switch">
                                        <input
                                          type="checkbox"
                                          name="is_active"
                                          checked={
                                            row?.is_active === 1 ? true : false
                                          }
                                          onChange={(e) =>
                                            checkStatushandleChange(e, row)
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label> */}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <ul className="list-inline hstack gap-2 mb-0">
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title=""
                                          data-bs-original-title="View"
                                        >
                                          <div
                                            onClick={() =>
                                              getViewProduct(row?.uuid)
                                            }
                                            // href="/viewCategory"
                                            className="text-success d-inline-block"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i className="fas fa-eye"></i>
                                          </div>
                                        </li>{" "}
                                        {/* <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title=""
                                          data-bs-original-title="Edit"
                                        >
                                          <div
                                            onClick={() =>
                                              getEditProduct(row?.uuid)
                                            }
                                            // href="/editCategory"
                                            className="text-primary d-inline-block edit-item-btn"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </div>
                                        </li>{" "} */}
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title=""
                                          data-bs-original-title="Delete"
                                        >
                                          <div
                                            // href="/"
                                            onClick={() =>
                                              getDeleteProduct(row?.uuid)
                                            }
                                            className="text-danger d-inline-block remove-item-btn"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i className="material-icons  delete">
                                              delete
                                            </i>
                                          </div>
                                        </li>{" "}
                                      </ul>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <CircularProgress
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "30%",
                            color: "orange",
                            animation: "none",
                            // animationDuration: "1s",
                          }}
                        />
                      </>
                    )}

                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam urna nunc, congue nec laoreet sed, maximus non
                      massa. Fusce vestibulum vel risus vitae tincidunt.{" "}
                    </p>
                    <p>
                      {" "}
                      Cras egestas nisi vel tempor dignissim. Ut condimentum
                      iaculis ex nec ornare. Vivamus sit amet elementum ante.
                      Fusce eget erat volutpat{" "}
                    </p> */}
              {/* </Tab.Pane> */}
              {/* <Tab.Pane
                    eventKey="tab3"
                    style={{
                      borderTop: "1px solid lightgray",
                      padding: "1rem",
                    }}
                  >
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam urna nunc, congue nec laoreet sed, maximus non
                      massa. Fusce vestibulum vel risus vitae tincidunt.{" "}
                    </p>
                  </Tab.Pane> */}
              {/* <Tab.Pane
                    eventKey="tab5"
                    style={{
                      borderTop: "1px solid lightgray",
                      padding: "1rem",
                    }}
                  >
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam urna nunc, congue nec laoreet sed, maximus non
                      massa. Fusce vestibulum vel risus vitae tincidunt.{" "}
                    </p>
                  </Tab.Pane> */}
              {/* </Tab.Content> */}
              {/* </Tab.Container> */}
            </div>
          </div>
        </div>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allProductData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
