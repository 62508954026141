import React, { Component } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Content from "../sections/Defaultlogin/Content";

const Defaultlogin = () => {
  return (
    <div className="">
      {/* <Sidenavigation /> */}
      <main className="body-content">
        {/* <Topnavigation /> */}
        <Content />
      </main>
    </div>
  );
};

export default Defaultlogin;
