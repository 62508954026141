import { useFormik } from "formik";
import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createProduct, productList } from "../../../Redux/productSlice";
import { categoryList } from "../../../Redux/categorySlice";
import { addonList } from "../../../Redux/addonSlice";
// import { attributeList } from "../../Redux/attributeSlice";
// import { attributeSetList } from "../../Redux/attributeSetSlice";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  CircularProgress,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { MyDropzone } from "../Dropzone/Dropzone";
import { attributeList } from "../../../Redux/attributeSlice";
import { attributeSetList } from "../../../Redux/attributeSetSlice";
import { Button } from "react-bootstrap";
import Collapsed from "../../pages/Collapsed";
import ProductAttribute from "../../pages/ProductAttribute";
import customAxios from "../../../customAxios";
import authHeader from "../../../authHeader";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Content = (props) => {
  const { state } = props.props.props.location;

  const { productData } = useSelector((state) => state.productSlice);
  const { categoryData } = useSelector((state) => state.categorySlice);
  const { addonData } = useSelector((state) => state.addonSlice);
  const { singleProductData } = useSelector((state) => state.productSlice);
  const [imageUpload, setImageUpload] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [categoriesOption, setCategoriesOption] = useState(null);
  const [payloadcat, setPayloadcat] = useState(null);
  const [addonsOption, setAddonsOption] = useState(null);
  const [payloadaddon, setPayloadaddon] = useState(null);
  //  const [Option, setAttributeOption] = useState(null);
  const [isLoadingData, setLoadingData] = useState(false);
  const [allProductData, setAllProductData] = useState("");
  const [productMultiData, setProductMultiData] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [checking, setChecking] = useState([]);

  const [value, setValue] = React.useState("en");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [shortDesc, setShortDesc] = React.useState("en");

  const handleShortDesc = (event, newShortDesc) => {
    setShortDesc(newShortDesc);
  };
  const [desc, setDesc] = React.useState("en");
  const handleDesc = (event, newDesc) => {
    setDesc(newDesc);
  };
  const { id } = useParams();
  const pageone = 1;

  const newdatacat = categoryData?.data?.map((category) => ({
    value: category?.id,
    label: category?.name?.en,
  }));

  const newaddondata = addonData?.data?.map((addon) => ({
    value: addon?.id,
    label: addon?.name?.en,
  }));

  useEffect(() => {
    if (saveData) {
      setChecking(saveData);
    }
  }, [saveData]);

  useEffect(() => {
    if (id) {
      let singleData = "/vendor/product/show/" + id;
      customAxios
        .get(singleData, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setSaveData(data?.data);
            // const defaultCategories = data?.data?.categories?.map((categ) => ({
            //   value: categ?.id,
            //   label: categ?.name?.en,
            // }));
            // setCategoriesOption(defaultCategories);
            // setCategoryyyy(
            //   defaultCategories?.map((category) => category?.value)
            // );

            // const defaultAddons = data?.data?.addons?.map((adon) => ({
            //   value: adon?.id,
            //   label: adon?.name?.en,
            // }));
            // setAddonsOption(defaultAddons);
            // setAddonsss(defaultAddons?.map((addon) => addon?.value));
          }
        })
        .catch(function (error) {});
    }
  }, [id]);

  const langs = localStorage.getItem("lang");

  const dispatch = useDispatch();
  let history = useHistory();

  const data = productData?.data;

  useEffect(() => {
    dispatch(categoryList(pageone));
    dispatch(addonList(pageone));
    dispatch(attributeList(pageone));
    dispatch(productList(pageone));
  }, []);

  useEffect(() => {
    if (productData) {
      setAllProductData(productData?.data);
    }
  }, [productData]);

  const payloadToFormData = async (payloadData) => {
    let formData = new FormData();
    try {
      for (let key in payloadData) {
        if (Array.isArray(payloadData[key])) {
          // console.log(key, ":", payloadData[key], "keyyyssssss");
          payloadData[key].forEach((item, index) => {
            // console.log(key, ":", item, "forEach item");
            if (typeof item === "object") {
              // console.log(item, "item inside object");
              for (let keyItem in item) {
                // console.log(keyItem, ":", item[keyItem], "object for");
                if (Array.isArray(item[keyItem])) {
                  // console.log(item[keyItem], "keyItem checkkk");
                  item[keyItem] &&
                    item[keyItem].forEach((itemItem, indexItem) => {
                      if (Object.keys(itemItem).length) {
                        for (let keys in itemItem) {
                          // console.log(keys, "keys");
                          formData.append(
                            `${key}[${index}][${keyItem}][${indexItem}][${keys}]`,
                            itemItem[keys]
                          );
                        }
                      }
                    });
                } else {
                  // console.log(
                  //   keyItem,
                  //   ":",
                  //   item[keyItem],
                  //   `${key}[${index}][${keyItem}]`,
                  //   item[keyItem],
                  //   "keyItem-else"
                  // );
                  formData.append(
                    `${key}[${index}][${keyItem}]`,
                    item[keyItem]
                  );
                }
              }
            } else {
              // console.log(
              //   `${key}[${index}]`,
              //   item[`${index}`],
              //   "forEach item ELSEE"
              // );
              formData.append(`${key}[${index}]`, item);
            }
            // for (let itemData in item) {

            // }
          });
        } else if (typeof payloadData[key] === "object") {
          formData.append(`${key}[${langs}]`, payloadData[key][langs]);
        } else {
          formData.append(key, payloadData[key]);
        }
      }

      return formData;
    } catch (err) {
      // console.log(err, "payload");
    }
  };

  const formik = useFormik({
    initialValues: {
      sku_code: data?.sku_code,
      nameen: data?.name,
      namear: data?.name,
      namede: data?.name,
      short_descriptionen: data?.short_description,
      short_descriptionar: "",
      short_descriptionde: "",
      descriptionen: data?.description,
      descriptionar: data?.description,
      descriptionde: data?.description,
      amount: data?.amount,
      quality: data?.quality,
      categories: data?.categories,
      addons: data?.addons,
      product_attributes: data?.product_attributes,
      product_images: data?.product_images,
      is_active: "",
    },
    // validate,
    onSubmit: async (values) => {
      let namevalue = {
        en: values.nameen,
        ar: values.namear,
        de: values.namede,
      };
      let shortdescvalue = {
        en: values.short_descriptionen,
        ar: values.short_descriptionar,
        de: values.short_descriptionde,
      };
      let descvalue = {
        en: values.descriptionen,
        ar: values.descriptionar,
        de: values.descriptionde,
      };

      const payload = {
        sku_code: values?.sku_code,
        name: namevalue,
        short_description: shortdescvalue,
        description: descvalue,
        amount: values?.amount,
        quality: values?.quality,
        categories: payloadcat,
        addons: payloadaddon,
        product_images: imageUpload,
        product_attributes: productMultiData,
        is_active: isChecked,
      };

      const formDataPayload = await payloadToFormData(payload);
      const request = {
        payload: formDataPayload,
        onSuccess: async (message) => {
          setLoadingData(false);
          toast.success(message);
          history.push("/menu-product");
        },
        onFail: (message) => {
          toast.error(message);
        },
      };
      setLoadingData(true);
      dispatch(createProduct(request));
    },
  });

  // console.log(productMultiData, "productMultiData");

  const { t, i18n } = useTranslation();

  const checkhandleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCategoryChange = (args) => {
    const payloadCategory = args?.map((selectedCat) => {
      return selectedCat.value;
    });
    setCategoriesOption(args);
    setPayloadcat(payloadCategory);
  };

  const handleAddonChange = (args) => {
    const payloadAddon = args?.map((selectedAddon) => {
      return selectedAddon.value;
    });
    setAddonsOption(args);
    setPayloadaddon(payloadAddon);
  };

  return (
    <div className="">
      <div className="col-xl-12 col-md-12">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-header">
            <h6>{t("Create Product")}</h6>
          </div>
          <div className="ms-panel-body">
            <form
              className="needs-validation clearfix"
              noValidate
              id="createProductForm"
              onSubmit={formik.handleSubmit}
            >
              {isLoadingData ? (
                <>
                  {" "}
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "30%",
                      zIndex: "1",
                      color: "orange",
                      animation: "none",
                    }}
                  />
                </>
              ) : null}
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="validationCustom18"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Name")} &nbsp;[
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="secondary tabs example"
                    >
                      <Tab
                        value="en"
                        label="English"
                        {...a11yProps("en")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="ar"
                        label="Arabic"
                        {...a11yProps("ar")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="de"
                        label="Germen"
                        {...a11yProps("de")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                    </Tabs>
                    ]
                  </label>
                  <TabPanel value={value} index={"en"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Name"
                        name="nameen"
                        onChange={formik.handleChange}
                        value={formik.values.nameen}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={"ar"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Name"
                        name="namear"
                        onChange={formik.handleChange}
                        value={formik.values.namear}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={"de"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Name"
                        name="namede"
                        onChange={formik.handleChange}
                        value={formik.values.namede}
                      />
                    </div>
                  </TabPanel>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Sku code")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="sku_code"
                      name="sku_code"
                      onChange={formik.handleChange}
                      value={formik.values.sku_code}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="validationCustom18"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Short Description")}
                    &nbsp;[
                    <Tabs
                      value={shortDesc}
                      onChange={handleShortDesc}
                      aria-label="secondary tabs example"
                    >
                      <Tab
                        value="en"
                        label="English"
                        {...a11yProps("en")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="ar"
                        label="Arabic"
                        {...a11yProps("ar")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="de"
                        label="Germen"
                        {...a11yProps("de")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                    </Tabs>
                    ]
                  </label>
                  <TabPanel value={shortDesc} index={"en"}>
                    <div className="input-group">
                      <TextareaAutosize
                        name="short_descriptionen"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Short Description"
                        value={formik.values.short_descriptionen}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={shortDesc} index={"ar"}>
                    <div className="input-group">
                      <TextareaAutosize
                        name="short_descriptionar"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Short Description"
                        onChange={formik.handleChange}
                        value={formik.values.short_descriptionar}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={shortDesc} index={"de"}>
                    <div className="input-group">
                      <TextareaAutosize
                        name="short_descriptionde"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Short Description"
                        onChange={formik.handleChange}
                        value={formik.values.short_descriptionde}
                      />
                    </div>
                  </TabPanel>
                </div>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="validationCustom18"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Description")}
                    &nbsp;[
                    <Tabs
                      value={desc}
                      onChange={handleDesc}
                      aria-label="secondary tabs example"
                    >
                      <Tab
                        value="en"
                        label="English"
                        {...a11yProps("en")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="ar"
                        label="Arabic"
                        {...a11yProps("ar")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="de"
                        label="Germen"
                        {...a11yProps("de")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                    </Tabs>
                    ]
                  </label>
                  <TabPanel value={desc} index={"en"}>
                    <div className="input-group">
                      <TextareaAutosize
                        name="descriptionen"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Description"
                        onChange={formik.handleChange}
                        value={formik.values.descriptionen}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={desc} index={"ar"}>
                    <div className="input-group">
                      <TextareaAutosize
                        name="descriptionar"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Description"
                        onChange={formik.handleChange}
                        value={formik.values.descriptionar}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={desc} index={"de"}>
                    <div className="input-group">
                      <TextareaAutosize
                        name="descriptionde"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Description"
                        onChange={formik.handleChange}
                        value={formik.values.descriptionde}
                      />
                    </div>
                  </TabPanel>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Amount")}</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Amount "
                      name="amount"
                      onChange={formik.handleChange}
                      value={formik.values.amount}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Quantity")}</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Quantity "
                      name="quality"
                      onChange={formik.handleChange}
                      value={formik.values.quality}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Categories")}</label>
                  <div className="input-group">
                    <Select
                      name="categories"
                      onChange={handleCategoryChange}
                      value={categoriesOption}
                      placeholder="categories"
                      className="form-gender"
                      isMulti={true}
                      options={newdatacat}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Addons")}</label>
                  <div className="input-group">
                    <Select
                      name="addons"
                      onChange={handleAddonChange}
                      value={addonsOption}
                      placeholder="addons"
                      className="form-gender"
                      isMulti={true}
                      options={newaddondata}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="validationCustom18">
                    {t("Product Attributes")}
                  </label>
                </div>
                <div className="col-md-12 mb-3">
                  <Collapsed
                    setProductMultiData={setProductMultiData}
                    // saveData={saveData}
                    attributeSetDataOptions={state}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label htmlFor="validationCustom23">
                    {t("Product Image")}
                  </label>
                  <div style={{ width: "100%", display: "flex", gap: "2rem" }}>
                    {productData?.data?.product_images?.map((productImage) => {
                      return (
                        <div
                          style={{
                            position: "relative",
                            width: "max-content",
                          }}
                        >
                          <img
                            src={productImage?.url}
                            alt=""
                            key={productImage?.id}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              right: "-8px",
                              top: "-11px",
                              cursor: "pointer",
                              border: "1px solid black",
                              width: "10px",
                              height: "10px",
                              padding: "10px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "white",
                            }}
                          >
                            <i class="Medium material-icons">close</i>
                          </div>
                        </div>
                      );
                    })}
                    <MyDropzone setImageUpload={setImageUpload} />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="ms-checkbox-wrap ms-checkbox-primary">
                    <input
                      type="checkbox"
                      defaultValue
                      id="validationCustom18"
                      name="is_active"
                      checked={isChecked}
                      onChange={(event) => {
                        checkhandleChange(event);
                      }}
                    />{" "}
                    <i className="ms-checkbox-check" />
                  </label>{" "}
                  <span> {t("Is Active")} </span>
                </div>
                <div className="col-md-6 mb-3"></div>
                <div className="col-md-6 mb-3">
                  <div className="ms-panel-header new buttonConatiner">
                    <button
                      className="btn btn-secondary d-block cancelButton"
                      onClick={() => history.push("/menu-product")}
                      style={{ minWidth: "100px", padding: "0.4rem" }}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary d-block saveButton"
                      type="submit"
                      // onClick={formik.handleSubmit}
                      style={{ minWidth: "100px", padding: "0.4rem" }}
                      form="createProductForm"
                    >
                      {t("Save")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
