import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

class Naturemap extends Component {
  static defaultProps = {
    center: {
      lat: 31.53912,
      lng: -89.29163,
    },
    zoom: 12,
    styles: [
      {
        featureType: "landscape",
        stylers: [
          {
            hue: "#FFA800",
          },
          {
            saturation: 0,
          },
          {
            lightness: 0,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "road.highway",
        stylers: [
          {
            hue: "#53FF00",
          },
          {
            saturation: -73,
          },
          {
            lightness: 40,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "road.arterial",
        stylers: [
          {
            hue: "#FBFF00",
          },
          {
            saturation: 0,
          },
          {
            lightness: 0,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "road.local",
        stylers: [
          {
            hue: "#00FFFD",
          },
          {
            saturation: 0,
          },
          {
            lightness: 30,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "water",
        stylers: [
          {
            hue: "#00BFFF",
          },
          {
            saturation: 6,
          },
          {
            lightness: 8,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            hue: "#679714",
          },
          {
            saturation: 33.4,
          },
          {
            lightness: -25.4,
          },
          {
            gamma: 1,
          },
        ],
      },
    ],
  };
  render() {
    return (
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCXdkd_KFhEZmymLNee3WdkYFqugVACTTg" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          defaultStyles={this.props.styles}
        >
          <Marker lat={31.53912} lng={-89.29163} name="My Marker" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Naturemap;
