import React from "react";

import { createSlice } from "@reduxjs/toolkit";
import { ApiService } from "../Api/ApiService";
import { reload } from "./userSlice";
const initialState = {
  isLoading: false,
  token: "",
  errorMessage: "",
  successMessage: "",
  singleProductData: {},
  productData: [],
  //   parent_category_id: "",
  // userData: "",
};
export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    createProductRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    createProductSuccessful(state, action) {
      state.isLoading = false;
      // state.userData = action.payload.userData;
      state.productData = action.payload;
    },
    createProductFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    productListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
      state.productData = [];
    },
    productListSuccessful(state, action) {
      state.isLoading = false;
      state.productData = action.payload;
    },
    productListFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    updateProductRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    updateProductSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    updateProductFailed(state, action) {
      state.errorMessage = action.payload;
    },
    singleProductRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    singleProductSuccessful(state, action) {
      state.isLoading = false;
      state.singleProductData = action.payload;
    },
    singleProductFailed(state, action) {
      state.errorMessage = action.payload;
    },
    deleteProductRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    deleteProductSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    deleteProductFailed(state, action) {
      state.errorMessage = action.payload;
    },
    deleteProductImageRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    deleteProductImageSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    deleteProductImageFailed(state, action) {
      state.errorMessage = action.payload;
    },
    changeProductStatusRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
      state.successMessage = [];
    },
    changeProductStatusSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    changeProductStatusFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const {
  createProductRequested,
  createProductSuccessful,
  createProductFailed,
  productListRequested,
  productListSuccessful,
  productListFailed,
  updateProductRequested,
  updateProductSuccessful,
  updateProductFailed,
  singleProductRequested,
  singleProductSuccessful,
  singleProductFailed,
  deleteProductRequested,
  deleteProductSuccessful,
  deleteProductFailed,
  deleteProductImageRequested,
  deleteProductImageSuccessful,
  deleteProductImageFailed,
  changeProductStatusRequested,
  changeProductStatusSuccessful,
  changeProductStatusFailed,
} = productSlice.actions;

export default productSlice.reducer;

export const createProduct = (request) => async (dispatch, getState) => {
  //   const { token } = getState().createCategory;
  const categoryToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = categoryToken?.token;

  const { payload, onSuccess, onFail } = request;
  //   const {businessId} = getState().loginSlice;
  dispatch(createProductRequested());
  try {
    const res = await ApiService.createProduct(payload, authtoken);

    if (res?.status === 201) {
      dispatch(createProductSuccessful());

      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        createProductFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        createProductFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        createProductFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const productList = (currPage) => async (dispatch, getState) => {
  // const { currPage } = request;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  const { token } = getState().loginSlice;
  dispatch(productListRequested());
  try {
    const res = await ApiService.productList(authtoken, currPage);

    if (res?.status === 200) {
      dispatch(productListSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        productListFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        productListFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        productListFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const updateProduct = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail, id } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(updateProductRequested());
  try {
    const res = await ApiService.updateProduct(payload, authtoken, id);
    if (res?.status === 202) {
      dispatch(updateProductSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        updateProductFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        updateProductFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        updateProductFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const singleProduct = (uuid) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  dispatch(singleProductRequested());
  try {
    const res = await ApiService.singleProduct(authtoken, uuid);

    if (res?.status === 200) {
      dispatch(singleProductSuccessful(res?.data?.data));
    } else {
      dispatch(
        singleProductFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      dispatch(
        singleProductFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      dispatch(
        singleProductFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};
export const deleteProduct = (uuid) => async (dispatch, getState) => {
  // const { payload, onSuccess, onFail, uuid } = request;
  // const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(deleteProductRequested());
  try {
    const res = await ApiService.deleteProduct(authtoken, uuid);
    if (res?.status === 200) {
      dispatch(deleteProductSuccessful());
      // onSuccess(res?.data?.message || "Something went wrong");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        deleteProductFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        deleteProductFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        deleteProductFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const deleteProductImage = (request) => async (dispatch, getState) => {
  const { ids, onSuccess, onFail } = request;
  // const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(deleteProductImageRequested());
  try {
    const res = await ApiService.deleteProductImage(authtoken, ids);
    if (res?.status === 202) {
      // dispatch(deleteProductImageSuccessful(""));
      // dispatch(reload());
      // window.reload();

      // setInterval(dispatch(reload()), 2000);
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        deleteProductImageFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        deleteProductImageFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        deleteProductImageFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const changeProductStatus = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail, id } = request;
  // const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(changeProductStatusRequested());
  try {
    const res = await ApiService.changeProductStatus(authtoken, id, payload);
    if (res?.status === 202) {
      dispatch(changeProductStatusSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        changeProductStatusFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        changeProductStatusFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        changeProductStatusFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
