import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// Css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendors/iconic-fonts/font-awesome/css/all.min.css";
import "./assets/vendors/iconic-fonts/flat-icons/flaticon.css";
import "./assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css";
import "./assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css";
import "./assets/css/animate.min.css";
import "./assets/css/style.css";
import "./assets/css/customestyle.css";
import "./i18n.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const storeVal = () => {
  return store;
};

ReactDOM.render(
  // <BrowserRouter basename={"/templatemonster/react/foodtech"}>
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </BrowserRouter>,
  document.getElementById("order365")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
