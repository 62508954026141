import { useFormik } from "formik";
import React, { Component, useRef, useState } from "react";
import Select from "react-select";

const options = [
  { value: "01", label: "Veg" },
  { value: "02", label: "Non Veg" },
];

const Curencyoptions = [
  { value: "01", label: "USD" },
  { value: "02", label: "Bitcoins" },
  { vlaue: "03", label: "EURO" },
];

const Editproductcontent = () => {
  const fileref = useRef();
  const [filename, setFileName] = useState();

  const validate = (values) => {
    const errors = {};
    if (!values.productName) {
      errors.productName = "Required";
    } else if (values.productName.length < 2) {
      errors.productName = "Product  Name ";
    }
    if (!values.quantity) {
      errors.quantity = "Required";
    }
    if (!values.Price) {
      errors.Price = "Required";
    }
    if (!values.message) {
      errors.message = "Required";
    }
    if (!values.selectCatagory) {
      errors.selectCatagory =
        "Please select the Job title yor are intrested in!";
    }
    if (!values.selectCurrency) {
      errors.selectCurrency = "please check";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      productName: "",
      selectCatagory: "",
      // currency: "",
      selectCurrency: "",
      Price: "",
      quantity: "",
      message: "",
    },
    validate,

    onSubmit: (values) => {
      console.log(values, "values");
      console.log(filename);
      formik.handleReset();
    },
  });

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        {/* <div className="col-md-12">
          <Breadcrumb />
          <div className="alert alert-success" role="alert">
            <strong>Well done!</strong> You successfully read this important
            alert message.
          </div>
        </div> */}
        <div className="col-xl-12 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Edit Product Form</h6>
            </div>
            <div className="ms-panel-body">
              <form className="needs-validation" onSubmit={formik.handleSubmit}>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationCustom18">Product Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Product Name"
                        name="productName"
                        onChange={formik.handleChange}
                        value={formik.values.productName}
                      />
                      {formik.touched.productName &&
                      formik.errors.productName ? (
                        <div className="input-error-message">
                          Product Name *.
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom22">Select Catagory</label>
                    <div className="input-group">
                      {/* <select
                        className="form-control"
                        id="validationCustom22"
                        name="selectCatagory"
                        // onChange={formik.handleChange}
                        // value={formik.values.selectCatagory}
                      >
                        <option value>veg</option>
                        <option value>Non veg </option>
                      </select> */}
                      <Select
                        options={options}
                        className="select-container"
                        name="selectCatagory"
                        placeholder="Select Catagory"
                        onChange={(e) =>
                          formik.setFieldValue("selectCatagory", e?.label)
                        }
                        value={formik.values.selectCatagory?.label}
                      />
                      {formik.touched.selectCatagory &&
                      formik.errors.selectCatagory ? (
                        <div className="input-error-message">
                          Select Catagory *.
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom23">Currency</label>
                    <div className="input-group">
                      <Select
                        options={Curencyoptions}
                        className="select-container"
                        name="selectCurrency"
                        placeholder="select Currency"
                        onChange={(e) =>
                          formik.setFieldValue("selectCurrency", e?.label)
                        }
                        value={formik.values.selectCurrency?.label}
                      />
                      {formik.touched.selectCurrency &&
                      formik.errors.selectCurrency ? (
                        <div className="input-error-message">
                          Select Currency *.
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom24">Quantity</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom24"
                        placeholder="01"
                        name="quantity"
                        onChange={formik.handleChange}
                        value={formik.values.quantity}
                      />
                      {formik.touched.quantity && formik.errors.quantity ? (
                        <div className="input-error-message">Quantity *.</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom25">Price</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom25"
                        placeholder="$10"
                        name="Price"
                        onChange={formik.handleChange}
                        value={formik.values.Price}
                      />
                      {formik.touched.Price && formik.errors.Price ? (
                        <div className="input-error-message">Price *.</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationCustom12">Description</label>
                    <div className="input-group">
                      <textarea
                        rows={5}
                        name="message"
                        id="validationCustom12"
                        className="form-control"
                        placeholder="Message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                      />
                      {formik.touched.message && formik.errors.message ? (
                        <div className="input-error-message">Message *.</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationCustom12">Product Image</label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        // id="validatedCustomFile"
                        placeholder="upload Image ...."
                        onChange={(e) => {
                          setFileName(e.target.files[0]);
                          console.log(e.target.files[0]);
                        }}
                        value={formik.values.productImage}
                        name="productImage"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="validatedCustomFile"
                      >
                        {filename?.name || "Select image"}
                      </label>
                    </div>
                  </div>
                </div>
                {/* <button
                  className="btn btn-primary mt-4 d-block w-100"
                  type="submit"
                >
                  Create Account
                </button> */}
                <div className="col-md-12 mb-3 p-0">
                  <div className="new button-container">
                    <div className="btn btn-secondary d-block">Cancel</div>
                    <button
                      className="btn btn-primary d-block submitBotton"
                      type="submit"
                    >
                      Save and Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editproductcontent;
