import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  render() {
    return (
      <nav aria-label="breadcrumb" style={{ paddingLeft: "1rem" }}>
        <ol className="breadcrumb pl-0">
          <li className="breadcrumb-item">
            <Link to="#">
              <i className="material-icons">home</i>
              Home
            </Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to="#">Orders</Link>
          </li>
          <li className="breadcrumb-item active">Order History</li>
        </ol>
      </nav>
    );
  }
}

export default Breadcrumb;
