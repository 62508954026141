import { CircularProgress, Table } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { storeVal } from "../../..";
import { singleCategory } from "../../../Redux/categorySlice";
import customAxios from "../../../customAxios";
import authHeader from "../../../authHeader";

const Content = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const { languageArabic } = ;
  const location = useLocation();

  // useEffect(() => {
  //   dispatch(singleCategory(id));
  // }, []);

  const { singleCategoryData, isLoading } = useSelector(
    (state) => state.categorySlice
  );
  // let storeData = storeVal();
  // const seenmessages = storeData.getState();
  // const newValue = seenmessages.categorySlice.singleCategoryData;

  // const viewData = singleCategoryData;
  const { t, i18n } = useTranslation();
  const [saveData, setSaveData] = useState({});
  const [refresh, setRefresh] = useState(false);

  const currentLang = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();
  const [data, setData] = useState([]);
  let newData;
  useEffect(() => {
    setCurrLang(currentLang);
  }, [currentLang]);

  useEffect(() => {
    if (id) {
      let singleData = "/vendor/category/show/" + id;
      setRefresh(true);
      customAxios
        .get(singleData, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setSaveData(data?.data);
            newData = [...newData, data?.data];
          }
        })
        .catch(function (error) {})
        .finally(() => {
          setRefresh(false);
        });
    }
  }, [id]);

  const handleCancel = () => {
    history.push("/menu-category");
    // setSaveData([]);
  };

  return (
    <div className="">
      <div className="col-xl-6 col-md-6">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-body">
            <div className="ms-profile-user-buttons viewbuttons">
              <h2 className="section-title new-heading">
                {t("View Categories")}
              </h2>
              <button
                className="btn-primary d-block mt-0"
                type="submit"
                onClick={handleCancel}
              >
                {t("Back")}
              </button>
            </div>
            <Table className="table ms-profile-information">
              <tbody>
                {refresh ? (
                  <>
                    <CircularProgress
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                        color: "orange",
                        animation: "none",
                        // animationDuration: "1s",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <tr>
                      <th scope="row">{t("Name")}</th>
                      <td> {saveData?.name && saveData?.name[currentLang]} </td>
                    </tr>
                    <tr>
                      <th scope="row">{t("Parent Category")}</th>
                      <td>{saveData?.parent_category_id}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("Is Active")}</th>
                      <td>
                        {saveData?.is_active === 1 ? (
                          <span style={{ color: "green" }}>Active</span>
                        ) : saveData?.is_active === 0 ? (
                          <span style={{ color: "red" }}>Inactive</span>
                        ) : null}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
