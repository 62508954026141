import React from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Breadcrumb from "../sections/CreateProduct/Breadcrumb";
import Content from "../sections/CreateProduct/Content";
import { useSelector } from "react-redux";

const CreateProduct = (props) => {
  const { singleProductData } = useSelector((state) => state.productSlice);

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="col-md-12">
          <Breadcrumb />
          <Content props={{ data: singleProductData, props }} />
        </div>
      </main>
    </div>
  );
};

export default CreateProduct;
