import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 16,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  objectFit: "contain",
};

const closeIconContainer = {
  position: "absolute",
  top: "-10px",
  right: "-10px",
  border: "1px solid black",
  width: "10px",
  height: "10px",
  padding: "10px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "white",
  cursor: "pointer",
};

export const MyDropzone = ({
  setImageUpload,
  showPreview,
  backgroundImage,
}) => {
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [preview, setPreview] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((imageFile) => {
      convertBase64(imageFile)
        .then((result) => {
          imageFile["base64"] = result;
          setImageUpload((prev) => [...prev, imageFile["base64"]]);
        })
        .catch((err) => {});
    });
    const fileobject = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setPreview((prevState) => {
      return [...prevState, ...fileobject];
    });
  }, []);

  const thumbs = preview?.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file?.preview}
          style={img}
          alt={file.name}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <p
        className="close-button-container"
        style={closeIconContainer}
        onClick={() => {
          let filteredPreviews = preview?.filter(
            (pre) => pre?.name !== file?.name
          );
          setPreview(filteredPreviews);
        }}
      >
        <i class="Medium material-icons">close</i>
      </p>
    </div>
  ));
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount

    return () => preview?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      <div
        style={{
          border: "1px dashed black",
          width: "100px",
          height: "100px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          // backgroundImage: `url(${backgroundImage})`,
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <i class="Medium material-icons">add_a_photo</i>
          {isDragActive ? (
            <p style={{ margin: "0" }}>Upload</p>
          ) : (
            <p style={{ margin: "0" }}>Drag & drop</p>
          )}
        </div>
      </div>
      {thumbs}
    </div>
  );
};
