import React, { useEffect, useState } from "react";
import { Button, Collapse, FormControl, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { attributeSetList } from "../../Redux/attributeSetSlice";
import { useParams } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { attributeList } from "../../Redux/attributeSlice";
function CollapseField({
  setProductMultiData,
  // saveData,
  attributeSetDataOptions,
}) {
  const dispatch = useDispatch();
  const { attributeData } = useSelector((state) => state.attributeSlice);
  const { attributeSetData } = useSelector((state) => state.attributeSetSlice);

  const [isCollapsed, setIsCollapsed] = useState([]);
  const [selectedAttribute1, setSelectedAttribute1] = useState("");
  const [selectedAttribute2, setSelectedAttribute2] = useState("");
  const [attributeSetID, setAttributeSetID] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [showCollapsedField, setShowCollapsedField] = useState(true);
  const [attributeTest, setAttributeTest] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [collapseFields, setCollapseFields] = useState([]);

  const { id } = useParams();
  const pageone = 1;

  const handleSelectChange1 = (event) => {
    setSelectedAttribute1(event.target.value);
  };

  const handleSelectChange2 = (event) => {
    setSelectedAttribute2(event.target.value);
  };
  const handleSelectChangeSet = ({ value, index, setIndex, field }) => {
    setCollapseFields((prevFields) => {
      const updatedFields = [...prevFields];
      const attributeSets = [...updatedFields[index].attribute_set]; // Create a copy of attribute_sets
      attributeSets[setIndex] = {
        ...attributeSets[setIndex],
        [field]: value,
      };
      updatedFields[index] = {
        ...updatedFields[index],
        attribute_set: attributeSets,
      };

      return updatedFields;
    });
  };

  // console.log({ attributeSetDataOptions });

  useEffect(() => {
    if (collapseFields.length > 0) {
      const newPayload = collapseFields.map((field) => {
        const attributeId = field.attribute_id || "";
        const attributeSets =
          field.attribute_set?.map((set) => ({
            id: set.id || "",
            price: set.price || "",
            is_active: set.is_active || "",
          })) || [];

        return {
          priority: field.priority || "",
          attribute_id: attributeId,
          attribute_set: attributeSets,
        };
      });

      setProductMultiData(newPayload);
    }
  }, [collapseFields]);

  const handleFormSubmit = (event) => {
    if (selectedAttribute1 && selectedAttribute2) {
      const newField = {
        attribute_id: selectedAttribute1,
        priority: selectedAttribute2,
        attribute_set: collapseFields.attribute_set
          ? collapseFields.attribute_set
          : [
              {
                id: "",
                price: "",
                is_active: "",
              },
            ],
      };

      setCollapseFields((prevFields) => [...prevFields, newField]);
    }
  };
  const handleAddFields = (selectedIndex) => {
    setCollapseFields(
      collapseFields.map((instance, index) => {
        setInputFields(instance?.attribute_set);

        if (index === selectedIndex) {
          instance = {
            ...instance,
            attribute_set: [
              ...instance.attribute_set,
              {
                id: "",
                price: "",
                is_active: "",
              },
            ],
          };
        }
        return instance;
      })
    );
  };
  const handleRemoveFields = (index, setIndex) => {
    setInputFields((prevFields) => {
      const values = [...prevFields];
      values.splice(index, 1);
      return values;
    });

    setCollapseFields((prevFields) =>
      prevFields.map((instance, fieldIndex) => {
        if (fieldIndex !== index) {
          return instance;
        } else {
          return {
            ...instance,
            attribute_set: instance.attribute_set.filter(
              (_, attrIndex) => attrIndex !== setIndex
            ),
          };
        }
      })
    );
  };

  const attributeName = attributeData?.data?.map((attributeDataName) => ({
    name: attributeDataName?.name?.en,
    id: attributeDataName?.id,
  }));

  const attributedataforpriority = attributeData?.data?.map(
    (atributeDataid, index) => ({
      id: index + 1,
    })
  );
  const handleCollapsable = (index) => {
    if (isCollapsed.includes(index)) {
      setIsCollapsed(isCollapsed.filter((i) => i !== index));
    } else {
      setIsCollapsed([...isCollapsed, index]);
    }
  };

  useEffect(() => {
    dispatch(attributeList(pageone));
  }, []);

  return (
    <div>
      <div className="attribute-wrapper">
        <FormControl
          fullWidth
          margin="normal"
          className="attribute-container"
          style={{ position: "relative" }}
        >
          <Select
            labelId="attribute-label1"
            id="attribute1"
            value={selectedAttribute1}
            onChange={(event) => handleSelectChange1(event)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            onOpen={() => {
              var page = document.querySelector("html");
              page.style.overflow = "hidden";
            }}
            onClose={() => {
              var page = document.querySelector("html");
              page.style.overflow = "auto";
            }}
          >
            <MenuItem value="">
              <span className="placeholder-select">Attribute</span>
            </MenuItem>
            {attributeName?.map((attributeOption, index) => (
              <MenuItem
                value={attributeOption?.id}
                id={attributeOption?.id}
                key={index}
                onClick={() => setAttributeTest(attributeOption?.id)}
              >
                {attributeOption?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" className="attribute-container">
          <Select
            labelId="attribute-label2"
            id="attribute2"
            value={selectedAttribute2}
            onChange={handleSelectChange2}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            onOpen={() => {
              var page = document.querySelector("html");
              page.style.overflow = "hidden";
            }}
            onClose={() => {
              var page = document.querySelector("html");
              page.style.overflow = "auto";
            }}
          >
            <MenuItem value="">
              <span className="placeholder-select">Priority</span>
            </MenuItem>
            {attributedataforpriority?.map((attributeDataOption, index) => (
              <MenuItem value={attributeDataOption?.id} key={index}>
                {attributeDataOption?.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={handleFormSubmit}
          variant="contained"
          color="primary"
          className="submit-button"
        >
          Submit
        </Button>
      </div>
      {showCollapsedField && (
        <>
          {collapseFields.map((field, index) => {
            const selectedAttributeDetail = attributeData?.data?.find(
              (instance) => instance?.id === field?.attribute_id
            );
            return (
              <>
                <div className="collapse-wrapper" key={index}>
                  <div
                    className="button-container"
                    onClick={() => {
                      handleCollapsable(index);
                    }}
                  >
                    <Button
                      // onClick={handleButtonClick}
                      variant="contained"
                      className="collapse-button-name"
                    >
                      {selectedAttributeDetail?.name?.en}
                    </Button>
                    {isCollapsed.includes(index) ? (
                      <ArrowUpwardIcon sx={{ color: "#ff8306" }} />
                    ) : (
                      <ArrowDownwardIcon sx={{ color: "#ff8306" }} />
                    )}
                  </div>
                  <Collapse in={isCollapsed.includes(index)} key={index}>
                    <form>
                      <div className="p-3 text-right">
                        <Button
                          className="add-field-button"
                          onClick={() => handleAddFields(index)}
                          size="sm"
                        >
                          <b>Add new +</b>
                        </Button>
                        {field?.attribute_set?.map((inputField, setIndex) => {
                          return (
                            <>
                              <div className="d-flex flex-row justify-content-evenly align-items-center p-3 w-96">
                                <div className="input-group attribute-container pr-3">
                                  <Select
                                    labelId="attribute-label1"
                                    value={inputField.id}
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      handleSelectChangeSet({
                                        field: "id",
                                        value,
                                        index,
                                        setIndex,
                                      });
                                    }}
                                    displayEmpty
                                    className="form-gender text-left"
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    onOpen={() => {
                                      var page = document.querySelector("html");
                                      page.style.overflow = "hidden";
                                    }}
                                    onClose={() => {
                                      var page = document.querySelector("html");
                                      page.style.overflow = "auto";
                                    }}
                                  >
                                    <MenuItem value="">
                                      <span className="placeholder-select">
                                        Attribute Set
                                      </span>
                                    </MenuItem>
                                    {attributeSetDataOptions[
                                      selectedAttributeDetail?.id
                                    ]?.map((value, index) => (
                                      <MenuItem key={index} value={value.id}>
                                        {value.value_lang}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                </div>
                                <div className="input-group attribute-container pr-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom18"
                                    placeholder="Price "
                                    name="Price"
                                    value={inputField.price}
                                    onChange={(event) =>
                                      handleSelectChangeSet({
                                        value: event.target.value,
                                        index,
                                        setIndex,
                                        field: "price",
                                      })
                                    }
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                </div>
                                <div className="input-group attribute-container pr-3">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="is_active"
                                      checked={inputField?.is_active}
                                      onChange={(event) =>
                                        handleSelectChangeSet({
                                          value: event.target.checked
                                            ? "1"
                                            : "0",
                                          index,
                                          setIndex,
                                          field: "is_active",
                                        })
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>

                                <Button
                                  className="ml-2 add-field-button py-1 px-3 no-min-width"
                                  onClick={() =>
                                    handleRemoveFields(index, setIndex)
                                  }
                                >
                                  <b>-</b>
                                </Button>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </form>
                  </Collapse>
                </div>
              </>
            );
          })}{" "}
        </>
      )}
    </div>
  );
}

export default CollapseField;
