import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../../Redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ForgetMail } from "./ForgetModal";
import OtpModal from "./OtpModal";
import { ResetPassword } from "./ResetPassword";
import { CircularProgress } from "@mui/material";
// import { reactCodeInput } from "CodeInputField.scss";

{
  /* import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'; */
}

const Content = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 0) {
      errors.password = "Must be 8 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      terms: false,
    },
    validate,
    onSubmit: (values) => {
      const payload = {
        email: values.email,
        password: values.password,
      };
      const request = {
        payload,
        onSuccess: async (message) => {
          toast.success(message);

          // alert(message);
          console.log(message, "message success");
          history.push("/dashboard");
        },
        onFail: (message) => {
          // alert(message);
          toast.error(message);
        },
      };
      dispatch(login(request));
    },
  });
  const [modalShow, setModalShow] = useState(false);
  const [modalOtp, setModalOtp] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const { isLoading } = useSelector((state) => state.loginSlice);
  console.log(isLoading, "isLoading");

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form" style={{ flexDirection: "column" }}>
            <div style={{ paddingBottom: "2rem" }}>
              <h2 style={{ color: "orange" }}>Order 365</h2>
            </div>
            <form className="needs-validation" onSubmit={formik.handleSubmit}>
              <h3>Login to Account</h3>
              <p>Please enter your email and password to continue</p>
              <div className="mb-3">
                <label>Email Address</label>

                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <>
                      <div className="input-error-message">
                        Please provide a valid email.
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="validationCustom09">Password</label>
                <div className="ms-form-group has-icon" style={{ margin: "0" }}>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    id="validationCustom09"
                    placeholder="Password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    // value={passwordInput}
                    // onChange={(e) => setPwd(e.target.value)}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="input-error-message">
                      Please provide a password.
                    </div>
                  ) : null}
                  {passwordShown ? (
                    <i class="fa fa-eye-slash" onClick={togglePassword}></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      onClick={togglePassword}
                    ></i>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="d-block mt-3">
                  <div
                    className="btn-link"
                    onClick={() => setModalShow(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </div>
                </label>
              </div>
              {/* {!isLoading ? (
                <> */}
              <button
                className="btn btn-primary mt-4 d-block w-100"
                type="submit"
                // onClick={<CircularProgress />}
              >
                Sign In
              </button>

              {isLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    color: "orange",
                    animation: "none",
                    // animationDuration: "1s",
                  }}
                />
              )}
              {/* {!isLoading && <h2>dataaa</h2>} */}
              {/* </>
              ) : (
                <>
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      color: "orange",
                      animation: "none",
                      // animationDuration: "1s",
                    }}
                  />
                </>
              )} */}
            </form>
          </div>
        </div>
      </div>
      <ForgetMail
        modalShow={modalShow}
        setModalShow={setModalShow}
        modalOtp={modalOtp}
        setModalOtp={setModalOtp}
      />
      <OtpModal
        modalOtp={modalOtp}
        setModalOtp={setModalOtp}
        modalShow={modalShow}
        setModalShow={setModalShow}
        modalPassword={modalPassword}
        setModalPassword={setModalPassword}
      />
      <ResetPassword
        modalPassword={modalPassword}
        setModalPassword={setModalPassword}
        passwordShown={passwordShown}
        setPasswordShown={setPasswordShown}
      />
    </div>
  );
};

export default Content;
