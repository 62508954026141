import { useFormik } from "formik";
import React, { Component, useEffect, useState, useRef } from "react";
// import { Editor, ContentState, convertFromHTML } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { editvendorinfo, lockups } from "../../../Redux/userSlice";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { MyDropzone } from "../Dropzone/Dropzone";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Singledropzone } from "../singleDropzone/Singledropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Content = () => {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const editorRef = React.useRef(null);
  const policyRef = React.useRef(null);
  const descRef = useRef(null);

  const { userData } = useSelector((state) => state.loginSlice);
  const { nationalities } = useSelector((state) => state.loginSlice);
  const { cities } = useSelector((state) => state.loginSlice);
  const { currencies } = useSelector((state) => state.loginSlice);

  const dispatch = useDispatch();
  const history = useHistory();
  const dataVendor = userData?.vendor;

  const [nationalityOptions, setNationalityOptions] = useState();
  const [cityOptions, setCityOptions] = useState();
  const [currencyOptions, setCurrencyOptions] = useState();
  const [imageUpload, setImageUpload] = useState([]);
  const [vendorBanner, setVendorBanner] = useState([]);
  const [data, setData] = useState([]);

  const [isAddress, setIsAddress] = React.useState("en");
  const handleAddressChange = (event, newAddress) => {
    setIsAddress(newAddress);
  };
  const languageArabic = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();

  useEffect(() => {
    setCurrLang(languageArabic);
  }, [languageArabic]);

  const [taskDescription, setTaskDescription] = useState("");
  // let content;
  const [code, setCode] = useState("");

  const handleTermChange = (content, delta, source, editor) => {
    setCode(content);
  };

  const handlePolicyContentChange = (content) => {
    setTaskDescription(content);
  };
  useEffect(() => {
    // genderOption();
    dispatch(lockups("nationalities"));
    dispatch(lockups("cities"));
    dispatch(lockups("currencies"));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameen: dataVendor?.vendor_name?.en,
      namear: dataVendor?.vendor_name?.ar,
      namede: dataVendor?.vendor_name?.de,
      vendor_phone: dataVendor?.vendor_phone,
      addressen: dataVendor?.vendor_address?.en,
      addressar: dataVendor?.vendor_address?.ar,
      addressde: dataVendor?.vendor_address?.de,
      vendor_city_type: cityOptions,
      vendor_nationality_type: nationalityOptions,
      vendor_currency_type: currencyOptions,
      zipcode: dataVendor?.zipcode,
      latitude: dataVendor?.latitude,
      longitude: dataVendor?.longitude,
      logo_image: dataVendor?.logo_image,
      vendor_banner: dataVendor?.vendor_banner,
      term_condition: code,
      policy: taskDescription,
    },
    // validate,
    onSubmit: (values) => {
      let namevalue = {
        en: values.nameen,
        ar: values.namear,
        de: values.namede,
      };
      let address = {
        en: values.addressen,
        ar: values.addressar,
        de: values.addressde,
      };
      const payload = {
        vendor_name: namevalue,
        vendor_phone: values.vendor_phone,
        vendor_address: address,
        vendor_city_type: cityOptions,
        vendor_nationality_type: nationalityOptions,
        vendor_currency_type: currencyOptions,
        zipcode: values.zipcode,
        latitude: values.latitude,
        longitude: values.longitude,
        logo_image: imageUpload,
        banner_image: vendorBanner,
        term_condition: code,
        policy: taskDescription,
      };

      const request = {
        payload,
        onSuccess: async (message) => {
          toast.success(message);
          history.push("/restorent-profile");
        },
        onFail: (message) => {
          toast.error(message);
        },
      };
      dispatch(editvendorinfo(request));
    },
  });

  const { t, i18n } = useTranslation();

  const handleNationChange = (args) => {
    setNationalityOptions(args?.value);
  };
  const handleCityChange = (args) => {
    setCityOptions(args?.value);
  };
  const handleCurrencyChange = (args) => {
    setCurrencyOptions(args?.value);
  };
  const nationOption = nationalities.map((nation) => ({
    value: nation?.id,
    label: nation?.name[languageArabic],
  }));
  const cityOption = cities.map((nation) => ({
    value: nation?.id,
    label: nation?.name[languageArabic],
  }));
  const currencyOption = currencies.map((nation) => ({
    value: nation?.id,
    label: nation?.name[languageArabic],
  }));

  const selectedVendorProfile = () => {
    if (userData?.vendor) {
      setCityOptions(userData?.vendor?.vendor_city_type?.id);
      setNationalityOptions(userData?.vendor?.vendor_nationality_type?.id);
      setCurrencyOptions(userData?.vendor?.vendor_currency_type?.id);
    }
  };

  useEffect(() => {
    selectedVendorProfile();
  }, [userData?.vendor]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  useEffect(() => {
    setCode(userData?.vendor?.term_condition?.en);
    setTaskDescription(userData?.vendor?.policy?.en);
  }, [userData?.vendor]);

  return (
    <div className="ms-content-wrapper">
      <div className="col-xl-12 col-md-12">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-header">
            <h6>{t("Edit Profile")}</h6>
          </div>
          <div className="ms-panel-body">
            <form
              className="needs-validation clearfix"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className="form-row">
                {/* <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Name")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Vendor Name"
                      name="nameen"
                      onChange={formik.handleChange}
                      value={formik.values.nameen}
                      // defaultValue="Manish"
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Vendor Name"
                      name="namear"
                      onChange={formik.handleChange}
                      value={formik.values.namear}
                      // defaultValue="Manish"
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Vendor Name"
                      name="namede"
                      onChange={formik.handleChange}
                      value={formik.values.namede}
                      // defaultValue="Manish"
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div> */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Phone")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Vendor Phone"
                      name="vendor_phone"
                      onChange={formik.handleChange}
                      value={formik.values.vendor_phone}
                      // defaultValue="manish@gamil.com"
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="validationCustom12"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Address")}&nbsp;[
                    <Tabs
                      value={isAddress}
                      onChange={handleAddressChange}
                      aria-label="secondary tabs example"
                      // textColor="secondary"
                    >
                      <Tab
                        value="en"
                        label="English"
                        {...a11yProps("en")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="ar"
                        label="Arabic"
                        {...a11yProps("ar")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="de"
                        label="Germen"
                        {...a11yProps("de")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                    </Tabs>
                    ]
                  </label>
                  <TabPanel value={isAddress} index={"en"}>
                    <div className="input-group">
                      <textarea
                        rows={1}
                        id="validationCustom12"
                        className="form-control"
                        placeholder="Address"
                        required
                        name="addressen"
                        onChange={formik.handleChange}
                        value={formik.values.addressen}
                      />
                      <div className="invalid-feedback">
                        Please provide a message.
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={isAddress} index={"ar"}>
                    <div className="input-group">
                      <textarea
                        rows={1}
                        id="validationCustom12"
                        className="form-control"
                        placeholder="Address"
                        required
                        name="addressar"
                        onChange={formik.handleChange}
                        value={formik.values.addressar}
                      />
                      <div className="invalid-feedback">
                        Please provide a message.
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={isAddress} index={"de"}>
                    <div className="input-group">
                      <textarea
                        rows={1}
                        id="validationCustom12"
                        className="form-control"
                        placeholder="Address"
                        required
                        name="addressde"
                        onChange={formik.handleChange}
                        value={formik.values.addressde}
                      />
                      <div className="invalid-feedback">
                        Please provide a message.
                      </div>
                    </div>
                  </TabPanel>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom22">{t("City")}</label>
                  <div className="input-group">
                    <Select
                      name="city"
                      onChange={handleCityChange}
                      // value={cityOptions}
                      value={cityOption.find(
                        (check) => check.value === cityOptions
                      )}
                      placeholder="city"
                      className="form-gender"
                      options={cityOption}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom23">{t("Nationality")}</label>
                  <div className="input-group">
                    <Select
                      name="Nationality"
                      onChange={handleNationChange}
                      // value={nationalityOptions}
                      placeholder="Nationality"
                      value={nationOption.find(
                        (check) => check.value === nationalityOptions
                      )}
                      className="form-gender"
                      options={nationOption}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom24">{t("Currency")}</label>
                  <div className="input-group">
                    <Select
                      name="Currency"
                      onChange={handleCurrencyChange}
                      // value={currencyOptions}
                      value={currencyOption.find(
                        (check) => check.value === currencyOptions
                      )}
                      placeholder="Currency"
                      className="form-gender"
                      options={currencyOption}
                    />

                    <div className="invalid-feedback">Quantity</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom25">{t("Zipcode")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom25"
                      placeholder="Zipcode"
                      required
                      name="zipcode"
                      onChange={formik.handleChange}
                      value={formik.values.zipcode}
                    />
                    <div className="invalid-feedback">Price</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom23">{t("Latitude")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Latitude"
                      name="latitude"
                      onChange={formik.handleChange}
                      value={formik.values.latitude}
                      //   defaultValue="Manish"
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom23">{t("Longitude")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Longitude"
                      name="longitude"
                      onChange={formik.handleChange}
                      value={formik.values.longitude}
                      //   defaultValue="Manish"
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-5">
                  <label htmlFor="validationCustom23">{t("Logo Image")}</label>
                  <div className="custom-file">
                    <div
                      style={{ width: "100%", display: "flex", gap: "2rem" }}
                    >
                      {imageUpload?.length ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            width: "max-content",
                          }}
                        >
                          <img
                            src={userData?.vendor?.vendor_logo}
                            alt=""
                            // key={productImage?.id}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      )}
                      {/* <MyDropzone setImageUpload={setImageUpload} /> */}
                      <Singledropzone setImageUpload={setImageUpload} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <label htmlFor="validationCustom23">
                    {t("Banner Image")}
                  </label>
                  <div className="custom-file">
                    <div
                      style={{ width: "100%", display: "flex", gap: "2rem" }}
                    >
                      {vendorBanner?.length ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            width: "max-content",
                          }}
                        >
                          <img
                            src={userData?.vendor?.vendor_banner}
                            alt=""
                            // key={productImage?.id}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      )}
                      {/* <MyDropzone setImageUpload={setImageUpload} /> */}
                      <Singledropzone setImageUpload={setVendorBanner} />{" "}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <label htmlFor="validationCustom12">
                    {t("Terms and Condition")}
                  </label>
                  <div className="input-group" style={{ marginBottom: "4rem" }}>
                    <ReactQuill
                      theme="snow"
                      ref={editorRef}
                      modules={modules}
                      formats={formats}
                      value={code}
                      onChange={handleTermChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationCustom12">{t("Policy")}</label>
                  <div className="input-group" style={{ marginBottom: "4rem" }}>
                    <ReactQuill
                      theme="snow"
                      ref={policyRef}
                      modules={modules}
                      formats={formats}
                      value={taskDescription}
                      onChange={handlePolicyContentChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="new">
                    <button
                      className="btn btn-secondary d-block cancelButton"
                      type="submit"
                      onClick={() => history.push("/restorent-profile")}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary d-block saveButton"
                      type="submit"
                    >
                      {t("Update")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
