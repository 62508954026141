import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reload } from "../../../Redux/userSlice";
import { useTranslation } from "react-i18next";

const Content = () => {
  const dispatch = useDispatch();

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [filename, setFileName] = useState("File Upload");
  const { userData } = useSelector((state) => state.loginSlice);
  const [isUndefined, updateIsUndefined] = useState(false);

  // const onError = () => {
  //   updateIsUndefined(true);
  // };
  const { t, i18n } = useTranslation();
  const languageArabic = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();

  useEffect(() => {
    setCurrLang(languageArabic);
  }, [languageArabic]);

  const dataVendor = userData?.vendor;
  // console.log(dataVendor, "userprofileuserDatavendorrrrrrrrrrrrrrrr");

  useEffect(() => {
    // console.log("inside useeffect");
    dispatch(reload());
    // dispatch(lockups("cities"));
  }, []);

  var htmlString = dataVendor?.term_condition?.en;
  var plainString = htmlString?.replace(/<[^>]+>/g, "");

  var htmlPolicyString = dataVendor?.policy?.en;
  var plainPolicyString = htmlPolicyString?.replace(/<[^>]+>/g, "");

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-body">
              <div className="ms-profile-user-buttons new-buttons">
                <h2 className="section-title new-heading">
                  {t("Basic Information")}
                </h2>
                <Link
                  to="/editRestorentProfile"
                  className="btn btn-primary new-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  {" "}
                  <i className="material-icons">person_add</i>{" "}
                  {t("Edit Profile")}
                </Link>
              </div>
              <table className="table ms-profile-information">
                <tbody>
                  {/* <tr>
                    <th scope="row">{t("Name")}</th>
                    <td>{dataVendor?.vendor_name[languageArabic]}</td>
                  </tr> */}

                  <tr>
                    <th scope="row">{t("Phone")}</th>
                    <td>{dataVendor?.vendor_phone}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Address")}</th>
                    <td>{dataVendor?.vendor_address[languageArabic]}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("City")}</th>
                    <td>
                      {dataVendor?.vendor_city_type?.name[languageArabic]}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Nationality")}</th>
                    <td>
                      {
                        dataVendor?.vendor_nationality_type?.name[
                          languageArabic
                        ]
                      }
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Currency")}</th>
                    <td>
                      {dataVendor?.vendor_currency_type?.name[languageArabic]}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Zipcode")}</th>
                    <td>{dataVendor?.zipcode}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Latitude")}</th>
                    <td>{dataVendor?.latitude}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Longitude")}</th>
                    <td>{dataVendor?.longitude}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Logo Image")}</th>
                    <td>
                      <img src={dataVendor?.vendor_logo} alt="logo" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Banner Image")}</th>
                    <td>
                      <img src={dataVendor?.vendor_banner} alt="banner" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Term and Condition")}</th>
                    <td>{plainString}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Policy")}</th>
                    <td>{plainPolicyString}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
