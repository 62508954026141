import React from "react";

import { createSlice } from "@reduxjs/toolkit";
import { ApiService } from "../Api/ApiService";
const initialState = {
  isLoading: false,
  token: "",
  errorMessage: "",
  successMessage: "",
  parent_category_id: "",
  singleCategoryData: {},
  categoryData: [],
  // userData: "",
};
export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    createCategoryRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    createCategorySuccessful(state, action) {
      state.isLoading = false;
      state.categoryData = action.payload;
    },
    createCategoryFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    categoryTreeListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    categoryTreeListSuccessful(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
    },
    categoryTreeListFailed(state, action) {
      state.errorMessage = action.payload;
    },
    updateCategoryRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    updateCategorySuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    updateCategoryFailed(state, action) {
      state.errorMessage = action.payload;
    },
    categoryListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
      state.categoryData = [];
    },
    categoryListSuccessful(state, action) {
      state.isLoading = false;
      state.categoryData = action.payload;
    },
    categoryListFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    singleCategoryRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    singleCategorySuccessful(state, action) {
      state.isLoading = false;
      state.singleCategoryData = action.payload;
    },
    singleCategoryFailed(state, action) {
      state.errorMessage = action.payload;
    },
    deleteCategoryRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    deleteCategorySuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    deleteCategoryFailed(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  createCategoryRequested,
  createCategorySuccessful,
  createCategoryFailed,
  categoryListRequested,
  categoryListSuccessful,
  categoryListFailed,
  categoryTreeListRequested,
  categoryTreeListSuccessful,
  categoryTreeListFailed,
  updateCategoryRequested,
  updateCategorySuccessful,
  updateCategoryFailed,
  singleCategoryRequested,
  singleCategorySuccessful,
  singleCategoryFailed,
  deleteCategoryRequested,
  deleteCategorySuccessful,
  deleteCategoryFailed,
} = categorySlice.actions;

export default categorySlice.reducer;

export const createCategory = (request) => async (dispatch, getState) => {
  //   const { token } = getState().createCategory;
  const categoryToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = categoryToken?.token;
  // console.log(categoryToken, "token22222");
  // console.log(authtoken, "token3333");

  const { payload, onSuccess, onFail } = request;
  //   const {businessId} = getState().loginSlice;
  dispatch(createCategoryRequested());
  try {
    const res = await ApiService.createCategory(payload, authtoken);

    if (res?.status === 201) {
      // console.log(res, "res");
      dispatch(createCategorySuccessful());

      onSuccess(res?.data?.message);
    } else {
      // console.log("else");
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        createCategoryFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        createCategoryFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        createCategoryFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
export const categoryList = (currPage) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // console.log(userToken, "userToken");
  // const { onSuccess, onFail } = ;request
  // const { token } = getState().loginSlice;
  dispatch(categoryListRequested());
  try {
    // console.log(token, "token2");
    const res = await ApiService.categoryList(authtoken, currPage);

    if (res?.status === 200) {
      // console.log(res, "fdfjdjfj");
      dispatch(categoryListSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        categoryListFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        categoryListFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        categoryListFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};
export const categoryTreeList = () => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // console.log(userToken, "userToken");
  // const { onSuccess, onFail } = ;request
  // const { token } = getState().loginSlice;
  dispatch(categoryTreeListRequested());
  try {
    // console.log(token, "token2");
    const res = await ApiService.categoryTreeList(authtoken);

    if (res?.status === 200) {
      // console.log(res, "fdfjdjfj");
      dispatch(categoryTreeListSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        categoryTreeListFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        categoryTreeListFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        categoryTreeListFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const updateCategory = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail, id } = request;
  // console.log(id, "request");
  // const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(updateCategoryRequested());
  try {
    const res = await ApiService.updateCategory(payload, authtoken, id);
    // console.log(res, "responseslicee");
    if (res?.status === 202) {
      dispatch(updateCategorySuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        updateCategoryFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        updateCategoryFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        updateCategoryFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
export const singleCategory = (uuid) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // console.log(uuid, "uuid");
  // const { onSuccess, onFail } = ;request
  // const { token } = getState().loginSlice;
  dispatch(singleCategoryRequested());
  try {
    // console.log(token, "token2");
    const res = await ApiService.singleCategory(authtoken, uuid);

    if (res?.status === 200) {
      // console.log(res, "fdfjdjfj");
      dispatch(singleCategorySuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        singleCategoryFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        singleCategoryFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        singleCategoryFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};
export const deleteCategory = (uuid) => async (dispatch, getState) => {
  // const { payload, onSuccess, onFail, uuid } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // console.log(uuid, "uuid");

  dispatch(deleteCategoryRequested());
  try {
    const res = await ApiService.deleteCategory(authtoken, uuid);
    // console.log(res, "responseslicee");
    if (res?.status === 202) {
      dispatch(deleteCategorySuccessful());
      // onSuccess(res?.data?.message || "Something went wrong");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        deleteCategoryFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        deleteCategoryFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        deleteCategoryFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
