import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

const Content = () => {
  const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "Required";
    } else if (values.firstname.length > 15) {
      errors.firstname = "Must be 15 characters or less";
    }

    if (!values.lastname) {
      errors.lastname = "Required";
    } else if (values.lastname.length > 10) {
      errors.lastname = "Must be 15 characters or less";
    }

    if (!values.password) {
      errors.password = "Required";
      console.log("error");
    } else if (values.password.length < 8) {
      errors.password = "Must be 8 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      terms: false,
    },
    validate,
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
      console.log(values, "okay");
    },
  });
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <form className="needs-validation" onSubmit={formik.handleSubmit}>
              <h3>Create Account</h3>
              <p>Please enter personal information to continue</p>
              <div className="form-row">
                <div className="col-md-6 ">
                  <label htmlFor="validationCustom01">First name</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      placeholder="First name"
                      name="firstname"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.firstname && formik.errors.firstname ? (
                      <div className="input-error-message">firstname *.</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 ">
                  <label htmlFor="validationCustom02">Last name</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      placeholder="Last name"
                      name="lastname"
                      onChange={formik.handleChange}
                      value={formik.values.lastname}
                    />
                    {formik.touched.lastname && formik.errors.lastname ? (
                      <div className="input-error-message">lastname *.</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 ">
                  <label htmlFor="validationCustom03">Email Address</label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="Email Address"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="input-error-message">
                        Please provide a valid email.
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 ">
                  <label htmlFor="validationCustom04">Password</label>
                  <div className="input-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className="input-error-message">
                        Please provide a password.
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-check pl-0">
                  <label className="ms-checkbox-wrap">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="invalidCheck"
                      name="terms"
                      onChange={formik.handleChange}
                    />
                    <i className="ms-checkbox-check" />
                  </label>
                  <span> Agree to terms and conditions </span>
                </div>
              </div>
              <button
                className="btn btn-primary mt-4 d-block w-100"
                type="submit"
              >
                Create Account
              </button>
              <p className="mb-0 mt-3 text-center">
                Already have an account?{" "}
                <Link className="btn-link" to="/default-login">
                  Login
                </Link>{" "}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
