// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: "/vendor/login",
  logout: "/vendor/logout",
  register: "/vendor/register",
  password: "/vendor/password-reset",
  reload: "/vendor/reload",
  resetPassword: "/vendor/reset-password",
  verifyEmail: "/vendor/verify-email",
  verifyOTP: "/vendor/verify-otp",
  editvendoradmin: "/vendor/edit-vendor-admin",
  updatesearch: "/vendor/update-search-status",
  vendorlockups: "/vendor/lockups",
  editvendorinfo: "/vendor/edit-vendor-info",
  createCategory: "/vendor/category/store",
  categoryTreeList: "/vendor/category/tree",
  categoryList: "/vendor/category?paginate_list=",
  singleCategory: "/vendor/category/show/",
  updateCategory: "/vendor/category/update/",
  deleteCategory: "/vendor/category/destroy/",
  createProduct: "/vendor/product/store",
  productList: "/vendor/product?paginate_list=",
  updateProduct: "/vendor/product/update/",
  singleProduct: "/vendor/product/show/",
  deleteProduct: "/vendor/product/destroy/",
  deleteProductImage: "/vendor/product/destroy-image/",
  changeProductStatus: "/vendor/product/change-status/",
  createAddon: "/vendor/addons/store",
  addonList: "/vendor/addons?paginate_list=",
  updateAddon: "/vendor/addons/update/",
  singleAddon: "/vendor/addons/show/",
  deleteAddon: "/vendor/addons/destroy/",
  getTime: "/vendor/schedule",
  updateTime: "/vendor/schedule/update",
  createAttribute: "/vendor/attribute/store",
  attributeList: "/vendor/attribute?paginate_list=",
  attributeTypeList: "/vendor/attribute-type-options",
  updateAttribute: "/vendor/attribute/update/",
  singleAttribute: "/vendor/attribute/show/",
  deleteAttribute: "/vendor/attribute/destroy/",
  createAttributeSet: "/vendor/attribute-set/store",
  attributeSetList: "/vendor/attribute-set?attribute_id=",
  updateAttributeSet: "/vendor/attribute-set/update/",
  singleAttributeSet: "/vendor/attribute-set/show/",
  deleteAttributeSet: "/vendor/attribute-set/destroy/",
};
