import React from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Breadcrumb from "../sections/CreateAttribute/Breadcrumb";
import Content from "../sections/CreateAttribute/Content";

const CreateAttribute = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="col-md-12">
          <Breadcrumb />
          <Content />
        </div>
      </main>
    </div>
  );
};

export default CreateAttribute;
