import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { singleProduct } from "../../../Redux/productSlice";
import customAxios from "../../../customAxios";
import authHeader from "../../../authHeader";

const Content = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleProductData } = useSelector((state) => state.productSlice);
  const { t, i18n } = useTranslation();

  const [saveData, setSaveData] = useState([]);

  useEffect(() => {
    if (id) {
      let singleData = "/vendor/product/show/" + id;
      customAxios
        .get(singleData, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setSaveData(data?.data);
          }
        })
        .catch(function (error) {});
    }
  }, []);

  const languageArabic = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();

  useEffect(() => {
    setCurrLang(languageArabic);
  }, [languageArabic]);

  const handleCancel = () => {
    history.push("/menu-product");
    setSaveData([]);
  };
  return (
    <div className="">
      <div className="col-xl-12 col-md-12">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-body">
            <div className="ms-profile-user-buttons viewbuttons">
              <h2 className="section-title new-heading">{t("View Product")}</h2>
              <button
                className="btn-primary d-block mt-0"
                type="submit"
                onClick={handleCancel}
              >
                {t("Back")}
              </button>
            </div>
            <table className="table ms-profile-information">
              <tbody>
                <tr>
                  <th scope="row">{t("Sku code")}</th>
                  <td style={{ textAlign: "justify" }}>{saveData?.sku_code}</td>
                </tr>
                <tr>
                  <th scope="row">{t("Name")}</th>
                  <td style={{ textAlign: "justify" }}>{saveData?.name?.en}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "40%" }}>
                    {t("Short Description")}
                  </th>
                  <td style={{ whiteSpace: "inherit", textAlign: "justify" }}>
                    {saveData?.short_description?.en}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "40%" }}>
                    {t("Description")}
                  </th>
                  <td style={{ whiteSpace: "inherit", textAlign: "justify" }}>
                    {saveData?.description?.en}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t("Amount")}</th>
                  <td style={{ textAlign: "justify" }}>{saveData?.amount}</td>
                </tr>
                <tr>
                  <th scope="row">{t("Quality")}</th>
                  <td style={{ textAlign: "justify" }}>{saveData?.quality}</td>
                </tr>
                <tr>
                  <th scope="row">{t("Category")}</th>
                  <td style={{ textAlign: "justify" }}>
                    {saveData?.categories?.map((e, index) => {
                      return (
                        <span>
                          {" "}
                          {(index ? "," : " ") + e?.name[languageArabic]}
                        </span>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t("Addons")}</th>
                  <td style={{ textAlign: "justify" }}>
                    {saveData?.addons?.map((e, index) => {
                      return (
                        <span>
                          {(index ? "," : " ") + e?.name[languageArabic]}{" "}
                        </span>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t("Is Active")}</th>
                  <td style={{ textAlign: "justify" }}>
                    {saveData?.is_active === 1 ? (
                      <span style={{ color: "green" }}>Active</span>
                    ) : saveData?.is_active === 0 ? (
                      <span style={{ color: "red" }}>Inactive</span>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t("Product Attribute")}</th>
                  <td
                    style={{
                      textAlign: "justify",
                      display: "flex",
                      gap: "2rem",
                    }}
                  >
                    {saveData?.product_attributes?.map((productData, index) => {
                      return (
                        <>
                          <span>{productData?.attribute?.name?.en}</span>|
                          <span>{productData?.priority}</span>|
                          <span>
                            {productData?.attribute_sets?.map(
                              (attributeSetData, index) => {
                                return (
                                  <>
                                    <ul>
                                      <li>{attributeSetData?.value?.en}</li>
                                      <li>{attributeSetData?.price}</li>
                                      <li>{attributeSetData?.is_active}</li>
                                    </ul>
                                  </>
                                );
                              }
                            )}
                          </span>
                        </>
                      );
                    })}
                  </td>{" "}
                </tr>
                <tr>
                  <th scope="row">{t("Product Image")}</th>
                  <td style={{ textAlign: "justify" }}>
                    {saveData?.product_images?.map((productImage) => {
                      return (
                        <img
                          src={productImage?.url}
                          alt=""
                          key={productImage?.id}
                        />
                      );
                    })}
                  </td>{" "}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
