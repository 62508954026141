import { createSlice } from "@reduxjs/toolkit";
import { ApiService } from "../Api/ApiService";
const initialState = {
  isLoading: false,
  userData: {},
  lockupsData: [],
  nationalities: [],
  cities: [],
  currencies: [],
  isLoggedIn: false,
  token: "",
  errorMessage: "",
  successMessage: "",
  reset_token: "",
  authUser: "",
};

export const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    loginSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.userData = action.payload.userData;
      state.token = action.payload.token;
    },
    UpdateLoginData(state, action) {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      // state.authUser = action.payload.authUser;
      // state.userData = action.payload.userData;
    },
    loginFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    // logoutSuccessful(state, action) {
    //     state.isLoading = false;
    //     state.isLoggedIn = false;
    // },
    registrationRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    registrationSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    registrationFailed(state, action) {
      state.errorMessage = action.payload;
    },
    // loginFailed(state, action) {
    //   state.errorMessage = action.payload;
    // },
    logoutRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    logoutSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
    },
    logoutFailed(state, action) {
      state.errorMessage = action.payload;
    },
    resetRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    resetSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      // state.isLoggedIn = true;
      // state.userData = action.payload.userData;
      // state.token = action.payload.token;
    },
    resetFailed(state, action) {
      state.errorMessage = action.payload;
    },
    resetPasswordRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    resetPasswordSuccessful(state, action) {
      // state.isLoading = false;
      // state.isLoggedIn = false;
      // state.reset_token = action.payload;
      // state.userData = action.payload.userData;
      // state.isLoggedIn = true;
    },
    resetPasswordFailed(state, action) {
      state.errorMessage = action.payload;
    },
    verifyEmailRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    verifyEmailSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      // state.isLoggedIn = true;
      // state.userData = action.payload.userData;
      // state.token = action.payload.token;
    },
    verifyEmailFailed(state, action) {
      state.errorMessage = action.payload;
    },
    verifyOTPRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    verifyOTPSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      // state.isLoggedIn = true;
      // state.userData = action.payload.userData;
      // state.token = action.payload.token;
    },
    verifyOTPFailed(state, action) {
      state.errorMessage = action.payload;
    },
    reloadRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    reloadSuccessful(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
    },
    reloadFailed(state, action) {
      state.errorMessage = action.payload;
    },
    editvendorRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    editvendorSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    editvendorFailed(state, action) {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    lockupsRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    lockupsSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.lockupsData = action.payload;
      // state.nationalities = action.payload;
    },
    nationalitySuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.nationalities = action.payload;
    },
    citiesSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.cities = action.payload;
    },
    currenciesSuccessful(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.currencies = action.payload;
    },
    lockupsFailed(state, action) {
      state.errorMessage = action.payload;
    },
    editvendorinfoRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    editvendorinfoSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    editvendorinfoFailed(state, action) {
      state.errorMessage = action.payload;
    },
    updateSearchRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    updateSearchSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    updateSearchFailed(state, action) {
      state.errorMessage = action.payload;
    },
  },
});
export const {
  updateSearchRequested,
  updateSearchSuccessful,
  updateSearchFailed,
  loginRequested,
  loginSuccessful,
  loginFailed,
  registrationRequested,
  registrationSuccess,
  registrationFailed,
  logoutRequested,
  logoutSuccessful,
  UpdateLoginData,
  logoutFailed,
  resetRequested,
  resetSuccessful,
  resetFailed,
  reloadRequested,
  reloadSuccessful,
  reloadFailed,
  resetPasswordRequested,
  resetPasswordSuccessful,
  resetPasswordFailed,
  verifyEmailRequested,
  verifyEmailSuccessful,
  verifyEmailFailed,
  verifyOTPRequested,
  verifyOTPSuccessful,
  verifyOTPFailed,
  editvendorRequested,
  editvendorSuccessful,
  editvendorFailed,
  lockupsRequested,
  lockupsSuccessful,
  nationalitySuccessful,
  citiesSuccessful,
  currenciesSuccessful,
  lockupsFailed,
  editvendorinfoRequested,
  editvendorinfoSuccessful,
  editvendorinfoFailed,
} = loginSlice.actions;

export default loginSlice.reducer;

export const login = (request) => async (dispatch) => {
  const { payload, onSuccess, onFail } = request;
  //   const {businessId} = getState().loginSlice;
  dispatch(loginRequested());
  try {
    const res = await ApiService.login(payload);

    if (res?.status === 200) {
      // toast.success(res?.data?.message || "Something went wrong");
      dispatch(
        loginSuccessful({
          userData: res.data.vendor,
          token: res.data.data.token,
        })
      );
      localStorage.setItem(
        "usersLogin",
        JSON.stringify({
          userData: res.data.vendor,
          token: res.data.data.token,
          isLoggedIn: true,
        })
      );

      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        loginFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        loginFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        loginFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const SignUp = (request) => async (dispatch, getState) => {
  const { bodyFormData, onSuccess, onFail } = request;
  // const {businessId} = getState().loginSlice;
  dispatch(registrationRequested());
  try {
    const res = await ApiService.signup(bodyFormData);
    if (res?.status === 200) {
      dispatch(registrationSuccess({ userData: res.data.data }));
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        registrationFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        registrationFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        registrationFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
export const logout = (request) => async (dispatch, getState) => {
  const { onSuccess, onFail } = request;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let token = userToken?.token;
  dispatch(logoutRequested());
  try {
    console.log({ token });
    const res = await ApiService.logout(token);
    if (res?.status === 200) {
      dispatch(logoutSuccessful());
      localStorage.removeItem("usersLogin");
      localStorage.clear();
      onSuccess("Logout Successfully");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        logoutFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        logoutFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        logoutFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const PasswordReset = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let token = userToken?.token;

  dispatch(resetRequested());
  try {
    const res = await ApiService.password(payload, token);
    if (res?.status === 202) {
      dispatch(resetSuccessful());
      localStorage.removeItem("usersLogin");
      localStorage.clear();
      // console.log(res.data.vendor, "api response vendor");
      // console.log(res.data.token, "api response token");

      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        resetFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        resetFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        resetFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const reload = () => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  // const { token } = getState().loginSlice;
  dispatch(reloadRequested());
  try {
    // console.log(token, "token2");
    const res = await ApiService.Userreload(authtoken);

    if (res?.status === 200) {
      dispatch(reloadSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        reloadFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        reloadFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        reloadFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const editvendor = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(editvendorRequested());
  try {
    const res = await ApiService.editvendor(payload, authtoken);
    if (res?.status === 202) {
      dispatch(editvendorSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        editvendorFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        editvendorFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        editvendorFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const lockups = (payload) => async (dispatch, getState) => {
  // const { onSuccess, onFail } = ;request
  const { token } = getState().loginSlice;

  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(lockupsRequested());
  try {
    const res = await ApiService.lockups(authtoken, payload);

    if (res?.status === 200) {
      if (payload === "gender") {
        dispatch(lockupsSuccessful(res?.data?.data));
      } else if (payload === "nationalities") {
        dispatch(nationalitySuccessful(res?.data?.data));
      } else if (payload === "currencies") {
        dispatch(currenciesSuccessful(res?.data?.data));
      } else if (payload === "cities") {
        dispatch(citiesSuccessful(res?.data?.data));
      }
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        lockupsFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        lockupsFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        lockupsFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const editvendorinfo = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(editvendorinfoRequested());
  try {
    const res = await ApiService.editvendorinfo(payload, authtoken);
    if (res?.status === 202) {
      dispatch(editvendorinfoSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        editvendorinfoFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        editvendorinfoFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        editvendorinfoFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
export const resetPassword = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  // const { token } = getState().loginSlice;
  // console.log(token, "token password");

  dispatch(resetPasswordRequested());
  try {
    const res = await ApiService.resetPassword(payload);
    if (res?.status === 202) {
      dispatch(resetPasswordSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        resetPasswordFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        resetPasswordFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        resetPasswordFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
export const verifyEmail = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  // const { token } = getState().loginSlice;
  // console.log(token, "token password");
  // console.log(payload, "payload password");

  dispatch(verifyEmailRequested());
  try {
    const res = await ApiService.verifyEmail(payload);
    if (res?.status === 200) {
      dispatch(verifyEmailSuccessful());
      // console.log(res.data.vendor, "api response vendor");
      // console.log(res.data.token, "api response token");

      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        verifyEmailFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        verifyEmailFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        verifyEmailFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
export const verifyOTP = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  // const { token } = getState().loginSlice;
  // console.log(token, "token password");
  // console.log(payload, "payload password");

  dispatch(verifyOTPRequested());
  try {
    const res = await ApiService.verifyOTP(payload);
    if (res?.status === 200) {
      dispatch(
        verifyOTPSuccessful({
          // userData: res.data.vendor,
          reset_token: res.data.reset_token,
        })
      );
      localStorage.setItem(
        "resettoken",
        JSON.stringify({
          reset_token: res.data.reset_token,
        })
      );

      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        verifyOTPFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        verifyOTPFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        verifyOTPFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
export const updateSearch = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail } = request;
  // const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(updateSearchRequested());
  try {
    const res = await ApiService.updateSearch(payload, authtoken);
    if (res?.status === 202) {
      dispatch(updateSearchSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        updateSearchFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        updateSearchFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        updateSearchFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
