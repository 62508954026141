import React, { Component, useEffect } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Catelougebox from "../sections/Menucatelogue/Catelougebox";
import Quickbar from "../layouts/Quickbar";
import Content from "../sections/Addons/Content";
import Contents from "../sections/Addons/Content";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../sections/Addons/Breadcrumb";

const Addons = () => {
  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");

  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="col-md-12">
          <Breadcrumb />
          <Contents t={t} />
        </div>
      </main>
      {/* <Quickbar /> */}
    </div>
  );
};

export default Addons;
