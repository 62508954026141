import React, { Component, useEffect } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Quickbar from "../layouts/Quickbar";
import Content from "../sections/EditProductList/Content";
import { singleProduct } from "../../Redux/productSlice";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../sections/EditProductList/Breadcrumb";
import { useHistory } from "react-router-dom";

const EditProductList = (props) => {
  const { singleProductData } = useSelector((state) => state.productSlice);

  useEffect(() => {
    // dispatch(singleProduct ());
  }, []);
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="col-md-12">
          <Breadcrumb />
          <Content props={{ data: singleProductData, props }} />
        </div>
      </main>
      {/* <Quickbar /> */}
    </div>
  );
};

export default EditProductList;
