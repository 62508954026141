import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@material-ui/icons/Search";
import {
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import Select from "react-select";
// import { reload } from "../../../Redux/userSlice";
import { Replay } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  attributeSetList,
  createAttributeSet,
  deleteAttributeSet,
  updateAttributeSet,
} from "../../Redux/attributeSetSlice";
import { Modal } from "react-bootstrap";
// import {
//   attributeList,
//   deleteAttribute,
//   updateAttribute,
// } from "../../../Redux/attributeSlice";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  // eslint-disable-next-line no-unused-expressions
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");

  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    // t,
  } = props;

  const headCells = [
    {
      id: "S.N",
      numeric: false,
      disablePadding: true,
      label: `${t("S.N")}`,
    },
    {
      id: "value",
      numeric: false,
      disablePadding: true,
      label: `${t("Value")}`,
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: `${t("Status")}`,
    },
    {
      id: "Action",
      numeric: true,
      disablePadding: false,
      label: `${t("Action")}`,
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            // align="center"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  component="span"
                  style={{
                    display: "none",
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      {/* <th>
        <button className="btn btn-danger" onClick={addRowTable}>
          Insert Row
        </button>
      </th> */}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const languageArabic = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();

  useEffect(() => {
    setCurrLang(languageArabic);
  }, [languageArabic]);
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        textAlign: currLang === "ar" ? "end" : "",
        flexDirection: currLang === "ar" ? "row-reverse" : "",
      }}
      // style={{}}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Contents({ statusOptions, setShowModal, showModal }) {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { attributeData } = useSelector((state) => state.attributeSlice);
  const { attributeSetData, isLoading } = useSelector(
    (state) => state.attributeSetSlice
  );

  const [allAddonData, setAllAddonData] = useState([]);

  const [addonListData, setAddonListData] = useState([]);
  const [payloadAmount, setPayloadamount] = useState(false);
  const [filterQuery, setIsFilterQuery] = useState({});
  const [value, setValue] = React.useState("en");
  const [disabled, setDisabled] = useState(true);

  const [saveValue, setSaveValue] = useState("true");

  // ******* filter states end ******

  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");

  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);

  const selectRef = useRef(null);
  const activeRef = useRef(null);
  const languageArabic = localStorage.getItem("lang");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const checkStatushandleChange = (event, row) => {
    const activeStatusValue = event.target.checked;
    let valueNames = {
      en: event.target.value,
      ar: event.target.value,
      de: event.target.value,
    };
    const payload = {
      value: valueNames,
      //   type: row.type,
      is_active: activeStatusValue,
    };
    const id = row?.uuid;
    const request = {
      payload,
      id,
      onSuccess: async (message) => {
        toast.success(message);
        history.push("/attribute-set");
        dispatch(attributeSetList(statusOptions));
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(updateAttributeSet(request));
    // setStatusActive(activeStatusValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (statusOptions) {
      dispatch(attributeSetList(statusOptions));
    }
  }, [statusOptions]);

  useEffect(() => {
    setAddonListData(attributeSetData[statusOptions]);
    const listData = attributeSetData[statusOptions]?.map((item) => ({
      ...item,
      type: "edit",
      isEdit: true,
    }));
    setAllAddonData(listData);
  }, [attributeSetData]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allAddonData?.map((n) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allAddonData?.length) : 0;

  const history = useHistory();

  const getViewAttributeSet = (id) => {
    history.push(`/viewAttributeSet/${id}`);
  };

  const getEditAttributeSet = (id) => {
    if (id) {
      const newArray = allAddonData?.map((attribute) => {
        if (attribute?.uuid === id) {
          // Update isEdit property
          return { ...attribute, isEdit: false };
        }
        return attribute;
      });

      // Optionally, you can update the original array
      setAllAddonData(newArray);
    }
  };

  const getDeleteAttributeSet = (id) => {
    dispatch(deleteAttributeSet(id));
    dispatch(attributeSetList(statusOptions));
    // history.push(`/editCategory/${id}`);
  };

  /* START: Search bar */
  const searchAddonData = () => {
    let filteredData = attributeSetData[statusOptions]?.filter((item) => {
      for (const key in filterQuery) {
        if (key in item) {
          if (item[key] !== filterQuery[key]) {
            return false;
          }
        }
      }
      return true;
    });

    setAllAddonData(filteredData);
    setPage(0);
  };
  const handleSearchData = (e, title) => {
    if (e?.target?.name === "name_lang") {
      const { name, value } = e?.target;
      setIsFilterQuery((prev) => ({ ...prev, [name]: value }));
    } else {
      setIsFilterQuery((prev) => ({ ...prev, [title]: e?.value }));
    }
  };

  const clearSearchData = () => {
    selectRef.current.clearValue();
    activeRef.current.clearValue();
    setIsFilterQuery({});
    setAllAddonData(addonListData);
  };

  /* END: Search bar */
  const statusOption = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 0,
      label: "Inactive",
    },
  ];

  let newArray;

  useEffect(() => {
    let parentId = attributeSetData[statusOptions]?.map((addon) => ({
      label: addon?.type,
      value: addon?.type,
    }));
    newArray = [...new Set(parentId?.map((obj) => JSON.stringify(obj)))].map(
      (str) => JSON.parse(str)
    );
    setPayloadamount(newArray);
    // const uniqueArray = [
    //   ...new Set(myArray.map((obj) => JSON.stringify(obj))),
    // ].map((str) => JSON.parse(str));
  }, [attributeSetData]);

  // useEffect(() => {
  //   console.log(filterQuery, "test query");
  // }, [filterQuery]);
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const getSerialNumber = (index) => {
    return index + page * rowsPerPage + 1;
  };

  //   const handleAddRow = () => {
  //     const item = {};
  //     setRows([...rows, item]);
  //   };

  const handleAddRow = () => {
    const item = {
      value: "",
      is_active: "",
      isEdit: false,
      type: "add",
    };
    setAllAddonData([...allAddonData, item]);
  };

  // const handleRemoveRow = () => {
  //   setAllAddonData([allAddonData.slice(0, -1)]);
  // };

  const handleRemoveSpecificRow = (id) => {
    const rows = allAddonData.filter((row) => row.id !== id);
    setAllAddonData(rows);
  };

  const handleAttributeValue = (event, row) => {
    const activeStatusValue = event.target.checked;
    let valueNames = {
      en: event.target.value,
      ar: event.target.value,
      de: event.target.value,
    };

    const payload = {
      value: valueNames,
      attribute_id: statusOptions,
      is_active: saveValue,
    };

    const id = row?.uuid;
    const request = {
      payload,
      onSuccess: async (message) => {
        toast.success(message);
        setShowModal(true);
        await dispatch(attributeSetList(statusOptions));
        // history.push("/attribute-set");
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    const editRequest = {
      payload,
      id,
      onSuccess: async (message) => {
        toast.success(message);
        setShowModal(true);
        await dispatch(attributeSetList(statusOptions));
        // history.push("/attribute-set");
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    if (row?.type === "add") {
      dispatch(createAttributeSet(request));
    } else {
      dispatch(updateAttributeSet(editRequest));
    }
    setSaveValue(saveValue);
  };

  return (
    <Modal
      size="xl"
      show={showModal}
      // onHide={onClose}
      onHide={() => setShowModal(!showModal)}
      animation={false}
      contentClassName="modal-content-info"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Attribute Set List{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            {selected.length > 0 ? (
              <EnhancedTableToolbar numSelected={selected.length} />
            ) : (
              <>
                {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0rem 1rem",
                paddingTop: "1rem",
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {t("Attribute List")}
              </Typography>

              <Tooltip title="Filter list">
                <IconButton>
                  <Link
                    to="/createAttribute"
                    className="btn btn-primary new-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <i className="material-icons">person_add</i>{" "}
                    {t("Create Attribute")}{" "}
                  </Link>{" "}
                </IconButton>
              </Tooltip>
            </div> */}
                {/* <TableCell> */}
                {/* <button className="btn btn-danger" onClick={handleAddRow}>
              Insert Row
            </button> */}
                {/* </TableCell> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0rem 1rem",
                    paddingTop: "1rem",
                  }}
                  className="media-query"
                >
                  <div className=" w-100 mr-2 wrapper">
                    <TextField
                      id="outlined-basic"
                      name="name_lang"
                      variant="outlined"
                      placeholder="Search Name"
                      className="text"
                      value={filterQuery?.name_lang ?? ""}
                      onChange={(e) => handleSearchData(e)}
                    />
                  </div>
                  <div
                    className="w-100 ml-2 wrapper-left"
                    // style={{ width: "50%" }}
                  >
                    <Select
                      ref={activeRef}
                      name="is_active"
                      onChange={(e) => handleSearchData(e, "is_active")}
                      // value={statusOptions}
                      options={statusOption}
                      placeholder="Select Status"
                      className="form-gender"
                    />
                  </div>
                  <div className="w-100 reset-container">
                    <div
                      style={{
                        border: "1px solid lightgrey",
                        fontSize: "23px",
                        width: "23%",
                        textAlign: "center",
                        borderRadius: "4px",
                        // color: "#808080",
                        backgroundColor: "black",
                        color: "white",
                        marginLeft: "7px",
                        cursor: "pointer",
                      }}
                      onClick={searchAddonData}
                      className="searchbar"
                    >
                      <SearchIcon />
                    </div>
                    <div
                      style={{
                        border: "1px solid lightgrey",
                        fontSize: "23px",
                        width: "23%",
                        textAlign: "center",
                        borderRadius: "4px",
                        // color: "#808080",
                        backgroundColor: "black",
                        color: "white",
                        marginLeft: "7px",
                        cursor: "pointer",
                      }}
                      onClick={clearSearchData}
                      className="searchbar"
                    >
                      <Replay />{" "}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-center mt-3 px-3">
                  <button
                    className=""
                    style={{
                      border: "none",
                      background: "green",
                      color: "white",
                    }}
                    onClick={handleAddRow}
                  >
                    <b>+</b>
                  </button>
                  <button
                    style={{
                      border: "none",
                      background: "red",
                      color: "white",
                    }}
                    className="ml-2"
                    onClick={() => handleRemoveSpecificRow()}
                  >
                    <b>-</b>
                  </button>
                </div>
              </>
            )}

            <TableContainer
              style={{
                direction: languageArabic === "ar" ? "rtl" : "unset",
                width: "95%",
                margin: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                style={{ position: "relative" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={allAddonData?.length}
                  //   t={t}
                />
                <TableBody>
                  {!isLoading ? (
                    <>
                      {allAddonData?.length === 0 ? (
                        <>
                          <b
                            style={{
                              // display: "flex",
                              // justifyContent: "center",
                              color: "red",
                              whiteSpace: "nowrap",
                            }}
                          >
                            No Data Available
                          </b>
                        </>
                      ) : (
                        <>
                          {stableSort(
                            allAddonData,
                            getComparator(order, orderBy)
                          )
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((row, index) => {
                              const isItemSelected = isSelected(row?.id);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row?.id}
                                  selected={isItemSelected}
                                  //   rows={rows}
                                  //   tableRowRemove={tableRowRemove}
                                  //   onValUpdate={onValUpdate}
                                  // handleRemoveSpecificRow={handleRemoveSpecificRow}
                                >
                                  {/* <TableCell padding="checkbox">
                                    <Checkbox
                                      onClick={(event) =>
                                        handleClick(event, row?.id)
                                      }
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell> */}
                                  <TableCell>
                                    {getSerialNumber(index)}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    // align="center"
                                  >
                                    <div>
                                      <label
                                        htmlFor="validationCustom18"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {t("Value")} &nbsp;[
                                        <Tabs
                                          value={value}
                                          onChange={handleChange}
                                          aria-label="secondary tabs example"
                                          // textColor="secondary"
                                        >
                                          <Tab
                                            value="en"
                                            label="English"
                                            {...a11yProps("en")}
                                            style={{
                                              textTransform: "unset",
                                              fontSize: "12px",
                                              minWidth: "50px",
                                              padding: "0",
                                              minHeight: "0",
                                            }}
                                          />
                                          <Tab
                                            value="ar"
                                            label="Arabic"
                                            {...a11yProps("ar")}
                                            style={{
                                              textTransform: "unset",
                                              fontSize: "12px",
                                              minWidth: "50px",
                                              padding: "0",
                                              minHeight: "0",
                                            }}
                                          />
                                          <Tab
                                            value="de"
                                            label="Germen"
                                            {...a11yProps("de")}
                                            style={{
                                              textTransform: "unset",
                                              fontSize: "12px",
                                              minWidth: "50px",
                                              padding: "0",
                                              minHeight: "0",
                                            }}
                                          />
                                        </Tabs>
                                        ]
                                      </label>
                                      <TabPanel value={value} index={"en"}>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="validationCustom18"
                                            placeholder="Value"
                                            disabled={row?.isEdit}
                                            name="nameen"
                                            defaultValue={row?.value?.en}
                                            onBlur={(e) =>
                                              handleAttributeValue(e, row, "en")
                                            }

                                            // onChange={formik.handleChange}
                                            // value={formik.values.nameen}
                                            // defaultValue="Manish"
                                          />
                                        </div>
                                        {/* Item One */}
                                      </TabPanel>
                                      <TabPanel value={value} index={"ar"}>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="validationCustom18"
                                            placeholder="Value"
                                            name="namear"
                                            disabled={row?.isEdit}
                                            value={row?.value?.ar}
                                            onBlur={(e) =>
                                              handleAttributeValue(e, row, "ar")
                                            }

                                            // onChange={formik.handleChange}
                                            // value={formik.values.namear}
                                            // defaultValue="Manish"
                                          />
                                        </div>
                                        {/* Item Two */}
                                      </TabPanel>
                                      <TabPanel value={value} index={"de"}>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="validationCustom18"
                                            placeholder="Value"
                                            name="namede"
                                            value={row?.value?.de}
                                            disabled={row?.isEdit}
                                            onBlur={(e) =>
                                              handleAttributeValue(e, row, "de")
                                            }

                                            // onChange={formik.handleChange}
                                            // value={formik.values.namede}
                                            // defaultValue="Manish"
                                          />
                                        </div>
                                        {/* Item Three */}
                                      </TabPanel>
                                    </div>
                                    {/* <input
                                  type="text"
                                  value={row?.value?.en}
                                  onBlur={(e) => handleAttributeValue(e)}
                                /> */}
                                    {/* {row?.value?.en} */}
                                  </TableCell>
                                  <TableCell>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="is_active"
                                        checked={
                                          row?.is_active === 1 ? true : false
                                        }
                                        onChange={(e) =>
                                          checkStatushandleChange(e, row)
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </TableCell>
                                  <TableCell>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      {/* <li
                                    className="list-inline-item"
                                    data-bs-toggle="tooltip"
                                    data-bs-trigger="hover"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="View"
                                  >
                                    <div
                                      onClick={() =>
                                        getViewAttributeSet(row?.uuid)
                                      }
                                      // href="/viewCategory"
                                      className="text-success d-inline-block"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fas fa-eye"></i>
                                    </div>
                                  </li>{" "} */}
                                      <li
                                        className="list-inline-item edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Edit"
                                      >
                                        <div
                                          onClick={() =>
                                            getEditAttributeSet(row?.uuid)
                                          }
                                          // href="/editCategory"
                                          className="text-primary d-inline-block edit-item-btn"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="fas fa-edit"></i>
                                        </div>
                                      </li>{" "}
                                      <li
                                        className="list-inline-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Delete"
                                      >
                                        <div
                                          // href="/"
                                          onClick={() =>
                                            getDeleteAttributeSet(row?.uuid)
                                          }
                                          className="text-danger d-inline-block remove-item-btn"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="material-icons  delete">
                                            delete
                                          </i>
                                        </div>
                                      </li>{" "}
                                    </ul>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </>
                      )}
                    </>
                  ) : (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                        color: "orange",
                        animation: "none",
                      }}
                    />
                  )}

                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allAddonData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}
