import React, { Component } from "react";
import { Link } from "react-router-dom";

const Breadcrumb = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb pl-0">
        <li className="breadcrumb-item">
          <Link to="/dashboard">
            <i className="material-icons">home</i> Home
          </Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/attribute-set">Configuration</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/attribute-set"> Attribute</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          View Attribute-Set{" "}
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
