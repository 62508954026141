import React from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../Redux/userSlice";

export const ResetPassword = ({
  modalPassword,
  setModalPassword,
  passwordShown,
  setPasswordShown,
}) => {
  const dispatch = useDispatch();

  const resetToken = localStorage.getItem("resettoken");
  const resetData = JSON.parse(resetToken);
  let token = resetData?.reset_token;

  const validate = (values) => {
    console.log(values, "valuesresettt");
    const errors = {};
    if (!values.new_password) {
      errors.new_password = "Required";
    } else if (values.new_password.length < 0) {
      errors.new_password = "Must be 8 characters or less";
    }
    return errors;
  };

  const formreset = useFormik({
    initialValues: {
      reset_token: "",
      new_password: "",
    },
    validate,
    onSubmit: (values) => {
      const payload = {
        reset_token: token,
        new_password: values.new_password,
      };
      const request = {
        payload,
        onSuccess: async (message) => {
          toast.success(message);
          setModalPassword(!modalPassword);
        },
        onFail: (message) => {
          toast.error(message);
        },
      };
      dispatch(resetPassword(request));
    },
  });
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div>
      <Modal
        className="modal-min"
        show={modalPassword}
        onHide={() => setModalPassword(!modalPassword)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <button
            type="button"
            className="close"
            onClick={() => setModalPassword(false)}
            style={{ cursor: "pointer" }}
          >
            <span aria-hidden="true">×</span>
          </button>
          <i className="flaticon-secure-shield d-block" />
          <h1>New Password</h1>
          <form onSubmit={formreset.handleSubmit}>
            <div className="ms-form-group has-icon">
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Create New Password"
                className="form-control form-control-email"
                name="new_password"
                onChange={formreset.handleChange}
                value={formreset.values.new_password}
              />{" "}
              {formreset.touched.new_password &&
              formreset.errors.new_password ? (
                <div className="input-error-message">
                  Please provide a new password.
                </div>
              ) : null}
              {passwordShown ? (
                <div className="oldpass">
                  <i class="fa fa-eye-slash" onClick={togglePassword}></i>{" "}
                </div>
              ) : (
                <div className="oldpass">
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                    onClick={togglePassword}
                  ></i>
                </div>
              )}
            </div>
            <button type="submit" className="btn btn-primary shadow-none">
              Reset Password
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
