import React, { useEffect } from "react";
// import { Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
import Preloader from "../components/layouts/Preloader";
import Accordions from "../components/pages/Accordions";
import Addons from "../components/pages/Addons";
import Addproduct from "../components/pages/Addproduct";
import Basictables from "../components/pages/Basictables";
import Buttons from "../components/pages/Buttons";
import Cards from "../components/pages/Cards";
import Chartjs from "../components/pages/Chartjs";
import Chat from "../components/pages/Chat";
import Clientmanagement from "../components/pages/Clientmanagement";
import Comingsoon from "../components/pages/Comingsoon";
import CreateAddons from "../components/pages/CreateAddons";
import CreateCategory from "../components/pages/CreateCategory";
import CreateProduct from "../components/pages/CreateProduct";
import Cropper from "../components/pages/Cropper";
import Customerlist from "../components/pages/Customerlist";
import Customerreview from "../components/pages/Customerreview";
import Dashboard from "../components/pages/Dashboard";
import Datatables from "../components/pages/Datatables";
import Defaultlogin from "../components/pages/Defaultlogin";
import Defaultregister from "../components/pages/Defaultregister";
import Draggables from "../components/pages/Draggables";
import EditCategory from "../components/pages/EditCategory";
import EditAttribute from "../components/pages/EditAttribute";
import EditAttributeSet from "../components/pages/EditAttributeSet";
import Editproduct from "../components/pages/EditProduct";
import EditProductList from "../components/pages/EditProductList";
import EditAddons from "../components/pages/EditAddons";
import EditProfile from "../components/pages/EditProfile";
import EditRestorentProfile from "../components/pages/EditRestorentProfile";
import Email from "../components/pages/Email";
import Faq from "../components/pages/Faq";
import Flaticons from "../components/pages/Flaticons";
import Fontawesome from "../components/pages/Fontawesome";
import Formelements from "../components/pages/Formelements";
import Formlayouts from "../components/pages/Formlayouts";
import Formvalidation from "../components/pages/Formvalidation";
import Formwizard from "../components/pages/Formwizard";
import Googlemaps from "../components/pages/Googlemaps";
import Invoice from "../components/pages/Invoice";
import Invoicedetail from "../components/pages/Invoicedetail";
import Invoicelist from "../components/pages/Invoicelist";
import Lockscreen from "../components/pages/Lockscreen";
import Materialize from "../components/pages/Materialize";
import Menucatalogue from "../components/pages/Menucatalogue";
import Menugrid from "../components/pages/Menugrid";
import Menulist from "../components/pages/Menulist";
import Modallogin from "../components/pages/Modallogin";
import Modalregister from "../components/pages/Modalregister";
import Modals from "../components/pages/Modals";
import Orders from "../components/pages/Orders";
import Pagination from "../components/pages/Pagination";
import Portfolio from "../components/pages/Portfolio";
import Preloaders from "../components/pages/Preloaders";
import Product from "../components/pages/Product";
import Productdetail from "../components/pages/Productdetail";
import Progressbars from "../components/pages/Progressbars";
import Rangeslider from "../components/pages/Rangeslider";
import Rating from "../components/pages/Rating";
import Restaurantlist from "../components/pages/Restaurantlist";
import ResturantProfile from "../components/pages/ResturantProfile";
import Sales from "../components/pages/Sales";
import Sliders from "../components/pages/Sliders";
import Socialactivity from "../components/pages/Socialactivity";
import Stockmanagement from "../components/pages/Stockmanagement";
import Sweetalerts from "../components/pages/Sweetalerts";
import Tabs from "../components/pages/Tabs";
import Toast from "../components/pages/Toast";
import Todolist from "../components/pages/Todolist";
import Tour from "../components/pages/Tour";
import Typography from "../components/pages/Typography";
import Userprofile from "../components/pages/Userprofile";
import Vectormaps from "../components/pages/Vectormaps";
import ViewAddons from "../components/pages/ViewAddons";
import ViewAttribute from "../components/pages/ViewAttribute";
import ViewAttributeSet from "../components/pages/ViewAttributeSet";
import ViewCategory from "../components/pages/ViewCategory";
import ViewProduct from "../components/pages/ViewProduct";
import Webanalytics from "../components/pages/Webanalytics";
import { createBrowserHistory } from "history";
import Categories from "../components/pages/Categories";
import Error from "../components/pages/Error";
import { useTranslation } from "react-i18next";
import TimeSchedules from "../components/pages/TimeSchedule";
import EditSchedule from "../components/pages/EditSchedule";
import OrdersHistory from "../components/pages/OrderHistory";
import Couponlist from "../components/pages/Couponlist";
import CouponList from "../components/sections/Couponlist/Couponlist";
import Createcoupon from "../components/pages/Createcoupon";
import Attribute from "../components/pages/Attribute";
import Attributeset from "../components/pages/Attributeset";
import CreateAttribute from "../components/pages/CreateAttribute";
import CreateAttributeSet from "../components/pages/CreateAttributeSet";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const userData = JSON.parse(localStorage.getItem("usersLogin"));
  const isLoggedIn = userData?.isLoggedIn;
  // console.log(isLoggedIn, "isLoggedIn");
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");

  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);

  const userData = JSON.parse(localStorage.getItem("usersLogin"));
  const isLoggedIn = userData?.isLoggedIn;
  const location = useLocation();

  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/login"} />;
  } else if (isLoggedIn && location.pathname === "/login") {
    return <Redirect to={"/dashboard"} />;
  }

  return (
    // <React.Fragment>
    <>
      <Preloader />
      <Switch>
        {/* <Route exact path="/" component={Defaultlogin} /> */}
        <Route path="/login" component={Defaultlogin} />
        {/* <Route path="/" component={Dashboard} /> */}
        {/* <Route exact element={<RestrictedRoute />}>
          {" "} */}
        <RestrictedRoute path="/dashboard" component={Dashboard} />
        <RestrictedRoute
          path="/timeSchedule"
          component={TimeSchedules}
          className={
            location.pathname === "/timeSchedule"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        {/* </Route> */}
        <RestrictedRoute path="/accordions" component={Accordions} />
        <RestrictedRoute path="/add-product" component={Addproduct} />
        <RestrictedRoute path="/basic-tables" component={Basictables} />
        <RestrictedRoute path="/buttons" component={Buttons} />
        <RestrictedRoute path="/cards" component={Cards} />
        <RestrictedRoute path="/editProfile" component={EditProfile} />
        <RestrictedRoute
          path="/editSchedule/:id"
          component={EditSchedule}
          className={
            location.pathname === "/editSchedule"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/restorent-profile"
          component={ResturantProfile}
        />
        <RestrictedRoute
          path="/editRestorentProfile"
          component={EditRestorentProfile}
        />
        <RestrictedRoute
          path="/editProductList/:id"
          component={EditProductList}
          className={
            location.pathname === "/editProductList"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/editAddons/:id"
          component={EditAddons}
          className={
            location.pathname === "/editAddons"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/createCoupon"
          component={Createcoupon}
          className={
            location.pathname === "/createCoupon"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/createCategory"
          component={CreateCategory}
          className={
            location.pathname === "/createCategory"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/createProduct"
          component={CreateProduct}
          className={
            location.pathname === "/createProduct"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/createAddons"
          component={CreateAddons}
          className={
            location.pathname === "/createAddons"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/createAttribute"
          component={CreateAttribute}
          className={
            location.pathname === "/createAttribute"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/createAttributeSet"
          component={CreateAttributeSet}
          className={
            location.pathname === "/createAttributeSet"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/viewProduct/:id"
          component={ViewProduct}
          className={
            location.pathname === "/viewProduct"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/viewCategory/:id"
          component={ViewCategory}
          className={
            location.pathname === "/viewCategory"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/viewAddons/:id"
          component={ViewAddons}
          className={
            location.pathname === "/viewAddons"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/viewAttribute/:id"
          component={ViewAttribute}
          className={
            location.pathname === "/viewAttribute"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/viewAttributeSet/:id"
          component={ViewAttributeSet}
          className={
            location.pathname === "/viewAttributeSet"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/editCategory/:id"
          component={EditCategory}
          className={
            location.pathname === "/editCategory"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/editAttribute/:id"
          component={EditAttribute}
          className={
            location.pathname === "/editAttribute"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute
          path="/editAttributeSet/:id"
          component={EditAttributeSet}
          className={
            location.pathname === "/editAttributeSet"
              ? "nav-item-in-view"
              : "nav-item"
          }
        />
        <RestrictedRoute path="/chartjs" component={Chartjs} />
        <RestrictedRoute path="/chat" component={Chat} />
        <RestrictedRoute path="/cropper" component={Cropper} />
        <RestrictedRoute path="/customer-list" component={Customerlist} />
        <RestrictedRoute path="/coupon-list" component={Couponlist} />
        <RestrictedRoute path="/customer-review" component={Customerreview} />
        <RestrictedRoute path="/data-tables" component={Datatables} />
        <RestrictedRoute path="/draggables" component={Draggables} />
        <RestrictedRoute path="/email" component={Email} />
        <RestrictedRoute path="/flaticons" component={Flaticons} />
        <RestrictedRoute path="/fontawesome" component={Fontawesome} />
        <RestrictedRoute path="/form-elements" component={Formelements} />
        <RestrictedRoute path="/form-layouts" component={Formlayouts} />
        <RestrictedRoute path="/form-validation" component={Formvalidation} />
        <RestrictedRoute path="/form-wizard" component={Formwizard} />
        <RestrictedRoute path="/google-maps" component={Googlemaps} />
        <RestrictedRoute path="/invoice-detail" component={Invoicedetail} />
        <RestrictedRoute path="/editproduct" component={Editproduct} />
        <RestrictedRoute path="/invoice-list" component={Invoicelist} />
        <RestrictedRoute path="/materialize" component={Materialize} />
        <RestrictedRoute path="/menu-catalogue" component={Menucatalogue} />
        <RestrictedRoute path="/menu-category" component={Categories} />
        <RestrictedRoute path="/menu-product" component={Product} />
        <RestrictedRoute path="/menu-addons" component={Addons} />
        <RestrictedRoute path="/menu-grid" component={Menugrid} />
        <RestrictedRoute path="/menu-list" component={Menulist} />
        <RestrictedRoute path="/modals" component={Modals} />
        {/* <RestrictedRoute path="/google-chart" component={Googlechart} /> */}
        <RestrictedRoute
          path="/orders"
          component={Orders}
          // className={
          //   location.pathname === "/orders" ? "nav-item-in-view" : "nav-item"
          // }
        />
        <RestrictedRoute
          path="/orders-history"
          component={OrdersHistory}
          // className={
          //   location.pathname === "/orders" ? "nav-item-in-view" : "nav-item"
          // }
        />
        <RestrictedRoute path="/attribute-list" component={Attribute} />
        <RestrictedRoute path="/attribute-set" component={Attributeset} />

        <RestrictedRoute path="/pagination" component={Pagination} />
        <RestrictedRoute path="/preloaders" component={Preloaders} />
        <RestrictedRoute path="/product-detail" component={Productdetail} />
        <RestrictedRoute path="/progress-bars" component={Progressbars} />
        <RestrictedRoute path="/range-slider" component={Rangeslider} />
        <RestrictedRoute path="/rating" component={Rating} />
        <RestrictedRoute path="/restaurant-list" component={Restaurantlist} />
        <RestrictedRoute path="/sales" component={Sales} />
        <RestrictedRoute path="/sliders" component={Sliders} />
        <RestrictedRoute path="/social-activity" component={Socialactivity} />
        <RestrictedRoute path="/sweet-alerts" component={Sweetalerts} />
        <RestrictedRoute path="/tabs" component={Tabs} />
        <RestrictedRoute path="/toast" component={Toast} />
        <RestrictedRoute path="/todo-list" component={Todolist} />
        <RestrictedRoute path="/tour" component={Tour} />
        <RestrictedRoute path="/typography" component={Typography} />
        <RestrictedRoute path="/vector-maps" component={Vectormaps} />
        {/* <RestrictedRoute path="/widgets" component={Widgets} /> */}
        <RestrictedRoute
          path="/client-management"
          component={Clientmanagement}
        />
        <RestrictedRoute path="/coming-soon" component={Comingsoon} />
        {/* <RestrictedRoute path="/login" component={Defaultlogin} /> */}
        <RestrictedRoute path="/default-register" component={Defaultregister} />
        {/* <RestrictedRoute path="/error" component={Error} /> */}
        <RestrictedRoute path="/faq" component={Faq} />
        <RestrictedRoute path="/invoice" component={Invoice} />
        <RestrictedRoute path="/lock-screen" component={Lockscreen} />
        <RestrictedRoute path="/modal-login" component={Modallogin} />
        <RestrictedRoute path="/modal-register" component={Modalregister} />
        <RestrictedRoute path="/portfolio" component={Portfolio} />
        <RestrictedRoute path="/stock-management" component={Stockmanagement} />
        <RestrictedRoute path="/user-profile" component={Userprofile} />
        <RestrictedRoute path="/web-analytics" component={Webanalytics} />
        <Route component={Error} />
      </Switch>
    </>
    // </React.Fragment>
  );
};
export default Routes;
