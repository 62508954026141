import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { MyDropzone } from "../Dropzone/Dropzone";
import { createAttributeSet } from "../../../Redux/attributeSetSlice";
import { attributeList } from "../../../Redux/attributeSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Content = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoadingData, setLoadingData] = useState(false);
  const [imageUpload, setImageUpload] = useState([]);
  const [selectTypes, setSelectTypes] = useState();
  const [payloadType, setPayloadType] = useState();

  const [value, setValue] = React.useState("en");

  const { attributeSetData } = useSelector((state) => state.attributeSetSlice);
  const { attributeData } = useSelector((state) => state.attributeSlice);

  const selectType = [
    {
      label: "Checkbox",
      value: "CHECKBOX",
    },
    {
      label: "Dropdown",
      value: "DROPDOWN",
    },
    {
      label: "Radio Button",
      value: "RADIO_BUTTON",
    },
  ];
  const parentOption = attributeData?.map((attributeSet) => ({
    value: attributeSet?.key,
    label: attributeSet?.key + " (" + attributeSet?.name?.en + ")",
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      nameen: "",
      namear: "",
      namede: "",
      is_active: "",
    },
    // validate,
    onSubmit: (values) => {
      let namevalue = {
        en: values.nameen,
        ar: values.namear,
        de: values.namede,
      };

      const payload = {
        value: namevalue,
        attribute_key: payloadType,
        is_active: isChecked,
      };

      const request = {
        payload,
        onSuccess: async (message) => {
          setLoadingData(false);
          toast.success(message);
          history.push("/attribute-set");
        },
        onFail: (message) => {
          toast.error(message);
        },
      };
      setLoadingData(true);
      dispatch(createAttributeSet(request));

      // history.push("/");
    },
  });

  const checkhandleChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleTypeChange = (args) => {
    setSelectTypes(args?.label);
    setPayloadType(args?.value);
  };
  useEffect(() => {
    dispatch(attributeList());
    // dispatch(attributeTypeList());
  }, []);

  return (
    <div className="">
      <div className="col-xl-12 col-md-12">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-header">
            <h6>{t("Create Attribute Set")}</h6>
          </div>
          <div className="ms-panel-body">
            <form
              className="needs-validation clearfix"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              {/* {isLoadingData ? (
                <>
                  {" "}
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "30%",
                      zIndex: "1",
                      color: "orange",
                      animation: "none",
                      // animationDuration: "1s",
                    }}
                  />
                </>
              ) : null} */}
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">
                    {t("Attribute key")}
                  </label>

                  <div className="input-group">
                    <Select
                      name="attribute_key"
                      onChange={handleTypeChange}
                      // value={selectTypes}
                      options={parentOption}
                      placeholder="Attribute key"
                      className="form-gender"
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="validationCustom18"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Value")} &nbsp;[
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="secondary tabs example"
                      // textColor="secondary"
                    >
                      <Tab
                        value="en"
                        label="English"
                        {...a11yProps("en")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="ar"
                        label="Arabic"
                        {...a11yProps("ar")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                      <Tab
                        value="de"
                        label="Germen"
                        {...a11yProps("de")}
                        style={{
                          textTransform: "unset",
                          fontSize: "12px",
                          minWidth: "50px",
                          padding: "0",
                          minHeight: "0",
                        }}
                      />
                    </Tabs>
                    ]
                  </label>
                  <TabPanel value={value} index={"en"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Value"
                        name="nameen"
                        onChange={formik.handleChange}
                        value={formik.values.nameen}
                        // defaultValue="Manish"
                      />
                    </div>
                    {/* Item One */}
                  </TabPanel>
                  <TabPanel value={value} index={"ar"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Value"
                        name="namear"
                        onChange={formik.handleChange}
                        value={formik.values.namear}
                        // defaultValue="Manish"
                      />
                    </div>
                    {/* Item Two */}
                  </TabPanel>
                  <TabPanel value={value} index={"de"}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        placeholder="Value"
                        name="namede"
                        onChange={formik.handleChange}
                        value={formik.values.namede}
                        // defaultValue="Manish"
                      />
                    </div>
                    {/* Item Three */}
                  </TabPanel>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="ms-checkbox-wrap ms-checkbox-primary">
                    <input
                      type="checkbox"
                      defaultValue
                      // defaultChecked
                      id="validationCustom18"
                      name="is_active"
                      checked={isChecked}
                      onChange={(event) => {
                        checkhandleChange(event);
                      }}
                    />{" "}
                    <i className="ms-checkbox-check" />
                  </label>{" "}
                  <span> {t("Is Active")} </span>
                </div>

                <div className="col-md-12 mb-3">
                  <div className="ms-panel-header new buttonConatiner">
                    <button
                      className="btn btn-secondary d-block cancelButton"
                      type="submit"
                      onClick={() => history.push("/attribute-set")}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary d-block saveButton"
                      type="submit"
                    >
                      {"Save"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
