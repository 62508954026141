import React, { Component, useEffect } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Breadcrumb from "../sections/OrderHistory/Breadcrumb";
import OrderHistory from "../sections/OrderHistory/OrderHistory";
import { useTranslation } from "react-i18next";

const OrdersHistory = () => {
  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");

  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
              <OrderHistory t={t} />
            </div>
          </div>
        </div>
      </main>
      {/* <Quickbar /> */}
    </div>
  );
};

export default OrdersHistory;
