import { createSlice } from "@reduxjs/toolkit";
import { ApiService } from "../Api/ApiService";
const initialState = {
  isLoading: false,
  token: "",
  errorMessage: "",
  successMessage: "",
  singleAttributeData: {},
  attributeData: [],
  attributeListData: [],
  //   parent_category_id: "",
  // userData: "",
};
export const attributeSlice = createSlice({
  name: "attribute",
  initialState,
  reducers: {
    createAttributeRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    createAttributeSuccessful(state, action) {
      state.isLoading = false;
      state.attributeData = action.payload;
    },
    createAttributeFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    attributeListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
      state.attributeData = [];
    },
    attributeListSuccessful(state, action) {
      state.isLoading = false;
      state.attributeData = action.payload;
    },
    attributeListFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    attributeTypeListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    attributeTypeListSuccessful(state, action) {
      state.isLoading = false;
      state.attributeListData = action.payload;
    },
    attributeTypeListFailed(state, action) {
      state.errorMessage = action.payload;
    },
    updateAttributeRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    updateAttributeSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    updateAttributeFailed(state, action) {
      state.errorMessage = action.payload;
    },
    singleAttributeRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    singleAttributeSuccessful(state, action) {
      state.isLoading = false;
      state.singleAttributeData = action.payload;
    },
    singleAttributeFailed(state, action) {
      state.errorMessage = action.payload;
    },
    deleteAttributeRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    deleteAttributeSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    deleteAttributeFailed(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  createAttributeRequested,
  createAttributeSuccessful,
  createAttributeFailed,
  attributeListRequested,
  attributeListSuccessful,
  attributeListFailed,
  attributeTypeListRequested,
  attributeTypeListSuccessful,
  attributeTypeListFailed,
  updateAttributeRequested,
  updateAttributeSuccessful,
  updateAttributeFailed,
  singleAttributeRequested,
  singleAttributeSuccessful,
  singleAttributeFailed,
  deleteAttributeRequested,
  deleteAttributeSuccessful,
  deleteAttributeFailed,
} = attributeSlice.actions;

export default attributeSlice.reducer;

export const createAttribute = (request) => async (dispatch, getState) => {
  //   const { token } = getState().createCategory;
  const categoryToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = categoryToken?.token;

  const { payload, onSuccess, onFail } = request;
  //   const {businessId} = getState().loginSlice;
  dispatch(createAttributeRequested());
  try {
    const res = await ApiService.createAttribute(payload, authtoken);

    if (res?.status === 201) {
      dispatch(createAttributeSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        createAttributeFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        createAttributeFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        createAttributeFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const attributeList = (currPage) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  // const { token } = getState().loginSlice;
  dispatch(attributeListRequested());
  try {
    const res = await ApiService.attributeList(authtoken, currPage);

    if (res?.status === 200) {
      dispatch(attributeListSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        attributeListFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        attributeListFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        attributeListFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const attributeTypeList = (currPage) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  // const { token } = getState().loginSlice;
  dispatch(attributeTypeListRequested());
  try {
    const res = await ApiService.attributeTypeList(authtoken, currPage);

    if (res?.status === 200) {
      dispatch(attributeTypeListSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        attributeTypeListFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        attributeTypeListFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        attributeTypeListFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const updateAttribute = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail, id } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(updateAttributeRequested());
  try {
    const res = await ApiService.updateAttribute(payload, authtoken, id);
    if (res?.status === 202) {
      dispatch(updateAttributeSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        updateAttributeFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        updateAttributeFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        updateAttributeFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const singleAttribute = (uuid) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  // const { onSuccess, onFail } = ;request
  const { token } = getState().loginSlice;
  dispatch(singleAttributeRequested());
  try {
    const res = await ApiService.singleAttribute(authtoken, uuid);

    if (res?.status === 200) {
      dispatch(singleAttributeSuccessful(res?.data?.data));
      // dispatch(clearLoginSlice());
      // await RemoveItemValue("loginData");
      // onSuccess("Reload Successfully");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        singleAttributeFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        singleAttributeFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        singleAttributeFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};
export const deleteAttribute = (uuid) => async (dispatch, getState) => {
  // const { payload, onSuccess, onFail, uuid } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(deleteAttributeRequested());
  try {
    const res = await ApiService.deleteAttribute(authtoken, uuid);
    if (res?.status === 200) {
      dispatch(deleteAttributeSuccessful());
      // onSuccess(res?.data?.message || "Something went wrong");
    } else {
      // onFail(res?.data?.message || "Something went wrong");
      dispatch(
        deleteAttributeFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      // onFail("Internal server error");
      dispatch(
        deleteAttributeFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      // onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        deleteAttributeFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};
