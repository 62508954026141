import React, { Component } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Editproductcontent from "../sections/Editproduct/Editproductcontent";

const Editproduct = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <Editproductcontent />
      </main>
    </div>
  );
};

export default Editproduct;
