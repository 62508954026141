import { createSlice } from "@reduxjs/toolkit";
import { ApiService } from "../Api/ApiService";
const initialState = {
  isLoading: false,
  token: "",
  errorMessage: "",
  successMessage: "",
  singleAttributeSetData: {},
  attributeSetData: [],
  //   attributeSetListData: [],
};
export const attributeSetSlice = createSlice({
  name: "attributeSet",
  initialState,
  reducers: {
    createAttributeSetRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    createAttributeSetSuccessful(state, action) {
      state.isLoading = false;
      state.attributeSetData = action.payload;
    },
    createAttributeSetFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    attributeSetListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
      state.attributeSetData = [];
    },
    attributeSetListSuccessful(state, action) {
      state.isLoading = false;
      state.attributeSetData = {
        ...state.attributeSetData,
        [action.payload.attribute_id]: action.payload.data,
      };
    },
    attributeSetListFailed(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    attributeSetTypeListRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    attributeSetTypeListSuccessful(state, action) {
      state.isLoading = false;
      state.attributeSetListData = action.payload;
    },
    attributeSetTypeListFailed(state, action) {
      state.errorMessage = action.payload;
    },
    updateAttributeSetRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    updateAttributeSetSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    updateAttributeSetFailed(state, action) {
      state.errorMessage = action.payload;
    },
    singleAttributeSetRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    singleAttributeSetSuccessful(state, action) {
      state.isLoading = false;
      state.singleAttributeSetData = action.payload;
    },
    singleAttributeSetFailed(state, action) {
      state.errorMessage = action.payload;
    },
    deleteAttributeSetRequested(state, action) {
      state.isLoading = true;
      state.errorMessage = "";
    },
    deleteAttributeSetSuccessful(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload;
    },
    deleteAttributeSetFailed(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  createAttributeSetRequested,
  createAttributeSetSuccessful,
  createAttributeSetFailed,
  attributeSetListRequested,
  attributeSetListSuccessful,
  attributeSetListFailed,
  attributeSetTypeListRequested,
  attributeSetTypeListSuccessful,
  attributeSetTypeListFailed,
  updateAttributeSetRequested,
  updateAttributeSetSuccessful,
  updateAttributeSetFailed,
  singleAttributeSetRequested,
  singleAttributeSetSuccessful,
  singleAttributeSetFailed,
  deleteAttributeSetRequested,
  deleteAttributeSetSuccessful,
  deleteAttributeSetFailed,
} = attributeSetSlice.actions;

export default attributeSetSlice.reducer;

export const createAttributeSet = (request) => async (dispatch, getState) => {
  const categoryToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = categoryToken?.token;

  const { payload, onSuccess, onFail } = request;
  dispatch(createAttributeSetRequested());
  try {
    const res = await ApiService.createAttributeSet(payload, authtoken);

    if (res?.status === 201) {
      dispatch(createAttributeSetSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        createAttributeSetFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        createAttributeSetFailed({
          errorMessage: "Internal server error",
        })
      );
      //   dispatch(logoutSuccessful());
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        createAttributeSetFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
      //   dispatch(logoutSuccessful());
    }
  }
};

export const attributeSetList =
  (attribute_id) => async (dispatch, getState) => {
    const userToken = JSON.parse(localStorage.getItem("usersLogin"));
    let authtoken = userToken?.token;
    dispatch(attributeSetListRequested());
    try {
      const res = await ApiService.attributeSetList(authtoken, attribute_id);

      if (res?.status === 200) {
        dispatch(
          attributeSetListSuccessful({ data: res?.data?.data, attribute_id })
        );
      } else {
        dispatch(
          attributeSetListFailed({
            errorMessage: res?.data?.message || "Something went wrong",
          })
        );
      }
    } catch (e) {
      if (e?.response?.status === 500) {
        dispatch(
          attributeSetListFailed({
            errorMessage: "Internal server error",
          })
        );
      } else {
        dispatch(
          attributeSetListFailed({
            errorMessage: e?.response?.data?.message || "Something went wrong",
          })
        );
      }
    }
  };

export const attributeSetTypeList =
  (currPage) => async (dispatch, getState) => {
    const userToken = JSON.parse(localStorage.getItem("usersLogin"));
    let authtoken = userToken?.token;
    dispatch(attributeSetTypeListRequested());
    try {
      const res = await ApiService.attributeSetTypeList(authtoken, currPage);

      if (res?.status === 200) {
        dispatch(attributeSetTypeListSuccessful(res?.data?.data));
      } else {
        dispatch(
          attributeSetTypeListFailed({
            errorMessage: res?.data?.message || "Something went wrong",
          })
        );
      }
    } catch (e) {
      if (e?.response?.status === 500) {
        dispatch(
          attributeSetTypeListFailed({
            errorMessage: "Internal server error",
          })
        );
      } else {
        dispatch(
          attributeSetTypeListFailed({
            errorMessage: e?.response?.data?.message || "Something went wrong",
          })
        );
      }
    }
  };

export const updateAttributeSet = (request) => async (dispatch, getState) => {
  const { payload, onSuccess, onFail, id } = request;
  const { token } = getState().loginSlice;
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(updateAttributeSetRequested());
  try {
    const res = await ApiService.updateAttributeSet(payload, authtoken, id);
    if (res?.status === 202) {
      dispatch(updateAttributeSetSuccessful());
      onSuccess(res?.data?.message || "Something went wrong");
    } else {
      onFail(res?.data?.message || "Something went wrong");
      dispatch(
        updateAttributeSetFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      onFail("Internal server error");
      dispatch(
        updateAttributeSetFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      onFail(e?.response?.data?.message || "Something went wrong");
      dispatch(
        updateAttributeSetFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};

export const singleAttributeSet = (uuid) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;
  const { token } = getState().loginSlice;
  dispatch(singleAttributeSetRequested());
  try {
    const res = await ApiService.singleAttributeSet(authtoken, uuid);

    if (res?.status === 200) {
      dispatch(singleAttributeSetSuccessful(res?.data?.data));
    } else {
      dispatch(
        singleAttributeSetFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      dispatch(
        singleAttributeSetFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      dispatch(
        singleAttributeSetFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};
export const deleteAttributeSet = (uuid) => async (dispatch, getState) => {
  const userToken = JSON.parse(localStorage.getItem("usersLogin"));
  let authtoken = userToken?.token;

  dispatch(deleteAttributeSetRequested());
  try {
    const res = await ApiService.deleteAttributeSet(authtoken, uuid);
    if (res?.status === 200) {
      dispatch(deleteAttributeSetSuccessful());
    } else {
      dispatch(
        deleteAttributeSetFailed({
          errorMessage: res?.data?.message || "Something went wrong",
        })
      );
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      dispatch(
        deleteAttributeSetFailed({
          errorMessage: "Internal server error",
        })
      );
    } else {
      dispatch(
        deleteAttributeSetFailed({
          errorMessage: e?.response?.data?.message || "Something went wrong",
        })
      );
    }
  }
};
