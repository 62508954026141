import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { Dropdown, Modal, NavLink } from "react-bootstrap";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import foodtechlogo from "../../assets/img/foodtech/foodtech-logo-84x41.png";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  PasswordReset,
  reload,
  updateSearch,
} from "../../Redux/userSlice";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Language } from "@mui/icons-material";
import closed from "../../assets/img/foodtech/closed.png";
import open from "../../assets/img/foodtech/open.jpg";
import closedd from "../../assets/img/foodtech/closedd.jpg";
import openShop from "../../assets/img/foodtech/openShop.png";
import Select from "react-select";
import { CircularProgress } from "@mui/material";

const flags = [
  {
    // src: "https://cdn-icons-png.flaticon.com/512/197/197484.png",
    alt: "English",
    code: "en",
  },
  {
    // src: "https://cdn-icons-png.flaticon.com/512/197/197376.png",
    alt: "Arabic",
    code: "ar",
  },
  // {
  //   // src: "https://cdn-icons-png.flaticon.com/512/197/197376.png",
  //   alt: "German",
  //   code: "de",
  // },
];

const Topnavigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData, isLoading } = useSelector((state) => state.loginSlice);
  // const vendorData = userData?.vendor;
  const data = userData?.vendor_admin;

  const { t, i18n } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [language, setLanguage] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );
  const [vendorStatus, setVendorStatus] = useState();
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);

  const langs = localStorage.getItem("lang");

  const addsidenavigation = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  const addsidenavigationarabic = () => {
    $(".ms-body").toggleClass("ms-aside-right-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  const topbaropen = () => {
    $("#ms-nav-options").toggleClass("ms-slide-down");
  };

  const handleLogout = () => {
    const request = {
      onSuccess: async (message) => {
        toast.success(message);
        history.push("/login");
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(logout(request));
  };

  // useEffect(() => {
  //   changeLanguageHandler(language);
  // }, [language]);

  useEffect(() => {
    dispatch(reload());
    // dispatch(updateSearch());
  }, []);

  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    // console.log(languageValue, "languageValueI");
    i18n.changeLanguage(languageValue);
    setLanguage(languageValue);
    // setShowDropdownLang(false);
    
    localStorage.setItem("lang", languageValue);
  };

  useEffect(() => {
    localStorage.setItem("lang", language);
  }, []);

  const validatepassword = (values) => {
    const errors = {};
    if (!values.old_password) {
      errors.old_password = "Required";
    } else if (values.old_password.length < 0) {
      errors.old_password = "Must be 8 characters or less";
    }

    if (values.new_password !== values.old_password) {
      errors.new_password = "Password doesn't match";
    }

    return errors;
  };
  const formreset = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validatepassword,
    onSubmit: (values) => {
      const payload = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      // console.log(values, "valuessss");
      const request = {
        payload,
        onSuccess: async (message) => {
          toast.success(message);
          history.push("/login");
          // console.log(message);
        },
        onFail: (message) => {
          toast.error(message);
          // console.log(message);
        },
      };
      dispatch(PasswordReset(request));

      // history.push("/");
    },
  });

  // const currentLangObj = Language;

  // useEffect(() => {
  //   document.body.dir = currentLangObj.dir || "ltr";
  // }, [currentLangObj]);

  // console.log(language, "language");

  var val = $("#mySelect").val();
  var lbl = $("#mySelect option:selected").text();
  $("#mySelect").prepend(
    "<option value='" +
      val +
      "' data-value='selected' selected hidden>" +
      val +
      "</option>"
  );

  $("#mySelect").on("change", function () {
    var val = $("#mySelect").val();
    var lbl = $("#mySelect option:selected").text();

    $("#mySelect option[data-value='selected']").attr("value", val);
    $("#mySelect option[data-value='selected']").text(val);

    $("#mySelect").val(val);
  });

  const togglePassword = () => {
    setOldPasswordShown(!oldPasswordShown);
  };
  const toggleNewPassword = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const searchStatushandleChange = (event, vendorData) => {
    // console.log(event, "eventnav");
    const id = vendorData?.uuid;
    setVendorStatus(event?.target?.checked);
    const activeStatusValue = event.target.checked ? 1 : 2;
    // console.log(activeStatusValue, "activeStatusValue");
    const payload = {
      search_status: activeStatusValue,
    };
    const request = {
      payload,
      id,
      onSuccess: async (message) => {
        toast.success(message);
        // dispatch(productList(pageone));
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(updateSearch(request));
    // dispatch(reload());
  };

  useEffect(() => {
    setVendorData((prev) => prev?.userData?.vendor);
    let status = userData?.vendor?.search_status === 1 ? true : false;
    setVendorStatus(status);
  }, [userData?.vendor]);

  // console.log(vendorData, "vendor");

  return (
    <nav className="navbar ms-navbar">
      <div
        className="ms-aside-toggler ms-toggler pl-0"
        onClick={addsidenavigation}
        // onClick={
        //   language === "ar" ? addsidenavigationarabic : addsidenavigation
        // }
      >
        <span className="ms-toggler-bar bg-primary" />
        <span className="ms-toggler-bar bg-primary" />
        <span className="ms-toggler-bar bg-primary" />
      </div>
      <div className="logo-sn logo-sm ms-d-block-sm">
        <Link className="pl-0 ml-0 text-center navbar-brand mr-0" to="/">
          <h4>{t("Order 365")}</h4>
          {/* <img src={foodtechlogo} alt="logo" />{" "} */}
        </Link>
      </div>

      <div
        style={{ display: "flex", alignItems: "center", gap: "10px" }}
        className="top-responsive"
      >
        <div>
          <div
            style={{
              // width: "30%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            // style={{ color: vendorData?.search_status === 2 ? "red" : "green" }}
          >
            <b style={{ color: "red" }}>Close</b>
            <label className="switch">
              <input
                type="checkbox"
                name="search_status"
                checked={vendorStatus}
                onChange={(e) => searchStatushandleChange(e, vendorData)}
              />
              <span className="slider round"></span>
            </label>
            {/* {vendorData?.search_status === 2 ? (
          <img alt="closed" src={closedShop} style={{ width: "100%" }}></img>
        ) : (
          <img alt="closed" src={openShop} style={{ width: "100%" }}></img>
        )} */}
            <b style={{ color: "green" }}>Open</b>
          </div>
        </div>
        <select
          className="custom-select"
          style={{ width: 100, height: "30px", padding: "2px 15px" }}
          value={language}
          onChange={changeLanguageHandler}
          // style={{ border: "none", background: "none", appearance: "none" }}
          // className="language-dropdown"
          // id="mySelect"
        >
          <option value="en">English</option>
          <option value="ar">Arabic</option>
          <option value="de">German</option>
        </select>

        <ul className="ms-inline mb-0" id="ms-nav-options">
          {/* <li className="ms-nav-item ms-search-form pb-0 py-0">
          <form className="ms-form" method="post">
            <div className="ms-form-group my-0 mb-0 has-icon fs-14">
              <input
                type="search" 
                className="ms-form-input"
                name="search"
                placeholder="Search here..."
              />{" "}
              <i className="flaticon-search text-disabled" />
            </div>
          </form>
        </li> */}
          {/* <li className="ms-nav-item dropdown">
          <Dropdown className="custom-dropdown">
            <Dropdown.Toggle
              as={NavLink}
              className="text-disabled ms-has-notification p-0"
            >
              <i className="flaticon-mail" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="mailDropdown"
            >
              <div className="dropdown-menu-header">
                <h6 className="dropdown-header ms-inline m-0">
                  <span className="text-disabled">Mail</span>
                </h6>
                <span className="badge badge-pill badge-success">3 New</span>
              </div>
              <div className="dropdown-divider" />
              <Scrollbar className="ms-scrollable ms-dropdown-list">
                <Link className="media p-2" to="#">
                  <div className="ms-chat-status ms-status-offline ms-chat-img mr-2 align-self-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/foodtech/customer-3.jpg"
                      }
                      className="ms-img-round"
                      alt="people"
                    />
                  </div>
                  <div className="media-body">
                    {" "}
                    <span>Hey man, looking forward to your new project.</span>
                    <p className="fs-10 my-1 text-disabled">
                      <i className="material-icons">access_time</i> 30 seconds
                      ago
                    </p>
                  </div>
                </Link>
                <Link className="media p-2" to="#">
                  <div className="ms-chat-status ms-status-online ms-chat-img mr-2 align-self-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/foodtech/customer-2.jpg"
                      }
                      className="ms-img-round"
                      alt="people"
                    />
                  </div>
                  <div className="media-body">
                    {" "}
                    <span>
                      Dear John, I was told you bought Foodtech! Send me your
                      feedback
                    </span>
                    <p className="fs-10 my-1 text-disabled">
                      <i className="material-icons">access_time</i> 28 minutes
                      ago
                    </p>
                  </div>
                </Link>
                <Link className="media p-2" to="#">
                  <div className="ms-chat-status ms-status-offline ms-chat-img mr-2 align-self-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/foodtech/customer-1.jpg"
                      }
                      className="ms-img-round"
                      alt="people"
                    />
                  </div>
                  <div className="media-body">
                    {" "}
                    <span>
                      How many people are we inviting to the dashboard?
                    </span>
                    <p className="fs-10 my-1 text-disabled">
                      <i className="material-icons">access_time</i> 6 hours ago
                    </p>
                  </div>
                </Link>
              </Scrollbar>
              <div className="dropdown-divider" />
              <div className="dropdown-menu-footer text-center">
                {" "}
                <Link to="/email">Go to Inbox</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li> */}
          {/* <li className="ms-nav-item dropdown">
          <Dropdown className="custom-dropdown">
            <Dropdown.Toggle
              as={NavLink}
              className="text-disabled ms-has-notification p-0"
            >
              <i className="flaticon-bell" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="notificationDropdown"
            >
              <div className="dropdown-menu-header">
                <h6 className="dropdown-header ms-inline m-0">
                  <span className="text-disabled">Notifications</span>
                </h6>
                <span className="badge badge-pill badge-info">4 New</span>
              </div>
              <div className="dropdown-divider" />
              <Scrollbar className="ms-scrollable ms-dropdown-list">
                <Link className="media p-2" to="#">
                  <div className="media-body">
                    {" "}
                    <span>12 ways to improve your crypto dashboard</span>
                    <p className="fs-10 my-1 text-disabled">
                      <i className="material-icons">access_time</i> 30 seconds
                      ago
                    </p>
                  </div>
                </Link>
                <Link className="media p-2" to="#">
                  <div className="media-body">
                    {" "}
                    <span>You have newly registered users</span>
                    <p className="fs-10 my-1 text-disabled">
                      <i className="material-icons">access_time</i> 45 minutes
                      ago
                    </p>
                  </div>
                </Link>
                <Link className="media p-2" to="#">
                  <div className="media-body">
                    {" "}
                    <span>
                      Your account was logged in from an unauthorized IP
                    </span>
                    <p className="fs-10 my-1 text-disabled">
                      <i className="material-icons">access_time</i> 2 hours ago
                    </p>
                  </div>
                </Link>
                <Link className="media p-2" to="#">
                  <div className="media-body">
                    {" "}
                    <span>An application form has been submitted</span>
                    <p className="fs-10 my-1 text-disabled">
                      <i className="material-icons">access_time</i> 1 day ago
                    </p>
                  </div>
                </Link>
              </Scrollbar>
              <div className="dropdown-divider" />
              <div className="dropdown-menu-footer text-center">
                {" "}
                <Link to="#">View all Notifications</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li> */}
          <li className="ms-nav-item ms-nav-user dropdown">
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                <img
                  className="ms-user-img ms-img-round"
                  // src={
                  //   process.env.PUBLIC_URL + "/assets/img/foodtech/customer-6.jpg"
                  // }
                  src={data?.profile_image}
                  alt="people"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-right user-dropdown"
                aria-labelledby="userDropdown"
              >
                <div className="dropdown-menu-header">
                  <h6 className="dropdown-header ms-inline m-0">
                    <span className="text-disabled">
                      Welcome, {vendorData?.vendor_name_lang}
                    </span>
                  </h6>
                </div>
                <div className="dropdown-divider" />
                <div className="ms-dropdown-list">
                  <Link className="media fs-14 p-2" to="/user-profile">
                    {" "}
                    <span>
                      <i className="flaticon-user mr-2" /> Profile
                    </span>
                  </Link>
                  {/* <Link className="media fs-14 p-2" to="/email">
                  {" "}
                  <span>
                    <i className="flaticon-mail mr-2" /> Inbox
                  </span>{" "}
                  <span className="badge badge-pill badge-info">3</span>
                </Link> */}
                  <Link className="media fs-14 p-2" to="/restorent-profile">
                    {" "}
                    <span>
                      <i className="flaticon-user mr-2" /> Resturant Profile
                    </span>
                  </Link>

                  {/* <Link className="media fs-14 p-2" to="/restorent-profile">
                    {" "}
                    <span>
                      <i className="flaticon-gear mr-2" /> Reset password
                    </span>
                  </Link> */}
                  <label className="d-block mt-3 p-2">
                    <div
                      className=""
                      onClick={() => setModalShow(true)}
                      style={{ color: "black" }}
                    >
                      <i className="flaticon-gear mr-2" />
                      Change Password
                    </div>
                  </label>
                </div>
                <div>
                  <Modal
                    className="modal-min"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Body className="text-center">
                      <button
                        type="button"
                        className="close"
                        onClick={() => setModalShow(false)}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                      <i className="flaticon-secure-shield d-block" />
                      <h1 style={{ cursor: "pointer" }}>Change Password</h1>
                      {/* <p>Enter your email to recover your password</p> */}
                      <form method="post" onSubmit={formreset.handleSubmit}>
                        <div className="ms-form-group has-icon">
                          <input
                            // type="text"
                            type={oldPasswordShown ? "text" : "password"}
                            placeholder="Old password"
                            className="form-control"
                            name="old_password"
                            onChange={formreset.handleChange}
                            value={formreset.values.old_password}
                          />{" "}
                          {oldPasswordShown ? (
                            <div className="oldpass">
                              <i
                                class="fa fa-eye-slash"
                                onClick={togglePassword}
                              ></i>
                            </div>
                          ) : (
                            <div className="oldpass">
                              <i
                                class="fa fa-eye"
                                aria-hidden="true"
                                onClick={togglePassword}
                              ></i>
                            </div>
                          )}
                          {formreset.touched.old_password &&
                          formreset.errors.old_password ? (
                            <div className="input-error-message">
                              Please provide a old password.
                            </div>
                          ) : null}
                          {/* <i className="material-icons">email</i> */}
                        </div>
                        <div className="ms-form-group has-icon">
                          <input
                            // type="text"
                            type={newPasswordShown ? "text" : "password"}
                            placeholder="New Password"
                            className="form-control"
                            name="new_password"
                            onChange={formreset.handleChange}
                            value={formreset.values.new_password}
                          />{" "}
                          {newPasswordShown ? (
                            <div className="oldpass">
                              <i
                                class="fa fa-eye-slash"
                                onClick={toggleNewPassword}
                              ></i>
                            </div>
                          ) : (
                            <div className="oldpass">
                              <i
                                class="fa fa-eye"
                                aria-hidden="true"
                                onClick={toggleNewPassword}
                              ></i>
                            </div>
                          )}
                          {formreset.touched.new_password &&
                          formreset.errors.new_password ? (
                            <div className="input-error-message">
                              Please provide a new password.
                            </div>
                          ) : null}
                          {/* <i className="material-icons">email</i> */}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary shadow-none"
                        >
                          Reset Password
                        </button>
                      </form>
                    </Modal.Body>
                  </Modal>
                </div>
                <div className="dropdown-divider" />
                {/* <div className="dropdown-menu-footer">
                <Link className="media fs-14 p-2" to="/lockscreen">
                  {" "}
                  <span>
                    <i className="flaticon-security mr-2" /> Lock
                  </span>
                </Link>
              </div> */}
                <div className="dropdown-menu-footer">
                  <div
                    className="media fs-14 p-2"
                    // to="/default-login"
                    onClick={handleLogout}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    <span>
                      <i className="flaticon-shut-down mr-2" /> Logout
                    </span>
                  </div>
                  {isLoading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        left: "35%",
                        top: "45%",
                        color: "orange",
                        animation: "none",
                        // animationDuration: "1s",
                      }}
                    />
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
      {/* <div
        className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler"
        onClick={topbaropen}
      >
        <span className="ms-toggler-bar bg-primary" />
        <span className="ms-toggler-bar bg-primary" />
        <span className="ms-toggler-bar bg-primary" />
      </div> */}
    </nav>
  );
};

export default Topnavigation;
