import React, { Component } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";

class Detailcontent extends Component {
  render() {
    return (
      <div className="">
        <div className="row">
          <div className="col-md-12" style={{ padding: "0rem 2rem" }}>
            <Breadcrumb />
            <div className="ms-panel">
              <div className="ms-panel-header header-mini">
                <div className="d-flex justify-content-between">
                  <h6>Order detail</h6>
                  <h6>#135178</h6>
                </div>
              </div>
              <div className="ms-panel-body">
                {/* Invoice To */}
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="invoice-address">
                      <h3 className="">Recieved From: </h3>
                      <h5>Anny Farisha</h5>
                      <p>example@example.com</p>
                      <p className="mb-0">
                        1642 Cambridge Drive, Phoenix, 85029 Arizona
                      </p>
                      <p className="mb-0">Arizona</p>
                      <p>Phoenix</p>
                    </div>
                  </div>
                  <div className="col-md-6 text-md-right">
                    <ul className="invoice-date">
                      <h5
                        className="badge badge-danger"
                        style={{ marginBottom: "6rem" }}
                      >
                        Cancelled
                      </h5>

                      <li>Order Date : Saturday, April 07 2021</li>
                      <li>Due Date : Sunday, April 19 2021</li>
                    </ul>
                  </div>
                </div>
                {/* Invoice Table */}
                <div className="ms-invoice-table table-responsive mt-5">
                  {/* <h3 className="badge badge-light">Order Details </h3> */}
                  <table className="table table-hover text-right thead-light">
                    <thead>
                      <tr className="text-capitalize">
                        <th className="text-center w-5">S.NO</th>
                        <th className="text-left">description</th>
                        {/* <th className="text-left">Addons</th> */}
                        <th>qty</th>
                        <th>Unit Cost</th>
                        <th>total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">1</td>
                        <td className="text-left">
                          Grilled Sandwich <br></br>{" "}
                          <span style={{ fontSize: "11px" }}>
                            Extra cheese - $ 25
                          </span>
                          <br></br>
                          <span style={{ fontSize: "11px" }}>
                            Crisp chilli - $ 25
                          </span>
                        </td>
                        {/* <td className="">Grilled Sandwich</td> */}

                        <td>1</td>
                        <td>$30</td>
                        <td>$30</td>
                      </tr>
                      <tr>
                        <td className="text-center">2</td>
                        <td className="text-left">
                          Fried Egg Sandwich <br></br>{" "}
                          <span style={{ fontSize: "11px" }}>
                            Extra cheese - $ 25
                          </span>
                          <br></br>
                          <span style={{ fontSize: "11px" }}>
                            Crisp chilli - $ 25
                          </span>
                        </td>
                        <td>1</td>
                        <td>$69</td>
                        <td>$69</td>
                      </tr>
                      <tr>
                        <td className="text-center">3</td>
                        <td className="text-left">
                          Spicy Grilled Burger <br></br>{" "}
                          <span style={{ fontSize: "11px" }}>
                            Extra cheese - $ 25
                          </span>
                          <br></br>
                          <span style={{ fontSize: "11px" }}>
                            Crisp chilli - $ 25
                          </span>
                        </td>
                        <td>2</td>
                        <td>$19</td>
                        <td>$38</td>
                      </tr>
                      <tr>
                        <td className="text-center">4</td>
                        <td className="text-left">
                          Peri Peri Fries <br></br>{" "}
                          <span style={{ fontSize: "11px" }}>
                            Extra cheese - $ 25
                          </span>
                          <br></br>
                          <span style={{ fontSize: "11px" }}>
                            Crisp chilli - $ 25
                          </span>
                        </td>
                        <td>2</td>
                        <td>$9</td>
                        <td>$18</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={4}>Total Cost:</td>
                        <td>$155</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="invoice-buttons text-right">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-primary mr-2"
                    onClick={() => window.print()}
                  >
                    Print Invoice
                  </Link>
                  {/* <button onClick={() => window.print()}> Print Invoice</button> */}
                  <Link to="/orders" className="btn btn-primary">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Detailcontent;
