import fireAjax from "./Api";
import ApiUrl from "./ApiUrl";

// eslint-disable-next-line import/no-anonymous-default-export
export const ApiService = {
  login: async (payload) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.login}`,
      data: payload,
    });
  },
  register: async (payload) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.register}`,
      data: payload,
    });
  },
  logout: async (token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.logout}`,
      token,
    });
  },
  password: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.password}`,
      data: payload,
      token,
    });
  },

  resetPassword: async (payload) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.resetPassword}`,
      data: payload,
    });
  },
  verifyEmail: async (payload) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.verifyEmail}`,
      data: payload,
    });
  },
  verifyOTP: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.verifyOTP}`,
      data: payload,
    });
  },
  Userreload: async (token) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.reload}`,
      token,
    });
  },
  editvendor: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.editvendoradmin}`,
      data: payload,
      token,
    });
  },
  updateSearch: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.updatesearch}`,
      data: payload,
      token,
    });
  },
  lockups: async (token, payload) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.vendorlockups}?key=${payload}`,
      token,
    });
  },
  editvendorinfo: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.editvendorinfo}`,
      data: payload,
      token,
    });
  },
  createCategory: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.createCategory}`,
      data: payload,
      token,
    });
  },
  categoryList: async (token, currPage) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.categoryList}${currPage}`,
      token,
    });
  },
  categoryTreeList: async (token) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.categoryTreeList}`,
      token,
    });
  },
  updateCategory: async (payload, token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.updateCategory}${uuid}`,
      data: payload,
      token,
    });
  },
  singleCategory: async (token, uuid) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.singleCategory}${uuid}`,
      token,
    });
  },
  deleteCategory: async (token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.deleteCategory}${uuid}`,
      token,
    });
  },
  createProduct: async (payload, token) => {
    const header = {
      headers: {
        "Content-Type": "multipart/formData",
      },
    };
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.createProduct}`,
      data: payload,
      header: header,
      token,
    });
  },
  productList: async (token, currPage) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.productList}${currPage}`,
      token,
    });
  },
  updateProduct: async (payload, token, uuid) => {
    const header = {
      headers: {
        "Content-Type": "multipart/formData",
      },
    };
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.updateProduct}${uuid}`,
      data: payload,
      header: header,
      token,
    });
  },
  singleProduct: async (token, uuid) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.singleProduct}${uuid}`,
      token,
    });
  },
  deleteProduct: async (token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.deleteProduct}${uuid}`,
      token,
    });
  },
  deleteProductImage: async (token, id) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.deleteProductImage}${id}`,
      token,
    });
  },
  changeProductStatus: async (token, id, payload) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.changeProductStatus}${id}`,
      data: payload,
      token,
    });
  },
  createAddon: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.createAddon}`,
      data: payload,
      token,
    });
  },
  addonList: async (token, currPage) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.addonList}${currPage}`,
      token,
    });
  },
  updateAddon: async (payload, token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.updateAddon}${uuid}`,
      data: payload,
      token,
    });
  },
  singleAddon: async (token, uuid) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.singleAddon}${uuid}`,
      token,
    });
  },
  deleteAddon: async (token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.deleteAddon}${uuid}`,
      token,
    });
  },
  getTime: async (token) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.getTime}`,
      token,
    });
  },
  updateTime: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.updateTime}`,
      data: payload,
      token,
    });
  },
  createAttribute: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.createAttribute}`,
      data: payload,
      token,
    });
  },
  attributeList: async (token, currPage) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.attributeList}${currPage}`,
      token,
    });
  },

  attributeTypeList: async (token, currPage) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.attributeTypeList}`,
      token,
    });
  },
  updateAttribute: async (payload, token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.updateAttribute}${uuid}`,
      data: payload,
      token,
    });
  },
  singleAttribute: async (token, uuid) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.singleAttribute}${uuid}`,
      token,
    });
  },
  deleteAttribute: async (token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.deleteAttribute}${uuid}`,
      token,
    });
  },
  createAttributeSet: async (payload, token) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.createAttributeSet}`,
      data: payload,
      token,
    });
  },
  attributeSetList: async (token, attribute_id) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.attributeSetList}${attribute_id}`,
      token,
    });
  },
  updateAttributeSet: async (payload, token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.updateAttributeSet}${uuid}`,
      data: payload,
      token,
    });
  },
  singleAttributeSet: async (token, uuid) => {
    return fireAjax({
      method: "GET",
      URL: `${ApiUrl.singleAttributeSet}${uuid}`,
      token,
    });
  },
  deleteAttributeSet: async (token, uuid) => {
    return fireAjax({
      method: "POST",
      URL: `${ApiUrl.deleteAttributeSet}${uuid}`,
      token,
    });
  },
};
