import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { verifyOTP } from "../../../Redux/userSlice";
import ReactCodeInput from "react-code-input";

const OtpModal = ({
  modalOtp,
  setModalOtp,
  modalPassword,
  setModalPassword,
}) => {
  const dispatch = useDispatch();

  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");

  const checkPinCode = () => {
    const payload = {
      otp: pinCode,
    };

    const request = {
      payload,
      onSuccess: async (message) => {
        toast.success(message);
        setModalOtp(!modalOtp);
        setModalPassword(!modalPassword);
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(verifyOTP(request));
    setIsPinCodeValid(isPinCodeValid);
    if (!isPinCodeValid) setPinCode("");
  };

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
  };
  return (
    <div>
      <Modal
        className="modal-min"
        show={modalOtp}
        onHide={() => setModalOtp(!modalOtp)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <button
            type="button"
            className="close"
            onClick={() => setModalOtp(!modalOtp)}
            style={{ cursor: "pointer" }}
          >
            <span aria-hidden="true">×</span>
          </button>
          <i
            className="flaticon-secure-shield d-block"
            style={{ marginBottom: "2rem" }}
          />
          <div>
            <h1>Verification</h1>
            <p>Enter Verification Code</p>
          </div>
          <div className="ms-form-group has-icon">
            <ReactCodeInput
              type="text"
              fields={6}
              name="otp"
              isValid={isPinCodeValid}
              onChange={handlePinChange}
              value={pinCode}
            />
          </div>{" "}
          <button
            type="submit"
            className="btn btn-primary shadow-none"
            onClick={checkPinCode}
          >
            Verify
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OtpModal;
