import React, { Component, useEffect } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Quickbar from "../layouts/Quickbar";
import { useTranslation } from "react-i18next";
import CouponList from "../sections/Couponlist/Couponlist";
import Breadcrumb from "../sections/Couponlist/Breadcrumb";

const Couponlist = () => {
  const { t, i18n } = useTranslation();
  const langs = localStorage.getItem("lang");

  const getLanguageChange = (langs) => {
    i18n.changeLanguage(langs);
  };

  useEffect(() => {
    getLanguageChange(langs);
  }, [langs]);

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="col-md-12">
          <Breadcrumb />
          <CouponList t={t} />
        </div>
      </main>
      {/* <Quickbar /> */}
    </div>
  );
};

export default Couponlist;
