import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSelector, useDispatch } from "react-redux";
import {
  categoryList,
  deleteCategory,
  singleCategory,
  updateCategory,
} from "../../../Redux/categorySlice";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import CachedIcon from "@mui/icons-material/Cached";
import Select from "react-select";
import ReplayIcon from "@mui/icons-material/Replay";
import { CircularProgress, TextField } from "@mui/material";
import ContentLoader from "react-content-loader";
import { Replay } from "@mui/icons-material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  // eslint-disable-next-line no-unused-expressions
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    t,
  } = props;

  const headCells = [
    {
      id: "S.N",
      numeric: false,
      disablePadding: true,
      label: `${t("S.N")}`,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: `${t("Name")}`,
    },
    {
      id: "parent_category_id",
      numeric: true,
      disablePadding: false,
      label: `${t("Parent Category")}`,
    },
    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: `${t("Status")}`,
    },
    {
      id: "Action",
      numeric: true,
      disablePadding: false,
      label: `${t("Action")}`,
    },
  ];
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            // style={{ textAlign: "center" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  style={{
                    display: "none",
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const languageArabic = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();

  useEffect(() => {
    setCurrLang(languageArabic);
  }, [languageArabic]);

  const { numSelected, t } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        textAlign: currLang === "ar" ? "end" : "",
        flexDirection: currLang === "ar" ? "row-reverse" : "",
      }}
      // style={{}}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Categories({ t, props }) {
  const [categoryListData, setCategoryListData] = useState([]);
  const [allCategoryData, setAllCategoryData] = useState([]);
  const { categoryData, isLoading } = useSelector(
    (state) => state.categorySlice
  );
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchKey, setSearchKey] = useState("");
  const [filterQuery, setIsFilterQuery] = useState({});
  const [parentOptions, setParentOptions] = useState();
  const [payloadParent, setPayloadparent] = useState();
  const [statusOptions, setStatusOptions] = useState("");
  const [payloadStatus, setPayloadstatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);

  const pageone = 1;
  const selectRef = useRef(null);
  const activeRef = useRef(null);

  const checkStatushandleChange = (event, row) => {
    const activeStatusValue = event.target.checked;
    const payload = {
      name: row.name,
      parent_category_id: row.parent_category_id,
      is_active: activeStatusValue,
    };
    const id = row?.uuid;
    const request = {
      payload,
      id,
      onSuccess: async (message) => {
        toast.success(message);
        history.push("/menu-category");
        dispatch(categoryList(pageone));
      },
      onFail: (message) => {
        toast.error(message);
      },
    };
    dispatch(updateCategory(request));
    // setStatusActive(activeStatusValue);
  };

  const languageArabic = localStorage.getItem("lang");

  useEffect(() => {
    dispatch(categoryList(pageone));
  }, []);

  useEffect(() => {
    setCategoryListData(categoryData?.data);
    setAllCategoryData(categoryData?.data);
  }, [categoryData]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allCategoryData?.map((n) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    // setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - allCategoryData?.length)
      : 0;

  const history = useHistory();

  const getViewCategory = (id) => {
    // dispatch(singleCategory(id));
    history.push({ pathname: `/viewCategory/${id}`, state: languageArabic });
  };

  const getEditCategory = (id) => {
    history.push(`/editCategory/${id}`);
  };
  const getDeleteCategory = (id, message) => {
    toast.success(message);
    dispatch(deleteCategory(id));
    dispatch(categoryList(pageone));
  };

  /* START: Search bar */

  const searchCategoryData = () => {
    // let searchkey = searchQuery;
    // setSearchKey(searchkey);
    let filteredData = categoryData?.data?.filter((item) => {
      for (const key in filterQuery) {
        if (key in item) {
          if (item[key] !== filterQuery[key]) {
            return false;
          }
        }
      }
      return true;
    });

    setAllCategoryData(filteredData);

    setPage(0);
  };

  const handleSearchData = (e, title) => {
    if (e?.target?.name === "name_lang") {
      const { name, value } = e?.target;
      setIsFilterQuery((prev) => ({ ...prev, [name]: value }));
    } else {
      setIsFilterQuery((prev) => ({ ...prev, [title]: e?.value }));
    }
  };

  const clearSearchData = () => {
    selectRef.current.clearValue();
    activeRef.current.clearValue();
    setIsFilterQuery({});
    setAllCategoryData(categoryData?.data);
  };

  const statusOption = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 0,
      label: "Inactive",
    },
  ];

  let newArray;

  useEffect(() => {
    let parentId = categoryData?.data?.map((category) => ({
      label: category?.name_lang,
      value: category?.parent_category_id,
    }));
    newArray = [...new Set(parentId?.map((obj) => JSON.stringify(obj)))].map(
      (str) => JSON.parse(str)
    );
    setPayloadparent(newArray);
  }, [categoryData?.data]);

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const getSerialNumber = (index) => {
    return index + page * rowsPerPage + 1;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {selected.length > 0 ? (
          <EnhancedTableToolbar numSelected={selected.length} t={t} />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0rem 1rem",
                paddingTop: "1rem",
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {t("Categories")}
              </Typography>

              {/* <i className="material-icons">search</i>{" "} */}
              <Tooltip title="Filter list">
                <IconButton>
                  <Link
                    to="/createCategory"
                    className="btn btn-primary new-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      padding: "0.4rem",
                    }}
                  >
                    {" "}
                    <i className="material-icons">person_add</i>{" "}
                    {t("Create Categories")}{" "}
                  </Link>{" "}
                </IconButton>
              </Tooltip>
            </div>
            <form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0rem 1rem",
                  paddingTop: "1rem",
                }}
                className="media-query"
              >
                <div className="w-100 mr-2 wrapper">
                  <TextField
                    name="name_lang"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Search Name"
                    className="text"
                    value={filterQuery?.name_lang ?? ""}
                    onChange={(e) => handleSearchData(e)}
                    //  label="Outlined"
                    // onInput={(e) => {
                    //   setSearchQuery(e.target.value);
                    // }}
                    // searchQuery={searchQuery}
                    // setSearchQuery={setSearchQuery}
                  />
                </div>
                <div className="w-100">
                  <Select
                    ref={selectRef}
                    name="parent_category_id"
                    onChange={(e) => handleSearchData(e, "parent_category_id")}
                    options={payloadParent}
                    placeholder="Select P-Category"
                    className="form-gender"
                  />
                </div>
                <div
                  className="w-100 ml-2 wrapper-left"
                  // style={{ width: "50%" }}
                >
                  <Select
                    ref={activeRef}
                    name="is_active"
                    onChange={(e) => handleSearchData(e, "is_active")}
                    options={statusOption}
                    placeholder="Select Status"
                    className="form-gender"
                  />
                </div>
                <div className="w-100 reset-container">
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      fontSize: "23px",
                      width: "23%",
                      textAlign: "center",
                      borderRadius: "4px",
                      // color: "#808080",
                      backgroundColor: "black",
                      color: "white",
                      marginLeft: "7px",
                      cursor: "pointer",
                    }}
                    onClick={searchCategoryData}
                    className="searchbar"
                  >
                    <SearchIcon />
                  </div>
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      fontSize: "23px",
                      width: "23%",
                      textAlign: "center",
                      borderRadius: "4px",
                      // color: "#808080",
                      backgroundColor: "black",
                      color: "white",
                      marginLeft: "7px",
                      cursor: "pointer",
                    }}
                    onClick={clearSearchData}
                    className="searchbar"
                  >
                    <Replay />{" "}
                  </div>
                </div>
              </div>
            </form>
          </>
        )}

        <TableContainer
          style={{
            direction: languageArabic === "ar" ? "rtl" : "unset",
            width: "95%",
            margin: "auto",
          }}
        >
          {refresh ? (
            <>
              {" "}
              <CircularProgress
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "35%",
                  color: "orange",
                  animation: "none",
                  // backgroundColor: "white",
                  // zIndex: "1",
                  // animationDuration: "1s",
                }}
              />
            </>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              style={{ position: "relative" }}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={allCategoryData?.length}
                t={t}
              />
              <TableBody style={{ height: isLoading ? "200px" : "auto" }}>
                {!isLoading ? (
                  <>
                    <>
                      {!allCategoryData?.length ? (
                        <>
                          <b
                            style={{
                              // display: "flex",
                              // justifyContent: "center",
                              color: "red",
                              whiteSpace: "nowrap",
                            }}
                          >
                            No Data Available
                          </b>
                        </>
                      ) : (
                        <>
                          {stableSort(
                            allCategoryData,
                            getComparator(order, orderBy)
                          )
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((row, index) => {
                              const isItemSelected = isSelected(row?.id);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row?.id}
                                  selected={isItemSelected}
                                >
                                  {/* <TableCell padding="checkbox">
                                    <Checkbox
                                      onClick={(event) =>
                                        handleClick(event, row?.id)
                                      }
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell> */}
                                  <TableCell>
                                    {getSerialNumber(index)}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    // align="center"
                                    // style={{ textAlign: "center" }}
                                  >
                                    {row?.name[languageArabic]}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                  >
                                    {row?.parent_category_id}
                                  </TableCell>
                                  <TableCell
                                  // align="center"
                                  // style={{ textAlign: "center" }}
                                  >
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="is_active"
                                        checked={
                                          row?.is_active === 1 ? true : false
                                        }
                                        onChange={(e) =>
                                          checkStatushandleChange(e, row)
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </TableCell>
                                  <TableCell
                                  // align="center"
                                  // style={{ textAlign: "center" }}
                                  >
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <li
                                        className="list-inline-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="View"
                                      >
                                        <div
                                          onClick={() =>
                                            getViewCategory(row?.uuid)
                                          }
                                          // href="/viewCategory"
                                          className="text-success d-inline-block"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="fas fa-eye"></i>
                                        </div>
                                      </li>{" "}
                                      <li
                                        className="list-inline-item edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Edit"
                                      >
                                        <div
                                          onClick={() =>
                                            getEditCategory(row?.uuid)
                                          }
                                          // href="/editCategory"
                                          className="text-primary d-inline-block edit-item-btn"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="fas fa-edit"></i>
                                        </div>
                                      </li>{" "}
                                      <li
                                        className="list-inline-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Delete"
                                      >
                                        <div
                                          // href="/"
                                          onClick={() =>
                                            getDeleteCategory(row?.uuid)
                                          }
                                          className="text-danger d-inline-block remove-item-btn"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="material-icons  delete">
                                            delete
                                          </i>
                                        </div>
                                      </li>{" "}
                                    </ul>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </>
                      )}
                    </>

                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </>
                ) : (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "30%",
                      color: "orange",
                      animation: "none",
                      backgroundColor: "white",
                      // zIndex: "1",
                      // animationDuration: "1s",
                    }}
                  />
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allCategoryData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
