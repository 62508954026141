import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Manualcoupon } from "./Manualcoupon";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CreateCoupon = ({ t }) => {
  let history = useHistory();

  return (
    <div>
      <div className="">
        <div className="col-xl-12 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>{t("Create Coupon")}</h6>
            </div>
            <div className="ms-panel-body">
              {/* <form
                className="needs-validation clearfix"
                noValidate
                // onSubmit={formik.handleSubmit}
              > */}
              <div className="form-row">
                <div
                  className="col-md-12 mb-3"
                  //   style={{ display: "flex", alignItems: "center" }}
                >
                  <label htmlFor="validationCustom18">
                    {t("Coupon Name")} &nbsp; [{" "}
                    <button
                      type="submit"
                      style={{
                        background: "none",
                        border: "none",
                        color: "blue",
                      }}
                    >
                      Generate Code
                    </button>
                    ]{" "}
                  </label>
                  {/* <div className="input-group"> */}
                  <input
                    type="text"
                    // value={values.initialValues?.full_name}
                    className="form-control"
                    id="validationCustom18"
                    placeholder="Coupon Name"
                    name="code"
                    // onChange={formik.handleChange}
                    // value={formik.values.sku_code}
                    // defaultValue="Manish"
                  />
                  <div className="valid-feedback">Looks good!</div>
                  {/* </div> */}
                </div>
                {/* <div className="col-md-6 mb-3">
                  <label
                    htmlFor="validationCustom18"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    {t("Coupon Option")}
                  </label>
                  <div className="">
                    <input
                      type="radio"
                      id="html"
                      name="fav_language"
                      value="HTML"
                      checked="checked"
                    />
                      <label for="html">Automatic</label> {" "}
                    <input
                      type="radio"
                      id="css"
                      name="fav_language"
                      value="CSS"
                    />
                      <label for="css">Manual</label>
                  </div>
                </div> */}
                {/* <div> */}
                {/* <Manualcoupon /> */}
                <div
                  className="col-md-12 mb-3"
                  //   style={{ display: "flex", alignItems: "center" }}
                >
                  <label htmlFor="validationCustom18">
                    {t("Uses Per Customer")}
                  </label>
                  {/* <div className="input-group"> */}
                  <input
                    type="text"
                    // value={values.initialValues?.full_name}
                    className="form-control"
                    id="validationCustom18"
                    placeholder="Uses Per Customer"
                    name="code"
                    // onChange={formik.handleChange}
                    // value={formik.values.sku_code}
                    // defaultValue="Manish"
                  />
                  <div className="valid-feedback">Looks good!</div>
                  {/* </div> */}
                </div>
                <div
                  className="col-md-12 mb-3"
                  //   style={{ display: "flex", alignItems: "center" }}
                >
                  <label
                    htmlFor="validationCustom18"
                    // style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Coupon Rule")}
                  </label>
                  {/* <div className="input-group"> */}
                  <Select
                    name="categories"
                    // onChange={handleCategoryChange}
                    // value={categoriesOption}
                    placeholder="Coupon Rule"
                    className="form-gender"
                    isMulti={true}
                    // options={newdatacat}
                  />
                  <div className="valid-feedback">Looks good!</div>
                  {/* </div> */}
                </div>
                <div
                  className="col-md-12 mb-3"
                  //   style={{ display: "flex", alignItems: "center" }}
                >
                  <label
                    htmlFor="validationCustom18"
                    // style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("Expiry Date")}
                  </label>
                  {/* <div className="input-group"> */}
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom18"
                    placeholder="Expiry Date"
                    name="Expiry Date"
                    //   onChange={formik.handleChange}
                    //   value={formik.values.descriptionen}
                    // defaultValue="Manish"
                  />
                  {/* </div> */}
                </div>

                {/* <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Amount")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Amount "
                      name="amount"
                      // onChange={formik.handleChange}
                      // value={formik.values.amount}
                      // defaultValue="phone"
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div> */}
                <div className="col-md-12 mb-3">
                  <label className="ms-checkbox-wrap ms-checkbox-primary">
                    <input
                      type="checkbox"
                      defaultValue
                      // defaultChecked
                      id="validationCustom18"
                      name="is_active"
                      // checked={isChecked}
                      // onChange={(event) => {
                      //   checkhandleChange(event);
                      // }}
                    />{" "}
                    <i className="ms-checkbox-check" />
                  </label>{" "}
                  <span> {t("Status")} </span>
                </div>
                {/* </div> */}
              </div>
              <div className="col-md-12 mb-3">
                <div className="ms-panel-header new buttonConatiner">
                  <button
                    className="btn btn-secondary d-block cancelButton"
                    type="submit"
                    onClick={() => history.push("/coupon-list")}
                    style={{ minWidth: "100px", padding: "0.4rem" }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn btn-primary d-block saveButton"
                    type="submit"
                    style={{ minWidth: "100px", padding: "0.4rem" }}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default CreateCoupon;
