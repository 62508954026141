import axios from "axios";
import { apiBaseUrl } from "../Constant/constant";

export default function fireAjax(testing) {
  let { method, URL, data, header, token } = testing;
  // console.log(testing, "testing");

  URL = apiBaseUrl + URL;
  // console.log(URL, header, "logggggg");
  if (method === "POST") {
    let headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (header) {
      // console.log(header, "header");
      headers = header;
    }
    if (token) {
      // console.log(headers, "headers checkk");
      headers = {
        headers: {
          ...headers.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return axios.post(URL, data, headers).then(
      (res) => {
        // if (res.response.status === 401) {
        // console.log(res, "error");
        //   localStorage.removeItem("usersLogin");
        //   window.location.href = "/login";
        //   return axios.post(URL, data, headers);
        // } else {
        //   return axios.post(URL, data, headers);
        // }
        // console.log(res, "res----");
        return res;
      },
      (error) => {
        // console.log(error, "error12123");
        if (error.response.status === 401) {
          // console.log(error, "error");
          localStorage.removeItem("usersLogin");
          window.location.href = "/login";
          return axios.post(URL, data, headers);
        } else {
          return axios.post(URL, data, headers);
        }
      }
    );
  } else if (method === "GET") {
    let headers = {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    if (header) {
      headers = header;
    }
    if (token) {
      // console.log(token, "token");
      headers = {
        headers: {
          ...headers.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return axios.get(URL, headers).then(
      console.log(headers, "headers"),
      (res) => {
        if (res.response.status === 401) {
          localStorage.removeItem("usersLogin");
          window.location.href = "/login";
          return axios.get(URL, headers);
        } else {
          return res;
        }
      },
      (error) => {
        // console.log(error, "get error");
        if (error.response.status === 401) {
          // console.log(error, "error");
          localStorage.removeItem("usersLogin");
          window.location.href = "/login";
          return axios.post(URL, headers);
        } else {
          return axios.post(URL, headers);
        }
      }
    );
  } else if (method === "DELETE") {
    let headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    if (header) {
      headers = header;
    }
    if (token) {
      headers = {
        headers: {
          ...headers.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return axios.delete(URL, headers).then(
      (res) => {
        return res;
      },
      (error) => {
        return axios.delete(URL, headers);
      }
    );
  }
}
