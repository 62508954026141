import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

class Markermap extends Component {
  static defaultProps = {
    center: {
      lat: 31.53912,
      lng: -89.29163,
    },
    disableDefaultUI: true,
    zoom: 12,
  };
  render() {
    return (
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCXdkd_KFhEZmymLNee3WdkYFqugVACTTg" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          defaultDefaultUI={this.props.disableDefaultUI}
        >
          <Marker lat={31.53912} lng={-89.29163} name="My Marker" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Markermap;
