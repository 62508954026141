import { useFormik } from "formik";
import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { editvendor, lockups, reload } from "../../../Redux/userSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { MyDropzone } from "../Dropzone/Dropzone";
import { Singledropzone } from "../singleDropzone/Singledropzone";

const Content = () => {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const { userData } = useSelector((state) => state.loginSlice);
  const { lockupsData } = useSelector((state) => state.loginSlice);
  const { nationalities } = useSelector((state) => state.loginSlice);
  const [genderOptions, setGenderOptions] = useState("");
  const [nationalityOptions, setNationalityOptions] = useState("");
  const [imageUpload, setImageUpload] = useState();
  const [imgs, setImgs] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const data = userData?.vendor_admin;

  useEffect(() => {
    // genderOption();
    dispatch(reload());
    dispatch(lockups("gender"));
    dispatch(lockups("nationalities"));
  }, []);

  const genderOption = lockupsData.map((gender) => ({
    value: gender?.id,
    label: gender?.name_lang,
  }));

  const nationOption = nationalities.map((nation) => ({
    value: nation?.id,
    label: nation?.name_lang,
  }));

  const option = [
    { value: "en", label: "English" },
    { value: "ar", label: "Arabic" },
    { value: "de", label: "German" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: data?.full_name,
      phone: data?.phone,
      address: data?.address,
      gender_type: genderOptions,
      nationality_type: nationalityOptions,
      lang: data?.lang,
      profile_image: data?.profile_image,
    },
    // validate,
    onSubmit: (values) => {
      const payload = {
        full_name: values.full_name,
        phone: values.phone,
        address: values.address,
        gender_type: genderOptions,
        nationality_type: nationalityOptions,
        lang: values.lang,
        profile_image: imageUpload,
      };

      const request = {
        payload,
        onSuccess: async (message) => {
          setIsLoadingData(false);
          toast.success(message);
          history.push("/user-profile");
        },
        onFail: (message) => {
          toast.error(message);
          setIsLoadingData(false);
        },
      };
      setIsLoadingData(true);
      dispatch(editvendor(request));
    },
  });
  const handleGenderChange = (args) => {
    setGenderOptions(args?.value);
    // let genderSelect = ()
  };

  const handleNationChange = (args) => {
    setNationalityOptions(args?.value);
  };
  const handleLangChange = (args) => {
    setSelectedOption(args?.value);
  };

  const { t, i18n } = useTranslation();

  const selectedProfile = () => {
    if (userData?.vendor_admin) {
      setGenderOptions(userData?.vendor_admin?.gender_type?.id);
      setNationalityOptions(userData?.vendor_admin?.nationality_type?.id);
      setSelectedOption(userData?.vendor_admin?.lang);
    }
  };

  useEffect(() => {
    selectedProfile();
  }, [userData?.vendor_admin]);

  return (
    <div className="ms-content-wrapper">
      <div className="col-xl-12 col-md-12">
        <div className="ms-panel ms-panel-fh">
          <div className="ms-panel-header">
            <h6>{t("Edit Profile")}</h6>
          </div>
          <div className="ms-panel-body">
            <form
              className="needs-validation clearfix"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              {isLoadingData ? (
                <>
                  {" "}
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "30%",
                      zIndex: "1",
                      color: "orange",
                      animation: "none",
                      // animationDuration: "1s",
                    }}
                  />
                </>
              ) : null}
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Full Name")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      // value={values.initialValues?.full_name}
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Full Name"
                      name="full_name"
                      onChange={formik.handleChange}
                      value={formik.values.full_name}
                      // defaultValue="Manish"
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">
                    {t("Phone Number")}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Phone "
                      name="phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      // defaultValue="phone"
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Gender")}</label>
                  <div className="input-group">
                    <Select
                      name="gender_type"
                      onChange={handleGenderChange}
                      // value={genderOptions}
                      value={genderOption.find(
                        (check) => check.value === genderOptions
                      )}
                      placeholder="Gender"
                      className="form-gender"
                      options={genderOption}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom18">{t("Nationality")}</label>
                  <div className="input-group">
                    <Select
                      placeholder="Nationality"
                      className="form-gender"
                      name="nationality_type"
                      onChange={handleNationChange}
                      // value={nationalityOptions}
                      value={nationOption.find(
                        (check) => check.value === nationalityOptions
                      )}
                      options={nationOption}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom22">{t("Language")}</label>
                  <div className="input-group">
                    <Select
                      placeholder="Language"
                      className="form-gender"
                      name="lang"
                      onChange={handleLangChange}
                      // value={selectedOption}
                      value={option?.find(
                        (check) => check?.value === selectedOption
                      )}
                      options={option}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationCustom12">{t("Address")}</label>
                  <div className="input-group">
                    <textarea
                      rows={1}
                      id="validationCustom12"
                      className="form-control"
                      placeholder="Address"
                      required
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    <div className="invalid-feedback">
                      Please provide a message.
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationCustom23">
                    {t("Profile Image")}
                  </label>
                  <div className="custom-file">
                    <div
                      style={{ width: "100%", display: "flex", gap: "2rem" }}
                    >
                      {imageUpload?.length ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            width: "max-content",
                          }}
                        >
                          <img
                            src={userData?.vendor_admin?.profile_image}
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      )}

                      {/* <MyDropzone setImageUpload={setImageUpload} /> */}
                      <Singledropzone setImageUpload={setImageUpload} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3"></div>
                <div className="col-md-6 mb-3">
                  <div className="ms-panel-header new buttonConatiner">
                    <button
                      className="btn btn-secondary d-block cancelButton"
                      type="submit"
                      onClick={() => history.push("/user-profile")}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary d-block saveButton"
                      type="submit"
                    >
                      {t("Update")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
