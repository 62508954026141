import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { lockups, reload } from "../../../Redux/userSlice";
import { useTranslation } from "react-i18next";

const Content = () => {
  // const { userData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [filename, setFileName] = useState("File Upload");
  const { userData } = useSelector((state) => state.loginSlice);

  const data = userData?.vendor_admin;
  console.log(data, "userprofileDatavendorrrrr");
  console.log(userData, "userprofileuserData");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // console.log("inside useeffect");
    dispatch(reload());
    // dispatch(lockups("cities"));
  }, []);

  const languageArabic = localStorage.getItem("lang");

  const [currLang, setCurrLang] = useState();

  useEffect(() => {
    setCurrLang(languageArabic);
  }, [languageArabic]);

  console.log(userData, "userData content");

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-body">
              <div className="ms-profile-user-buttons new-buttons">
                <h2 className="section-title new-heading">
                  {t("Basic Information")}
                </h2>
                <Link
                  to="/editProfile"
                  className="btn btn-primary new-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  {" "}
                  <i className="material-icons">person_add</i>
                  {t("Edit")}
                </Link>
              </div>
              <table className="table ms-profile-information">
                <tbody>
                  <tr>
                    <th scope="row">{t("Full Name")}</th>
                    <td>{data?.full_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Phone Number")}</th>
                    <td>{data?.phone}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Address")}</th>
                    <td>{data?.address}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Gender")}</th>
                    <td>{data?.gender_type?.name[languageArabic]}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Nationality")}</th>
                    <td>{data?.nationality_type?.name[languageArabic]}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Language")}</th>
                    <td>{data?.lang}</td>
                  </tr>
                  <tr>
                    <th scope="row">{t("Profile Image")}</th>
                    <td>
                      <img src={data?.profile_image} alt="img upload" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
